<template>
  <div v-loading="loadingPage" class="coupon-cont wd-shadow">
    <div class="dataSearch">
      <div class="settlement">
            <el-button type="primary"  icon="el-icon-money" @click="settlement">结算佣金</el-button>
        <el-dialog
            v-loading="loadingWindow"
            title="佣金结算"
            :visible.sync="settlementDialogVisible"
            width="30%"
            center>
          <span style="color:red;font-size: 25px">佣金总额：￥{{ this.commissionrateRecords.commission}}</span><br><br>
          <span style="font-size:20px">返佣率：{{this.commissionrateRecords.commissionrate*100}}%</span><br><br>
          <span slot="footer" class="dialog-footer">
    <el-button @click="settlementDialogVisible = false">取 消</el-button>
    <el-button type="primary" @click="getBill">结 算</el-button>
  </span>
        </el-dialog>
      </div>
    </div>
    <div class="cont"> <el-table
        v-loading="loading"
        :data="buyRecordsList"
        style="width: 100%">
      <el-table-column
          prop="username"
          label="用户名"
          width="200">
      </el-table-column>
      <el-table-column
          prop="email"
          label="邮箱">
      </el-table-column>
      <el-table-column
          label="充值时间"
      >
        <template slot-scope="scope">
            	{{ getTime(scope.row) }}
        </template>
      </el-table-column>
      <el-table-column
          prop="originalamount"
          label="充值金额"
      >
      </el-table-column>
      <el-table-column
          label="佣金"
      >
        <template slot-scope="scope">
          {{ comm(scope.row)}}
        </template>
      </el-table-column>
    </el-table>
    </div>
    <div class="position">
      <el-pagination
          @current-change="handleCurrentChange"
          :current-page.sync="page.pageNo"
          :page-size="page.pageSize"
          layout="total, prev, pager, next"
          :total="page.total">
      </el-pagination>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import { parseTime } from "@/utils/date";

export default {
  name: "ProxyCustomer",
  data(){
    return{
      loading:true,
      loadingPage:false,
      loadingWindow:true,
      buyRecordsList:[],
      commissionrate:0,
      commissionrateRecords:[],
      settlementDialogVisible:false,
      page:{
        total:0,
        pageNo:1,
        pageSize:7,
      },
    }
  },
  methods:{
    getTime(row){
      return parseTime(row.create_time, "{y}-{m}-{d}")
    },
    comm(row){
      console.log(this.$store.getters.userInfo.commissionrate)
      return (row.originalamount*this.$store.getters.userInfo.commissionrate).toFixed(2)
    },
    handleCurrentChange() {
      this.loading=true;
      var parms = {
        pageNo: this.page.pageNo,
        pageSize: this.page.pageSize,
      }
      axios.post("/api/customer/chatCustomer/queryAgentCustomersRechargePageList", parms).then(res => {
        // console.log("id"+this.$store.getters.userInfo.id)
        // this.commissionrate=this.$store.getters.userInfo.commissionrate;
        if (res.data.success) {
          this.buyRecordsList = res.data.result.records;
          this.page.total = res.data.result.total;
        }
        this.loading=false;
      })
    },
    //打开结算窗口
    settlement(){
      this.loadingPage=true;
      axios.post('/api/customer/chatCustomer/getSettlementData').then(res=>{
          if (res.data.success){
              this.commissionrateRecords=res.data.result;
            if(this.commissionrateRecords!==null&&this.commissionrateRecords!==''){
              this.settlementDialogVisible=true;
            }else {
              this.$message({
                message:'无可提取佣金',
                type: 'warning',
                showClose: true,
              });
            }
          }else {
            this.$message({
              message:res.data.message,
              type: 'error',
              showClose: true,
            });
          }
          this.loadingPage=false;
      })
    },
    //生成账单
    getBill(){
      this.loadingWindow=true;
      let params={
        lastDate:this.commissionrateRecords.lastDate,
      }
      axios.post('/api/customer/chatCustomer/saveSettlementRecord',params).then(res=>{
          if(res.data.success){
            this.settlementDialogVisible=false;
            this.$message({
              message:'结算成功，请联系客服提取佣金！',
              type: 'success',
              showClose: true,
            });
          }else {
            this.$message({
              message:res.data.message,
              type: 'error',
              showClose: true,
            });
          }
          this.loadingWindow=false;
      })

    }
  },
  created() {
    // this.commissionrate=this.$store.getters.userInfo.commissionrate;
    this.handleCurrentChange();
  }
}
</script>

<style scoped>
.settlement{
  width: 150px;
  float: right;
  margin-right: 0px;
}
</style>
