<template>
  <div class="weyi_detailMid clearfix">
    <div class="weyi_detail fl">
      <!-- 标题部分 start -->
      <div class="top">
        <div class="back" style="height: 19px;">
          <h2><img src="../../../public/static/web/img/weyiXW4d5f.png?v=3.3.49">条款与隐私</h2>
        </div>
      </div>
      <!-- 标题部分 end -->
      <!-- 内容部分 start -->
      <div class="mid">
        <div class="tit">
          <h2>平台服务协议</h2>
          <p>版本号：<span>1.0</span>    发布/更新日期：<span class="readNo">2024-06-12 18:08:28</span></p>
        </div>
        <div class="con con2">
          <p><span style="background-color: rgb(255, 255, 255); color: rgb(48, 48, 48);">本协议由本网站（www.catmsg.com）注册用户（简称“您”）与Catchat跨境电商翻译助手（简称“CC翻译”）共同缔结，根据《中华人民共和国合同法》及其他相关法律法规自愿订立本协议。</span></p><p><span style="color: rgb(48, 48, 48); background-color: rgb(255, 255, 255);">【签约声明】</span></p>
          <p><span style="color: rgb(48, 48, 48); background-color: rgb(255, 255, 255);">一、您确认在签署本协议之前，已充分阅读、理解并接受本协议的全部内容（包括但不限于本协议全部声明及条款），一旦您点击确认同意本协议、或实际已接受本协议项下的权利（或履行义务）的，即表示您同意遵循本协议之所有约定。</span></p>
          <p><span style="color: rgb(48, 48, 48); background-color: rgb(255, 255, 255);">二、本协议内容包括签约声明、协议正文、附件、正文提及的规则及所有CC翻译发布的关于</span>
            <span style="color: rgb(57, 57, 57); background-color: rgb(255, 255, 255);">“翻译服务”<!--、“计数服务”、“客服坐席服务”--></span>
            <span style="color: rgb(48, 48, 48); background-color: rgb(255, 255, 255);">已经发布或将来可能发布的各类规则。所有这些规则都应视为本协议不可分割的组成部分，与本协议具有同等法律效力。</span></p>
          <p><span style="color: rgb(48, 48, 48); background-color: rgb(255, 255, 255);">三、您同意CC翻译可根据运营需求对本协议内容进行单方面的补充或变更，并以在网站公告的方式予以公布；若您在本协议内容公告变更后继续使用本协议项下服务，即表示您已充分阅读、理解并接受修改后的协议内容，也将遵循修改后的协议内容使用服务；若您不同意修改后的协议内容，您应停止使用服务，并向CC翻译提交终止协议的申请。</span></p>
          <p><span style="color: rgb(48, 48, 48); background-color: rgb(255, 255, 255);">【协议正文】</span></p>
          <p><span style="color: rgb(48, 48, 48); background-color: rgb(255, 255, 255);">一、服务定义</span></p>
          <p><span style="color: rgb(48, 48, 48); background-color: rgb(255, 255, 255);">实时翻译服务：以下简称“翻译服务”，即在向用户提供的实时翻译服务，在发送基本文字内容通讯服务的基础上还提供各行业或类目全球大部分语言的文本翻译服务。</span></p>
<!--          <p><span style="color: rgb(57, 57, 57); background-color: rgb(255, 255, 255);">计数服务：以下简称“跨境服务”，即在您通过CC翻译网站（以下简称“本网站”）的相关页面、或以其他遂意语联允许的方式，向遂意语联提交跨境相关服务请求，同时向遂意语联提交所需要的处理内容（以下简称“稿件”）并支付服务费，遂意语联根据本协议自行或委托他人进行处理内容、并交付处理完成的内容的服务。</span></p>
          <p><span style="color: rgb(57, 57, 57); background-color: rgb(255, 255, 255);">客服坐席服务：以下简称“增值服务”，即在您通过CC翻译网站（以下简称“本网站”）的相关页面、或以其他遂意语联允许的方式，向他人协商其他服务内容，同时向遂意语联提交并支付服务费，遂意语联根据本协议委托他人进行处理内容、并交付处理完成的内容的服务。</span></p>
          --><p><span style="color: rgb(48, 48, 48); background-color: rgb(255, 255, 255);">二、帐号注册</span></p><p><span style="color: rgb(48, 48, 48); background-color: rgb(255, 255, 255);">2.1 服务前提：</span></p><p><span style="color: rgb(48, 48, 48); background-color: rgb(255, 255, 255);">您确认，在您完成注册程序或以其他CC翻译允许的方式实际使用服务时，您应当是具备完全民事权利能力和与所从事的民事行为相适应的行为能力的自然人、法人或其他组织。若您不具备前述主体资格，请勿使用服务，否则您及您的监护人应承担因此而导致的一切后果，且CC翻译有权注销（永久冻结）您的帐号，并向您及您的监护人索偿。如您代表一家公司或其他法律主体在本网站登记，则您声明和保证，您有权使该公司或该法律主体受本协议的约束。</span></p>
          <p><span style="color: rgb(48, 48, 48); background-color: rgb(255, 255, 255);">2.2 注册帐号</span></p><p><span style="color: rgb(48, 48, 48); background-color: rgb(255, 255, 255);">2.2.1 在您按照注册页面提示填写信息、阅读并同意本协议且完成全部注册程序后，或在您按照激活页面提示填写信息、阅读并同意本协议且完成全部激活程序后，或您以其他CC翻译允许的方式实际使用本网站服务时，您即受本协议的约束。您可以使用CC翻译允许的登录方式进入本网站。</span></p>
          <p><span style="color: rgb(48, 48, 48); background-color: rgb(255, 255, 255);">2.2.2 您承诺，您在注册时及使用服务中所提供的一切信息和数据（合称“信息”）均真实准确，且您将及时维护和更新信息，特别是您提供的电子邮件地址、联系电话、联系地址、手机号码等联系方式，以便CC翻译与您进行有效联系；因通过信息无法与您取得联系，导致您在使用CC翻译服务过程中产生任何损失或增加费用的，应由您完全独自承担。您的信息有变更需要更新的，您应按CC翻译的要求或网站提示进行操作。</span></p>
          <p><span style="color: rgb(48, 48, 48); background-color: rgb(255, 255, 255);">2.2.3 您在此明确授权，在您注册成功时，您已授权本网站披露给CC翻译或其关联公司，并同时授权CC翻译或其关联公司使用，以使您更便捷地使用CC翻译或其关联公司提供的各项服务。CC翻译如发现注册信息或稿件有违反中国法律法规的，有权立即终止服务，并有权保存有关记录，向有关机关报告。</span></p>
          <p><span style="color: rgb(48, 48, 48); background-color: rgb(255, 255, 255);">2.3 若您违反本协议任一约定造成纠纷、或被国家有权机关处理的，您应独立承担全部责任，并赔偿因此给CC翻译及或其关联公司及其他任何第三方造成的损失。</span></p>
          <p><span style="color: rgb(48, 48, 48); background-color: rgb(255, 255, 255);">三、帐号安全</span></p>
          <p><span style="color: rgb(48, 48, 48); background-color: rgb(255, 255, 255);">3.1 您须自行负责对您的登录名和密码保密，且须对该登录名和密码下发生的所有活动或行为（包括但不限于信息披露、发布信息、网上点击同意或提交各类规则协议、网上续签协议或购买服务等）承担责任。</span></p>
          <p><span style="color: rgb(48, 48, 48); background-color: rgb(255, 255, 255);">3.2 您同意：(a)如发现任何人未经授权使用您的登录名和密码，或发生违反保密规定的任何其他情况，您会立即通知CC翻译，并授权CC翻译将该信息同步给关联公司及合作伙伴； (b)确保您在每个上网时段结束时，以正确步骤离开网站。</span></p>
          <p><span style="color: rgb(48, 48, 48); background-color: rgb(255, 255, 255);">3.3 CC翻译不能也不会对因您未能遵守本款规定而发生的任何损失或损毁负责。请您理解CC翻译对您的请求采取行动需要合理时间，CC翻译对在采取行动前已经产生的后果（包括但不限于您的任何损失）不承担任何责任。</span></p>
          <p><span style="color: rgb(48, 48, 48); background-color: rgb(255, 255, 255);">3.4 除非有法律规定或司法裁定、且征得CC翻译的同意，否则，您的登录名和密码不得以任何方式转让、赠与或继承（与帐号相关的财产权益除外）。</span></p>
          <p><span style="color: rgb(48, 48, 48); background-color: rgb(255, 255, 255);">四、服务费用</span></p>
          <p><span style="color: rgb(48, 48, 48); background-color: rgb(255, 255, 255);">4.1 套餐费用：您在本网站购买套餐（VIP1、VIP2、包月）的费用，本软件会利用你终端设备的处理器和带宽等资源，在使用过程中可能产生数据流量的费用，用户需自行向运营商了解相关资费信息，并自行承担相关费用。</span></p>
          <p><span style="color: rgb(48, 48, 48); background-color: rgb(255, 255, 255);">4.2 翻译服务中，中译外笔译项目以中文字符数为依据计算翻译服务费用（不计空格）。外译中以及外译外的字数统计，以中文字符长度计算。字数统计：字数统计根据您提交翻译文本后CC翻译提供的最终计算统计为准。</span></p>
          <p><span style="color: rgb(48, 48, 48); background-color: rgb(255, 255, 255);">4.3 付款方式：您应通过CC翻译网站提供的支付方式一次性支付所购买的套餐费用。</span></p>
<!--          <p><span style="color: rgb(48, 48, 48); background-color: rgb(255, 255, 255);">五、服务内容</span></p>-->
          <p><span style="color: rgb(48, 48, 48); background-color: rgb(255, 255, 255);">五、 保密义务</span></p>
          <p><span style="color: rgb(48, 48, 48); background-color: rgb(255, 255, 255);">5.1 未经您的允许，CC翻译不会向除供方外的第三方公开或传播您的信息及稿件内容。</span></p>
          <p><span style="color: rgb(48, 48, 48); background-color: rgb(255, 255, 255);">5.2 您理解并同意，为履行本协议目的，CC翻译可向签订相关保密协议条款的CC翻译及其相关联公司员工、顾问、被委托人、代理人和其他第三方披露您的信息及译文内容。若非因CC翻译人为原因，如因天灾、盗窃、抢劫、黑客攻击等造成信息泄露的，CC翻译不承担法律责任。</span></p>
          <p><span style="color: rgb(48, 48, 48); background-color: rgb(255, 255, 255);">六、不可抗力</span></p>
          <p><span style="color: rgb(48, 48, 48); background-color: rgb(255, 255, 255);">因不可抗力（包括但不限于自然灾害、罢工或骚乱、暴动、战争行为、政府行为、黑客攻击、停电、通讯或其他设施故障、或严重伤亡事故等）或其他非归咎于CC翻译的原因，致使延迟或未能履约的，CC翻译无须承担违约责任。</span></p>
          <p><span style="color: rgb(48, 48, 48); background-color: rgb(255, 255, 255);">七、服务终止</span></p>
          <p><span style="color: rgb(48, 48, 48); background-color: rgb(255, 255, 255);">11.1 CC翻译有权提前【30】天通知您解除本协议；解除本协议时有已支付（且未完成）订单的，网站将以该订单的服务费金额为限向您提供适当补偿。</span></p>
          <p><span style="color: rgb(48, 48, 48); background-color: rgb(255, 255, 255);">11.2 您同意，服务终止后，CC翻译有权利但没有义务为您保留原帐号中或与之相关的任何信息。</span></p>
          <p><span style="color: rgb(48, 48, 48); background-color: rgb(255, 255, 255);">八、法律适用</span></p>
          <p><span style="color: rgb(48, 48, 48); background-color: rgb(255, 255, 255);">12.1 本协议之效力、解释、变更、执行与争议解决均适用中华人民共和国法律。</span></p>
          <p><span style="color: rgb(48, 48, 48); background-color: rgb(255, 255, 255);">12.2 因本协议引起的或与本协议有关的一切纠纷，由双方友好协商解决；协商不成的，任何一方有权向CC翻译住所地人民法院提起诉讼。</span></p><p><br/></p>                </div>
      </div>
      <!-- 内容部分 end -->
    </div>
  </div>
</template>

<script>
export default {
  name: "clause"
}
</script>

<style scoped>
@import "../../../public/static/common/css/animate.min4d5f.css?v=3.3.49";
@import "../../../public/static/common/css/common4d5f.css?v=3.3.49";
@import "../../../public/static/web/css/server4d5f.css?v=3.3.49";
</style>
