<template>
  <div class="page4">
    <ul class="faqList">
      <el-collapse class="list" v-model="activeName" accordion>
        <el-collapse-item title="1、如何购买套餐？" name="1">
          <div class="info">
            <p>您需要先注册并登陆账号。</p><p></p><p>1. 点击<a href="/members" style="white-space: normal;"> 【会员专享】 </a>查看套餐<a href="/members" style="white-space: normal;"></a></p><p>2. 选择一个合适的套餐，点击立即购买，然后选择购买数量</p><p>3. 选择支付方式，点击去支付进行支付。</p><p><br/></p>									</div>
        </el-collapse-item>
        <el-collapse-item title="2、是否可以多次购买不同套餐？" name="2">
          <div class="info">
            <p>当购买不同套餐时，需将之前购买的套餐使用完之后，再进行购买，否则后一次购买的套餐会将前一次购买的套餐的字符余量清零</p>									</div>
        </el-collapse-item>
        <el-collapse-item title="3、购买套餐可以享受哪些特权？" name="3">
          <div class="info">
            <p>根据每个套餐的内容可以享受不同的特权，能够使用更多的翻译引擎，并且还能升级成为高级账户，开启创建子账号的特权。</p></div>
        </el-collapse-item>
        <el-collapse-item title="4、购买套餐的内容是什么？" name="4">
          <div class="info">
            <p>套餐买的是字符量和翻译工具，字符量必须大于零才能进行翻译操作，并且您可以随意切换套餐内的翻译工具。<br/></p></div>
        </el-collapse-item>
        <el-collapse-item title="5、什么是包月套餐？" name="5">
          <div class="info">
            <p>包月套餐是指按用户需求自由定制一定时间段里的套餐业务，您可以自由选择是定制一个月，还是一个季度，抑或是一年。您选择好定制的时间，这段时间你就可以享受这一业务，包月套餐没有字符余量，可以放心使用。<br><span style="text-indent:15.75pt;">提示：请先使用完原套餐的字符余量，否则会被清空。</span></p></div>
        </el-collapse-item>
        <el-collapse-item title="6、如何查看套餐内容？" name="6">
          <div class="info">
            <p><span style="font-family:" class="ke-insertfile">点击页面右上方头像进入<a href="/members" style="white-space: normal;">【个人中心】</a>，在我的主页可以看到当前的套餐和套餐内的翻译工具，并且点击可以切换翻译工具。</span></p></div>
        </el-collapse-item>
        <el-collapse-item title="7、如何获取优惠券？" name="7">
          <div class="info">
            <span style="font-family:&quot;font-size:14px;">您可以通过以下几种方式获取优惠券：</span><br />
            <span style="font-family:&quot;font-size:14px;">A. 通过活动获取，我们会不定期推出会员活动，针对积极参与的用户会发放优惠券。</span><br />
            <span style="font-family:&quot;font-size:14px;">B. 加入CC翻译用户体验QQ交流群, 群内会有语翼专业的用户体验师与您互动并收集您的反馈，对于积极提交有价值反馈的用户，我们会赠送优惠券等奖励。</span><br />
            <span style="font-family:&quot;font-size:14px;">C. 如果您充值金额较大，可以联系客服手动发放优惠券</span><br /></div>
        </el-collapse-item>
      </el-collapse>
    </ul>
  </div>
</template>

<script>
export default {
  name: "page4",
  data() {
    return {
      activeName: '0'
    };
  }
}
</script>

<style scoped>
.info {
  background: #f2f2f2;
  padding: 15px 20px;
  border-radius: 10px;
}
</style>
