<template>
  <div>
  <section>
    <ul class="settingList">
      <li>
        <div class="icon">
          <img src="../../../../public/static/common/img/i_09_mima.png" alt="">
        </div>
        <div class="info">
          <strong>登录密码</strong>
          <p>互联网帐号存在被盗风险，建议您定期更改密码以保护账户安全。</p>
        </div>
        <div class="submit">
          <a href="/personal/Security/ChangePassword">更改密码</a>
        </div>
      </li>
      <li v-if="this.$store.getters.userInfo.usertype!=='3'">
        <div class="icon  email ">
          <img src="../../../../public/static/common/img/i_09_email2.png" alt="">
        </div>
        <div class="info">
          <strong>邮箱验证</strong>
          <p>						已验证的邮箱：<span>{{ this.$store.getters.userInfo.email}}，邮箱修改后，登录账号也随之修改</span>
          </p>
        </div>
        <div class="submit">
          <a href="/personal/Security/ChangeEmail">修改邮箱</a>
        </div>
      </li>
      <li v-if="this.$store.getters.userInfo.usertype!=='3'">
        <div class="icon shenshu">
          <img src="../../../../public/static/common/img/i_09_shensu.png" alt="">
        </div>
        <div class="info">
          <strong>账号申诉</strong>
          <p>如以上方式无法完成修改密码或邮箱，可联系客服</p>
        </div>
        <div class="submit">
          <button class="moquu_wserve" @click="service">联系客服</button>
        </div>
      </li>
    </ul>
  </section>
    <el-dialog
        title="联系客服"
        :visible.sync="dialogVisible"
        width="20%">
      <el-tabs v-model="activename">
        <el-tab-pane label="微信" name="first"><img src="https://catchatoss.oss-cn-hangzhou.aliyuncs.com/kefu_imgs/20220712105413.jpg" class="qrCodeImg" alt=""></el-tab-pane>
        <el-tab-pane label="纸飞机" name="second"><img src="https://catchatoss.oss-cn-hangzhou.aliyuncs.com/kefu_imgs/20220712105413.jpg" class="qrCodeImg" alt=""></el-tab-pane>
        <el-tab-pane label="WhatApp" name="third"><img src="https://catchatoss.oss-cn-hangzhou.aliyuncs.com/kefu_imgs/20220712105413.jpg" class="qrCodeImg" alt=""></el-tab-pane>
      </el-tabs>
<!--      <span slot="footer" class="dialog-footer">-->
<!--    <el-button @click="dialogVisible = false">取 消</el-button>-->
<!--    <el-button type="primary" @click="dialogVisible = false">确 定</el-button>-->
<!--  </span>-->
    </el-dialog>
  </div>
</template>

<script>
export default {
  name: "Main",
  data(){
    return{
      dialogVisible:false,
      activename:'first',
    }
  },
  methods:{
    service(){
        this.dialogVisible=true;
    },
  }
}
</script>

<style scoped>
.m_content {
  display: block;
  float: left;
  width: 905px;
  margin-left: 15px;
  margin-bottom: 40px;
  background: #fff;
  border-radius: 4px;
}
.userComon {
  min-height: 350px !important;
}
.m_content .settingList {
  background: #fff;
}
.m_content .settingList li {
  overflow: hidden;
  margin-left: 30px;
  padding: 30px 0;
  border-bottom: 1px solid #eaeaea;
}
.m_content .settingList li .icon {
  float: left;
  width: 35px;
  height: 35px;
  border-radius: 35px;
  overflow: hidden;
}
img {
  display: inline-block;
  border: none;
  vertical-align: middle;
}
.m_content .settingList li .icon img {
  width: 427px;
  /*margin: -4px 0 0 -283px;*/
}
.m_content .settingList li .info {
  float: left;
  padding-left: 21px;
}
.m_content .settingList li .info p {
  color: #999;
  font-size: 14px;
  padding-top: 4px;
}
.m_content .settingList li .edit_icon img {
  width: 427px;
  margin: -56px 0 0 -337px;
}
.m_content .settingList li .submit {
  float: right;
  padding-right: 26px;
  margin-top: 10px;
}
.m_content .settingList li .submit button, .m_content .settingList li .submit a {
  border: 1px solid #1aba79;
  background: #fff;
  font-size: 12px;
  color: #1aba79;
  cursor: pointer;
  border-radius: 4px;
  padding: 4px 13px;
}
.qrCodeImg{
  /*height:150px;*/
  margin: 0 auto;
}
</style>
