<template>
  <div class="pricing" style="margin-top: 60px ;">
    <!-- 主体内容 start -->
    <section class="a memberPrice">
      <div class="banner-top">
        <div class="banner-img wd-center">
          <p class="banner-text1">更低的价格&nbsp;&nbsp;&nbsp;更专业的服务</p>
          <p class="banner-text2">CC翻译出海聊天翻译助手</p>
        </div>
      </div>
      <div class="memberPrice">
        <!--翻译价格-->
        <!--翻译价格-->
        <div class="content-3 wd-center">
          <p>会员套餐 </p>
          <table class="layui-table" lay-skin="line">
            <colgroup>
              <col width="240">
              <col width="100">
              <col width="260">
              <col width="420">
            </colgroup>
            <thead>
            <tr>
              <th>套餐名称</th>
              <th>费用单位</th>
              <th>价格</th>
              <th>说明</th>
            </tr>
            </thead>
            <tbody>
            <tr>
              <td>VIP1</td>
              <td>160万字符 </td>
              <td>￥200</td>
              <td class="wd-text-l">超多字符加有道、百度、小牛、火山等多种翻译工具有200多种翻译语言</td>
            </tr>
            <tr>
              <td>VIP2</td>
              <td>160万字符 </td>
              <td>￥320</td>
              <td class="wd-text-l">人工智能赋能精准翻译，超多字符量加谷歌、chatGPT、微软、亚马逊、腾讯等高级翻译工具</td>
            </tr>
            <tr>
              <td>VIP3</td>
              <td>160万字符 </td>
              <td>￥680</td>
              <td class="wd-text-l">最高的数据安全性，自动升级高级账户，支持子账户管理，支持全世界最精准的Deepl翻译工具</td>
            </tr>
            <tr>
              <td>VIP1包月套餐</td>
              <td>30天字符不限量</td>
              <td>￥499</td>
              <td class="wd-text-l">无字符限量的顾虑，支持有道，百度等翻译通道，不支持子账号，一个账号一台电脑</td>
            </tr>
            <tr>
              <td>VIP2包月套餐</td>
              <td>30天字符不限量</td>
              <td>￥599</td>
              <td class="wd-text-l">无字符限量的顾虑，支持谷歌，腾讯，有道等翻译通道，不支持子账号，一个账号一台电脑</td>
            </tr>
            <tr>
              <td>VIP3包月套餐</td>
              <td>30天字符不限量</td>
              <td>￥999</td>
              <td class="wd-text-l">无字符限量的顾虑，支持Deepl,谷歌，chatGPT等翻译通道，不支持子账号，一个账号一台电脑</td>
            </tr>

            </tbody>
          </table>
        </div>
        <div class="content-1-1">
          <div class="top">
            <span class="text1" id="pricetips">翻译价格（元/万字）</span>
            <span class="text2">标准级</span>
          </div>
          <!--价格图表-->
          <div class="chart">
            <div class="chartin-1">
              <div class="c-top">
                <h3>翻译价格</h3>
                <p id="distips">同样的服务，比市场优惠<span id="dis">8%</span></p>
              </div>
              <ul class="chart-list">
                <li class="chart-1">
                  <div class="wd-center chart-box">
                    <div class="wd-center middel">
                      <div class="color-line bg-green" data-percentage="0" style="height: 30.74%;">
                        <span class="num" id="ordinary" data-discount="10">￥1.25</span>
                      </div>
                    </div>
                    <span class="bottom color-green">VIP1</span>
                  </div>
                  <div class="wd-center chart-box">
                    <div class="wd-center middel">
                      <div class="color-line bg-yellow" data-percentage="0" style="height: 45.76%;">
                        <span class="num" id="ordinary" data-discount="10">￥1.33</span>
                      </div>
                    </div>
                    <span class="bottom color-yellow">某基础版</span>
                  </div>
                </li>
                <li class="chart-2">
                  <div class="wd-center chart-box">
                    <div class="wd-center middel">
                      <div class="color-line bg-green" data-percentage="0" style="height: 50%">
                        <span class="num" id="silver" data-discount="8.8">￥2.25</span>
                      </div>
                    </div>
                    <span class="bottom color-green">VIP2</span>
                  </div>
                  <div class="wd-center chart-box">
                    <div class="wd-center middel">
                      <div class="color-line bg-yellow" data-percentage="0" style="height: 65.23%">
                        <span class="num" id="silver" data-discount="8.8">￥2.33</span>
                      </div>
                    </div>
                    <span class="bottom color-yellow">某高级版</span>
                  </div>
                </li>
<!--                <li class="chart-13">
                  <div class="wd-center chart-box">
                    <div class="wd-center middel">
                      <div class="color-line bg-green" data-percentage="0" style="height: 60%">
                        <span class="num" id="gold" data-discount="8">￥1.62</span>
                      </div>
                    </div>
                    <span class="bottom color-green">包月</span>
                  </div>
                </li>-->
              </ul>
            </div>
          </div>
          <div class="chart-btn-box">
            <a href="/members" onclick="woordeeGio({eventId:'regPageShow',eventLevelVariables:{triggerPage_var:'价格页_注册领会员','triggerItem_var':'注册领会员'}},3,window.location.href)" class="btn-order btn-o-1">
              <i class="icon"></i>
              <p class="p1">立即注册 领10000字符</p>
              <p class="p2">充值累计超1000元自动升级为企业级会员</p>
              <i class="curt"></i>
              <i class="horn"></i>
            </a>
          </div>
        </div>
        <div class="content-2">
          <span class="title">企业会员免费开通<span class="num">30</span>个子账户，更多特权等你来领</span>
          <button class="layui-btn by-btn"><a href="/members">了解会员专享</a></button>
        </div>

      </div>
      <input type="hidden" id="priceSourceV" name="sourceId" value="zh-CN"/>
      <input type="hidden" id="priceTarV" name="tarId" value="en"/>
      <input type="hidden" id="memberPriceV" name="price">

    </section>
    <!--简单的选择语言-->
    <div class="choice-lag hidden">
      <div class="price-assess">
        <div class="top">
          <p>请选择语言</p>
        </div>
        <form class="layui-form price-form">
          <div class="layui-form-item">
            <label class="layui-form-label">源语言</label>
            <div class="layui-input-block">
              <select id="s_lan1" name="s_lan1" lay-filter="s_lan1">
              </select>
            </div>
          </div>
          <div class="layui-form-item">
            <label class="layui-form-label">目标语言</label>
            <div class="layui-input-block">
              <select id="t_lan1" name="t_lan1" lay-filter="t_lan1" disabled>
                <option selected value="请选择目标语言" disabled style="display: none"></option>
              </select>
            </div>
          </div>
        </form>
        <div class="bottom">
          <button class="layui-btn submit-btn" disabled="disabled">确定</button>
        </div>
      </div>

    </div>
  </div>
</template>

<script>
import vuex from "@/store";

export default {
  name: "pricing",

  methods:{
    buy1(){
      if(vuex.getters.showName ===null|| vuex.getters.userInfo ===null){
        this.$message('请先登录再购买');
        this.$router.push({path:"/signIn"})
      }else{
        this.$router.push({path: "/setMeal1"})
      }
    },
    buy2(){
      if(vuex.getters.showName ===null|| vuex.getters.userInfo ===null){
        this.$message('请先登录再购买');
        this.$router.push({path:"/signIn"})
      }else{
        this.$router.push({path: "/setMeal2"})
      }
    },
    buyService(){
      if(vuex.getters.showName ===null|| vuex.getters.userInfo ===null){
        this.$message('请先登录再购买');
        this.$router.push({path:"/signIn"})
      }else{
        //回到顶部
        document.body.scrollTop = 0
        document.documentElement.scrollTop = 0
        window.pageYOffset = 0
      }
    }
  }
}
</script>

<style scoped>
.a .banner-top {
   width: 100%;
   min-width: 1234px;
  height: 200px;
  margin-top: 60px;
  background: linear-gradient(59.04deg, #39cdb4 0, #7befc7 100%);
}
.a .banner-top .banner-img {
  width: 1234px;
  height: 100%;
  position: relative;
}
.a .banner-top .banner-img p.banner-text1 {
  font-size: 27px;
  color: #fff;
  line-height: 27px;
  top: 70px;
  left: 48px;
}
.a .banner-top .banner-img p.banner-text2 {
  font-size: 19px;
  color: rgba(255,255,255,0.8);
  line-height: 19px;
  top: 112px;
  left: 48px;
}
.a .banner-top .banner-img p {
  position: absolute;
}
.a.memberPrice .banner-img {
  background: url(../../../public/static/web/img/bannerTrList4.png) center no-repeat;
}
@import "../../../public/static/plugin/validform/css/validform4d5f.css?v=3.3.49";
@import "../../../public/static/user/css/member/member4d5f.css?v=3.3.49";
@import "../../../public/static/user/css/member/memberPrice4d5f.css?v=3.3.49";
@import "../../../public/static/user/css/order/orderAlertMsg4d5f.css?v=3.3.49";
@import "../../../public/static/web/css/priceAssess4d5f.css?v=3.3.49";
</style>`
