<template>
    <div  v-loading="loading" class="coupon-content coupon-cont wd-shadow userComon">
      <div class="layui-tab layui-tab-brief">
<!--        <ul class="layui-tab-title" id="coupon-type">-->
<!--          <li class="layui-this" data-type="10">未使用</li>-->
<!--          <li data-type="20" class="">已使用</li>-->
<!--          <li data-type="30" class="">已过期</li>-->
<!--        </ul>-->
        <div class="layui-tab-content">
          <!--搜索框-->
<!--          <div class="search-box">-->
<!--            <input type="text" autocomplete="off" placeholder="请输入优惠券兑换码" class="layui-input" id="exchange-code">-->
<!--            <button class="layui-btn btn-search" id="exchange-btn">立即兑换</button>-->
<!--          </div>-->
          <div>
            <!--优惠券列表-->
            <ul v-if="coupons.length!==0" class="voucher-list">
              <li v-for="(item, index) in coupons" class="voucher-box mzq unuse-box">
              <div class="img-voucher">
                <div class="border-l"></div>
                <p><o>¥</o>
                  <span>{{ item.deductionamount }}</span></p>
              </div>
              <div class="voucher-cont">
                <div class="cont-top">
                  <span class="icon">优惠券</span>
                  <span class="title" title="满500元减100元">满{{ item.paylowerlimit }}元减{{ item.deductionamount }}元</span>
                  </div>
                <span class="packageType">适用套餐：{{ item.chatPackageNames }}</span>
                <div class="cont-center">

                  <span class="time">{{ item.endtime }} 失效</span>
                  <a href="/members" class="layui-btn to-use">立即使用</a>
                </div>
<!--                <div class="cont-bottom">-->
<!--&lt;!&ndash;                  <span class="text">使用规则</span>&ndash;&gt;-->
<!--                  <o class="icon-zhu"></o>-->
                  <div class="wd-shadow-around notes-text">
                    <div class="triangle_border_up">
                      <span></span> </div>
<!--                    <div class="text-content"> 适用于翻译与listing所有订单类型，订单金额满500元立减100元。 </div>-->
                  </div>
<!--                </div>-->
              </div>
              </li>
            </ul>
            <!--空态-->
            <div v-if="coupons.length===0" id="no-message" class="no-message">
              <i class="wd-center"></i>
              <p>您暂时还没有优惠券</p>
            </div>
          </div>
        </div>
      </div>
    </div>
</template>

<script>
import axios from "axios";

export default {
  name: "MyCoupon",
  data(){
    return{
      loading:true,
      coupons:[],
    }
  },
  created() {
    this.loading=true;
    let params={
      username:this.$store.getters.userInfo.username,
    }
    axios.post("/api/customer/couponMng/queryCoupon",params).then(res=>{
      if(res.data.success){
        console.log(res.data.result)
        this.coupons=res.data.result;
      }
      this.loading=false;
    })
  }
}
</script>

<style scoped>
.coupon-cont {
  display: block;
  float: left;
  width: 905px;
  margin-left: 15px;
  margin-bottom: 40px;
  background: #fff;
  border-radius: 4px;
  min-height: 600px;
}
.layui-tab {
  margin: 10px 0;
  text-align: left!important;
}
.coupon-cont .layui-tab-title {
  padding-left: 20px;
}
.coupon-cont .layui-tab-title .layui-this {
  color: #1aba79 !important;
}
.coupon-cont .layui-tab-title li {
  color: #484848;
  padding: 0 10px;
}
.coupon-cont .layui-tab-content {
  padding: 20px;
}
.coupon-cont .layui-tab-content .search-box input {
  width: 235px;
  height: 32px;
  vertical-align: middle;
  display: inline-block;
  border-radius: 4px;
}
.layui-input, .layui-textarea {
  display: block;
  width: 100%;
  padding-left: 10px;
}
.layui-input, .layui-select, .layui-textarea {
  height: 38px;
  line-height: 1.3;
  line-height: 38px\9;
  border-width: 1px;
  border-style: solid;
  background-color: #fff;
  border-radius: 2px;
}
.coupon-cont .layui-tab-content .search-box .btn-search {
  width: 90px;
  height: 32px;
  margin-left: 10px;
  vertical-align: middle;
  display: inline-block;
  background: #1aba79;
  border-radius: 4px;
  color: #fff;
  line-height: 32px;
}
.layui-show {
  display: block!important;
}
.coupon-cont .layui-tab-content ul.voucher-list {
  display: inline-block;
  margin-top: 30px;
}
.coupon-cont .layui-tab-content ul.voucher-list li.voucher-box {
  width: 276px;
  height: 104px;
  box-sizing: border-box;
  display: block;
  float: left;
  background: #fff;
  border: 1px solid rgba(0,0,0,0.02);
  box-shadow: 0 1px 4px rgba(0,0,0,0.01), 0 4px 8px rgba(0,0,0,0.02);
  border-radius: 4px;
  margin-right: 12px;
  margin-bottom: 16px;
}
.coupon-cont .layui-tab-content ul.voucher-list li.mzq .img-voucher {
  background: #58cd91;
}
.coupon-cont .layui-tab-content ul.voucher-list li.voucher-box .img-voucher {
  position: relative;
  width: 80px;
  height: 100%;
  display: block;
  float: left;
  vertical-align: top;
}
.coupon-cont .layui-tab-content ul.voucher-list li.mzq .img-voucher .border-l {
  background: url(../../../../public/static/common/img/img.png) no-repeat -3px 0;
  background-color: white;
  /*background: white;*/
}
.coupon-cont .layui-tab-content ul.voucher-list li.voucher-box .img-voucher .border-l {
  display: block;
  width: 3px;
  height: 100%;
  position: absolute;
  left: -3px;
}
.coupon-cont .layui-tab-content ul.voucher-list li.voucher-box .img-voucher p o {
  font-size: 14px;
}
.coupon-cont .layui-tab-content ul.voucher-list li.voucher-box .img-voucher p {
  text-align: center;
  font-size: 22px;
  font-family: ArialMT;
  color: #fff;
  line-height: 102px;
}
.coupon-cont .layui-tab-content ul.voucher-list li.voucher-box .voucher-cont {
  position: relative;
  display: block;
  float: left;
  width: 192px;
  vertical-align: top;
  box-sizing: border-box;
  padding: 10px 10px 0 10px;
  height: 100%;
}
.coupon-cont .layui-tab-content ul.voucher-list li.voucher-box .voucher-cont .cont-top .icon {
  display: inline-block;
  vertical-align: middle;
  width: 43px;
  height: 16px;
  font-size: 12px;
  text-align: center;
  line-height: 16px;
  line-height: 15px\0;
  color: #fff;
  border-radius: 2px;
}
.coupon-cont .layui-tab-content ul.voucher-list li.mzq .cont-top .icon {
  background: #67bf95;
}
.coupon-cont .layui-tab-content ul.voucher-list li.voucher-box .voucher-cont .cont-top .title {
  display: inline-block;
  max-width: 124px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  vertical-align: middle;
  color: #666;
}
.coupon-cont .layui-tab-content ul.voucher-list li.voucher-box .voucher-cont .cont-center {
  margin-top: 0px;
  width: 100%;
  height: 26px;
}
.packageType {
  display: inline-block;
  vertical-align: sub;
  font-size: 12px;
  font-family: ArialMT;
  color: #ccc;
}
.coupon-cont .layui-tab-content ul.voucher-list li.voucher-box .voucher-cont .cont-center .time {
  display: inline-block;
  vertical-align: sub;
  font-size: 12px;
  font-family: ArialMT;
  color: #ccc;
}
.coupon-cont .layui-tab-content ul.voucher-list li.voucher-box .voucher-cont .cont-center .to-use {
  display: inline-block;
  float: right;
  vertical-align: sub;
  text-align: center;
  font-size: 12px;
  line-height: 22px;
  background: #fff;
  padding: 0;
  width: 66px;
  height: 24px;
  border-radius: 13px;
}
.coupon-cont .layui-tab-content ul.voucher-list li.mzq .to-use {
  color: #67bf95;
  border: 1px solid #67bf95;
}
.to-use{
  margin-top: 3px;
}

.coupon-cont .layui-tab-content ul.voucher-list li.voucher-box .voucher-cont .cont-bottom {
   position: relative;
   width: 100%;
   margin-top: 5px;
   border-top: 1px dashed #eaeaea;
}
.coupon-cont .layui-tab-content ul.voucher-list li.voucher-box .voucher-cont .cont-bottom .text {
  font-size: 12px;
  color: #ccc;
  line-height: 26px;
  float: left;
  cursor: default;
}
.coupon-cont .layui-tab-content ul.voucher-list li.voucher-box .voucher-cont .cont-bottom .icon-zhu {
  position: relative;
  display: block;
  float: left;
  width: 12px;
  height: 12px;
  margin-top: 8px;
  margin-right: 2px;
  margin-left: 2px;
  background: url(../../../../public/static/common/img/icon_spirit.png) -20px -210px;
  cursor: pointer;
}
.notes-text {
  display: none;
  width: 250px;
  height: auto;
  background: #fff;
  border: 1px solid #f5f5f5;
  position: absolute;
  z-index: 999;
  top: 30px;
  left: -68px;
  border-radius: 4px;
}
.notes-text .triangle_border_up {
  width: 0;
  height: 0;
  border-width: 0 10px 10px;
  border-style: solid;
  border-color: transparent transparent #f5f5f5;
  position: absolute;
  top: -10px;
  left: 114px;
}
.notes-text .text-content {
  box-sizing: border-box;
  width: 100%;
  height: 100%;
  padding: 20px;
  font-size: 12px;
  line-height: 20px;
  color: #484848;
  top: 0;
}
.icon-zhu:hover .notes-text{
  display: block;
  width: 250px;
  height: auto;
  background: #fff;
  border: 1px solid #f5f5f5;
  position: absolute;
  z-index: 999;
  top: 30px;
  left: -68px;
  border-radius: 4px;
}
.no-message {
  box-sizing: border-box;
  width: 866px;
  float: left;
  margin-bottom: 100px;
}
.no-message i {
  width: 100px;
  height: 100px;
  background: url(../../../../public/static/common/img/common-icon.png) -419px -4px no-repeat;
  border-radius: 50%;
  display: block;
  margin-top: 100px;
}
.wd-center {
  margin-left: auto;
  margin-right: auto;
  display: block;
}
.no-message p {
  height: 16px;
  font-size: 16px;
  font-family: MicrosoftYaHei;
  font-weight: 400;
  color: #666;
  line-height: 24px;
  display: block;
  text-align: center;
  margin-top: 20px;
}

</style>
