<template>
  <!--页面内容-->
  <div style="margin-top: 60px ;">
    <div class="attitude" id="bodyContent">
      <div class="banner_ali">
        <div class="container">
          <div class="leftText fl" >
            <p class="title">使用说明</p>
            <p >CC翻译精汇客户最高频的问题</p>
            <p >在这里，90%的疑问都能得到解答</p>
          </div>
          <div class="fr rightImg"></div>
        </div>
      </div>
      <div class="container section">
        <div class="left">
          <ul class="cateList">
            <li v-for="(item, index) in items" :class="{ active: index === cIndex }" @click="itemOnClick(index)">{{ item.text }}</li>
          </ul>
        </div>
        <div class="right">
          <h3>
            <span>使用说明</span>
          </h3>
          <router-view></router-view>
        </div>
      </div>
    </div>
  </div>

</template>

<script>
export default {
  name: "Support",
  components:{

  },
  data(){
    return{
      cIndex:0,
      items:[
        { route: "/problem/page1", text:"平台功能" },
        { route: "/problem/page2", text:"订单支付" },
        { route: "/problem/page3", text:"平台服务" },
        { route: "/problem/page4", text:"会员套餐" },
        { route: "/problem/page5", text:"软件下载" },
        // { route: "/problem/page6", text:"跨境经营包" },
        // { route: "/problem/page7", text:"钜惠翻译包" },
        // { route: "/problem/page8", text:"语翼会员卡" },
      ],
      route:['/problem/page1','/problem/page2','/problem/page3','/problem/page4','/problem/page5','/problem/page6','/problem/page7','/problem/page8'],
    }
  },
  methods:{
    itemOnClick(index) {
      event.preventDefault(); //阻止默认事件

      // 路由跳转
        this.$router.push(this.items[index].route);
        this.cIndex = index;
    }
  },
  created() {
    // console.log(this.$route.path)
    if(this.route.indexOf(this.$route.path)>=0){
      this.cIndex=this.route.indexOf(this.$route.path);
    }
  }
}
</script>

<style scoped>
@import "../../../public/static/common/css/animate.min4d5f.css?v=3.3.49";
@import "../../../public/static/common/css/common4d5f.css?v=3.3.49";
/*@import "../../../public/static/web/css/series4d5f.css?v=3.3.49";*/
@import "../../../public/static/web/css/helpCenter4d5f.css?v=3.3.49";
@import "../../../public/static/common/css/common4d5f.css?v=3.3.49";
@import "../../../public/static/web/css/service_s4d5f.css?v=3.3.49";
@import "../../../public/static/web/css/productservice4d5f.css?v=3.3.49";
.head img{
  width: 100%;
  height: 100%;
}
.attitude{
  background: #fff;
}
</style>
