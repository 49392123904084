<template>
  <div style="background-color: #F5F5F5; padding-top: 80px">
    <!--页面内容-->
    <div class="wd-content" style="">
      <div class="layui-container login-position">
        <div class="swimg-box" style="">
<!--          <a href="vip.html" ><img src="../filemanager.woordee.com/d5b19f631479c2fff9314a92d510a8360f86759c4d5f.png?v=3.3.49" alt="人工翻译"/></a>-->
          <a href="/members" ><img src="../../../public/static/common/img/i_01.png" alt="人工翻译"/></a>
        </div>
        <!-- 登录组件start-->
<!--        <link rel="stylesheet" type="text/css" href="static/common/css/login4d5f.css?v=3.3.49" />-->
        <div class="login-box wd-shadow" style="background: #fff">
          <div class="wd-log" style="overflow: hidden;">
            <div class="wd-center icon-log"></div>
            <p class="text-log">智能翻译，通达世界</p>
            <div class="user-login" style="margin-top:20px;margin-bottom:40px">
              <!-- 手机密码登录 start -->
              <form class="wd-center layui-form captcha-form" style="display:block">
                <div class="row">
                  <div class="log-input">
<!--                    <input type="text" name="account" value="" placeholder="邮箱" datatype="mobile|e" nullmsg="请输入邮箱" errormsg="请输入正确的邮箱" class="layui-input">-->
                    <el-input v-model="model.username" placeholder="请输入用户名" @blur="handleEmailCheck" :oninput="model.username = model.username.replace(/\s+/g,'')"></el-input>
                    <sapn class="tishi">{{ this.usernameInfo }}</sapn>
                  </div>
                </div>

                <div class="row" id="pwdDiv" >
                  <div class="log-input input-pwd">
<!--                    <input type="password" name="password" maxlength="20" datatype="*6-20"  placeholder="请输入密码" errormsg="请输入密码(6-20位字符)" nullmsg="请输入密码" class="layui-input password noBlank">-->
                    <el-input placeholder="请输入密码" v-model="model.password" show-password auto-complete="new-password" :oninput="model.password = model.password.replace(/\s+/g,'')" @blur="handlepwdCheck"></el-input>
                    <sapn class="tishi">{{ this.passwordInfo }}</sapn>
<!--                    <i class="passwordeye eye visible"></i>-->
                  </div>
                  <div class="Validform_checktip"></div>
                </div>
                <!--密码登录错误3次，显示滑块验证码-->
<!--                <div class="row" id="phcode" style="height:58px;display:none"></div>-->
                <div class="wd-center opera">
                  <a class="wd-fl p-code pwd-to-code" href="/create" index="bycode">注册</a>
                  <a class="wd-fr forgot" target="_top" href="/lost">忘记密码？</a>
                </div>

              </form><button class="layui-btn wd-center btn-login btn-pwd-login" @keyup.enter="keyDown(e)" :disabled="flag" @click="login">登 录</button>


              <!-- 第三方登录 start-->
              <div class="wd-center third-party">
                <div class="header">
<!--                  <span>使用第三方帐号登录</span>-->
                </div>
                <div class="msg wd-center">
                </div>
              </div>
              <!-- 第三方登录 end-->
              <div class="bottom" style="display:block">
                <p>没有帐号？<a href="/create" target="_top" index="toregister">注册</a></p>
              </div>
            </div>
          </div>
        </div>
  </div>
    </div>
  </div>
</template>
<script>
import axios from "axios";
export default {
  name: "SignIn",
  data() {
    return {
      flag:false,//防止重复提交，暂时禁用按钮标志
      model: {
        username: '',
        password: '',
      },
      user:{},
      usernameInfo:'',
      passwordInfo:'',
    }
  },
    methods:{
      handleEmailCheck() {
        // var reg=/^([a-zA-Z]|[0-9])(\w|\-)+@[a-zA-Z0-9]+\.([a-zA-Z]{2,4})$/;
        // if(!reg.test(this.model.username)){
        //   // callback(new Error("请输入正确的邮箱号"))
        //   this.usernameInfo='请输入正确邮箱'
        // }else{
        //   this.usernameInfo=''
        // }
        if(this.model.username===null||this.model.username===''){
          this.usernameInfo='账号不能为空'
        }else{
          this.usernameInfo=''
        }
      },
      handlepwdCheck(){
        if(this.model.password===null||this.model.password===''){
          this.passwordInfo='请输入密码'
        }else if(this.model.password.length<6||this.model.password.length>20){
            this.passwordInfo='请输入密码(6-20位字符)'
        }else{
          this.passwordInfo='';
        }
      },
      login(){
        // console.log(11111)
        if(this.model.username===null||this.model.username===''){
            this.$message({
              message: '账号不能为空',
              type: 'warning'
            });
          }else if(this.model.password===null||this.model.password===''){
            this.$message({
              message: '密码不能为空',
              type: 'warning'
            });
          }else{
          this.flag=true;
            let loginInfo={
                  username:this.model.username,
                  password: this.model.password,
            }
            // console.log("登录参数", loginInfo)
                axios
                .post("/api/customer/customerMng/login",loginInfo).then(res=>{
                  // console.log(res)
                  if(res.data.success){
                    // console.log(res.data.result)
                    this.$store.commit('handleUserInfo',res.data.result.customer);
                    this.$store.commit('handleShowName',true);
                    this.$store.commit('handleToken',res.data.result.token);
                    this.$router.push({path :"/personal"})
                    this.$message({
                      message: '登录成功',
                      type: 'success',
                      showClose: true,
                    });

                  }else if(!res.data.success){
                    if(res.data==="访问超时，请稍后再试!"){
                      this.$message.error(res.data);
                    }else{
                      this.$message.error(res.data.message);
                    }
                  }
            }).finally(() => {
                  setTimeout(() => {
                    this.flag = false;
                  }, 2000)
                });
            // if(this.model.username==="123456@qq.com"&&this.model.password==="123456"){
            //     this.user.name="fu";
            //     this.user.email="410540271@qq.com"
            //     this.$store.commit('handleUserInfo',this.user);
            //     this.$store.commit('handleShowName',true);
            //     this.$router.push({path :"/person"})
            // }else{
            //   this.$message.error('密码或账号错误');
            // }
          }
        },
    },
  created() {
    document.onkeydown =(e) => {
      e = window.event || e;
      if(this.$route.path==='/signIn'&&(e.code==='Enter'||e.code==='enter')){
        //调用登录事件方法
        this.login();
      }
    }
  },
}

</script>

<style scoped>
@import "../../../public/static/common/css/login4d5f.css?v=3.3.49";
@import "../../../public/static/user/css/login4d5f.css?v=3.3.49";
@import "../../../public/static/plugin/validform/css/validform4d5f.css?v=3.3.49";
.tishi{
  color: #F83333;
  font-size: 12px;
}
</style>
