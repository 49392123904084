<template>
  <div>
    <div class="newwoordee">
      <div class="content">
        <i class="rotop"></i>
        <i class="roleft"></i>
        <i class="rorigjt"></i>
        <i class="romini"></i>
        <i class="rojt"></i>
        <i class="tree"></i>
        <i class="img-new"></i>
        <p class="p1">CC翻译全新产品上线</p>
        <p class="p2">新用户赠送30000字符</p>
        <a href="/create" target="_blank" class="link"><i class="ipre"></i><span>立即领取</span></a>
      </div>
    </div>
    <section class="section memberPrivileges">
      <div class="memberPrivilegesSection">
        <div class="memberInfo-c">
          <div class="memberCenter">
            <i class="triangle_border_up up1" style="display: inline;"></i>
            <i class="triangle_border_up up2" style="display: none;"></i>
            <i class="triangle_border_up up3" style="display: none;"></i>
            <!--CC翻译桌面版-Windows-64位下载-->
            <div class="center-box box1" style="display: block;">
              <ul class="box-ul  listingpack1">
                <li class="box-li level1">
                  <div class="title">
                    <h3>Windows</h3>
                    <span>CC翻译桌面版-Windows安装包下载</span>
                  </div>
                  <!--                <ul class="detail">-->
                  <!--                  <li>赠价值299银牌会员，享服务8.8折优惠</li><li>适用于listing撰写、listing优化服务</li><li>购买后3个月内使用</li>                            </ul>-->
                  <div class="windows">
                    <div style="padding: 20px;">
                      <img src="../../../public/static/common/img/i_08_windows.png" width="100" height="100">
                    </div>
                    <div class="bottom">
                      <button class="to-pay   paypack" _goodscate="listpack" _goodsid="55f68e06505a482fad57e7de1ef13d5c" _price="1068" _oname="windows" _name="windows" _level="level1">
                        <a href="https://wwd.lanzoul.com/b03vauj9i" target="_blank">立即下载</a>
                      </button>
                    </div>
                  </div>
                </li>
                <li class="box-li level2">
                  <div class="title">
                    <h3>Mac</h3>
                    <span>CC翻译桌面版-Mac安装包下载</span>
                  </div>
                  <!--                <ul class="detail">-->
                  <!--                  <li>赠价值599金牌会员，享服务8折优惠</li><li>适用于listing撰写、listing优化服务</li><li>购买后6个月内使用</li>                            </ul>-->
                  <div class="mac">
                    <div style="padding: 20px;">
                      <img src="../../../public/static/common/img/i_08_mac.png" width="100" height="100">
                    </div>
                    <div class="bottom">
                      <button class="to-pay  paypack" _goodscate="listpack" _goodsid="efb9c98af9a9444d9ef2d036f143d6ed" _price="3081" _oname="步步为营包" _name="MAC" _level="level2">
                        <a href="https://wwd.lanzoul.com/b00b3lcvid" target="_blank">立即下载</a>
                      </button>
                    </div>
                  </div>

                </li>
                <li class="box-li level1">
                  <div class="title">
                    <h3>分身版</h3>
                    <span>CC翻译桌面版分身安装包下载</span>
                  </div>
                  <!--                <ul class="detail">-->
                  <!--                  <li>赠价值299银牌会员，享服务8.8折优惠</li><li>适用于listing撰写、listing优化服务</li><li>购买后3个月内使用</li>                            </ul>-->
                  <div class="windows">
                    <div style="padding: 20px;">
                      <img src="../../../public/static/common/img/i_08_windows.png" width="100" height="100">
                    </div>
                    <div class="bottom">
                      <button class="to-pay   paypack" _goodscate="listpack" _goodsid="55f68e06505a482fad57e7de1ef13d5c" _price="1068" _oname="windows" _name="windows" _level="level1">
                        <a href="https://wwd.lanzoul.com/b00b3lcvza" target="_blank">立即下载</a>
                      </button>
                    </div>
                  </div>
                </li>
              </ul>
            </div>
          </div>
        </div>

      </div>
    </section>
  </div>


</template>

<script>

  export default {
    name : "download",

  }
</script>

<style scoped>
.to-pay{
  margin-top: 10px;
}
.windows1{
  background: url("../../../public/static/common/img/i_04.png");
  background-size: 100% 100%;
}
.mac1{
  background: url("../../../public/static/common/img/i_05.png");
  background-size: 100% 95%;
}
.linux1{
  background: url("../../../public/static/common/img/i_06.png");
  background-size: 100% 85%;
}
.section {
  width: 1140px;
  margin: 0 auto;
  padding-bottom: 65px;
}
.section {
  display: table;
   padding: 0px 0;
}
.section .list .mallRecommend {
  background: url(../../../public/static/common/img/i_03.png) no-repeat;
  background-size: cover;
}
.section .list li {
  width: 274px;
  height: 330px;
  float: left;
  padding: 0 20px;
  overflow: hidden;
  margin-left: 14px;
  background: #fff;
  box-sizing: border-box;
}
p {
  display: block;
  margin-block-start: 1em;
  margin-block-end: 1em;
  margin-inline-start: 0px;
  margin-inline-end: 0px;
}
.section .nav {
  overflow: hidden;
  padding-top: 30px;
}
.section .nav .name {
  float: left;
}
.section .nav .name .title {
  color: #222;
  font-size: 18px;
  float: left;
  line-height: 20px;
}
.section .nav .name .text {
  color: #999;
  font-size: 14px;
  float: left;
  padding-left: 8px;
  line-height: 20px;
}
.section .list {
  padding-top: 20px;
  overflow: hidden;
}
.section .list li:first-child {
  margin-left: 0;
}
.section .list .prize {
  text-align: center;
  padding-top: 20px;
  position: relative;
}
.section .list li {
   width: 274px;
   height: 330px;
   float: left;
   padding: 0 20px;
   overflow: hidden;
   margin-left: 14px;
   background: #fff;
   box-sizing: border-box;
}
button, input, optgroup, option, select, textarea {
  font-family: inherit;
  font-size: inherit;
  font-style: inherit;
  font-weight: inherit;
  outline: 0;
}
.section .list .prize .btn {
  border: 1px solid #ff9626;
  border-radius: 4px;
  width: 120px;
  height: 32px;
  line-height: 30px;
  color: #ff9626;
  background: #fff;
  cursor: pointer;
  margin-top: 0px;
  transition: .5s;
}
</style>
