<template>
  <div class="page1">
    <ul class="faqList">
      <el-collapse class="list" v-model="activeName" accordion>
        <el-collapse-item title="1、如何开始使用CC翻译软件？" name="1">
<!--          <div>与现实生活一致：与现实生活的流程、逻辑保持一致，遵循用户习惯的语言和概念；</div>-->
<!--          <div>在界面中一致：所有的元素和结构需保持一致，比如：设计样式、图标和文本、元素的位置等。</div>-->
          <div class="info">
            <p><span style="font-family: 微软雅黑, &quot;Microsoft YaHei&quot;; font-size: 14px;">快速使用：</span></p><p><br/></p><p><span style="font-family: 微软雅黑, &quot;Microsoft YaHei&quot;; font-size: 14px;">1 .注册账号，通过点击右上方<a href="/create">【注册有礼】</a>按钮进入注册页面，根据页面指引完成注册。</span></p><p><span style="font-family: 微软雅黑, &quot;Microsoft YaHei&quot;; font-size: 14px;">
            2. 软件下载，点击导航栏<a href="/download">【软件下载】</a>按钮进入下载页面，选择对应操作系统版本进行下载。</span></p></div>
        </el-collapse-item>
        <el-collapse-item title="2、如何使用翻译功能？" name="2">
          <div class="info">
                        <p class="MsoNormal" style="margin-left:15.75pt;">
                          新注册账号之后，系统会赠送一定的字符量，当使用翻译时，会扣除字符余量，为了不影响使用可以点击导航栏<a href="/members" style="white-space: normal;"> 【会员专享】 </a>购买字符。<span></span>
                        </p>									</div>
        </el-collapse-item>
        <el-collapse-item title="3、如何切换翻译工具？" name="3">
          <div class="info">
            <p class="MsoListParagraph" style="margin-left: 18pt; text-indent: 0cm;">
              登录官网，进入<a href="/personal" style="white-space: normal;">【个人中心】</a>选择翻译通道即可，或则在客户端直接切换。</p></div>
        </el-collapse-item>
        <el-collapse-item title="4、如何获取优惠券？" name="4">
          <div class="info">
            <p class="MsoNormal"><span style="font-family: 微软雅黑, "><span style="font-family: 微软雅黑, ">当新用户注册或者充值时，会进行优惠券的赠送，具体情况根据当前活动内容决定</span></span></p></div>
        </el-collapse-item>
        <el-collapse-item title="5、什么是高级账户？" name="5">
          <div class="info">
            <p class="MsoNormal">
              高级账户可以创建多个子账户，并且为子账户分配字符，子账户无需购买套餐，即可享用高级账户的字符套餐。</p></div>
        </el-collapse-item>
        <el-collapse-item title="6、如何成为高级账户？" name="6">
          <div class="info">
            <p><span style="font-family: 微软雅黑, &quot;Microsoft YaHei&quot;; font-size: 14px;">当充值金额到达300，系统自动将账号升级为企业账户，并为其分配一定数量的子账户，如果需要继续增加子账户数量，请联系客服。（具体充值金额与子账户数量由当前活动决定）</span></p></div>
        </el-collapse-item>
        <el-collapse-item title="7、如何查看订单状态？" name="7">
          <div class="info">
            <p><span style="font-family:&quot;font-size:14px;">您有如下两种方式可以查看订单状态：</span><br/><span style="font-family:&quot;font-size:14px;">您可以通过CC翻译官网，点击右侧的<a href="/personal/MyOrder" style="white-space: normal;">【我的订单】</a>按钮查看您的历史订单。</span></p></div>
        </el-collapse-item>
<!--        <el-collapse-item title="8、积分和金币如何兑换优惠券和礼品？" name="8">-->
<!--          <div class="info">-->
<!--            <p class="MsoNormal" style="margin-top:3.75pt;margin-right:0cm;margin-bottom:3.75pt;margin-left:0cm;">-->
<!--              完成系统任务后，您会得到一定的积分或金币。您领取积分和金币后，即可前往语翼兑换商城兑换各种优惠券或礼品了。每兑换成功一个商品，会扣减相应的积分或金币。积分或金币不足，可前往成就系统做任务或取更多积分或金币。点击直接进入<a href="https://point.woordee.com/" target="_blank" title="积分商城">兑换商城</a></p><p class="MsoNormal" style="margin: 3.75pt 0cm;"><br/></p>									</div>-->
<!--        </el-collapse-item>-->
<!--        <el-collapse-item title="9、如何领取及使用优惠券？" name="9">-->
<!--          <div class="info">-->
<!--            <p><span style="font-family: 微软雅黑, ">优惠券的领取有两种方式：</span></p><p><span style="font-family: 微软雅黑, "><br/></span></p><p><span style="font-family: 微软雅黑, ">1. 使用优惠码兑换：当您收到优惠券码时，可在官网右上角头像进入【个人中心】-【我的优惠券】，在【未使用】下方的输入框内，输入优惠码，点击【立即兑换】即可兑换领取；</span></p><p><span style="font-family: 微软雅黑, "><br/></span></p><p><span style="font-family: 微软雅黑, ">2. 优惠券自动到账：如您在参与活动时获得了优惠券奖励，或者在投诉后获得优惠券赔偿时，您的客户经理或者在线客服将帮您申请好指定金额的优惠券，通过后台发送到您的语翼账号，此时您无需手动操作领取优惠券，您可以直接前往【个人中心】-【我的优惠券】-【未使用】页面查看优惠券是否到账。</span></p><p><span style="font-family: 微软雅黑, "><br/></span></p><p><span style="font-family: 微软雅黑, "></span></p><p style="white-space: normal;"><span style="font-family: 微软雅黑, ">优惠券使用办法：</span></p><p style="white-space: normal;"><span style="font-family: 微软雅黑, ">下单支付时，如果订单符合优惠券使用条件，系统会自动勾选该券抵扣费用，现PC端与手机端均支持下单用券。</span></p>									</div>-->
<!--        </el-collapse-item>-->
<!--        <el-collapse-item title="10、语翼woordee翻译订单支持哪些格式的文件上传？" name="10">-->
<!--          <div class="info">-->
<!--            <p><span style="font-family: 微软雅黑, &quot;Microsoft YaHei&quot;; font-size: 14px;"></span></p><p><span style="font-family: 微软雅黑, &quot;Microsoft YaHei&quot;; font-size: 14px;">语翼woordee旨在优化客户的操作体验，提供了一站式人工翻译服务和电子化文字提取和排版功能。<br/></span></p><p><span style="font-family: 微软雅黑, &quot;Microsoft YaHei&quot;; font-size: 14px;">您可以在【快速下单】-【文档翻译】入口上传稿件处一键上传包括doc/.docx/.ppt/.pptx/.xls/.xlsx/的office文档，也可以直接输入或复制粘贴需要翻译的文字（限2000字以内），如果您需要更多文档类型的翻译，请使用“管家服务”，下单后由管家客服联系您完成报价。</span></p>									</div>-->
<!--        </el-collapse-item>-->
<!--        <el-collapse-item title="11、语翼woordee支持自动统计文档字数吗？" name="11">-->
<!--          <div class="info">-->
<!--            <p>-->
<!--              <span style="font-family:&quot;font-size:14px;">语翼支持自动统计文档字数。</span>-->
<!--            </p>-->
<!--            <p>-->
<!--              <span style="font-family:&quot;font-size:14px;">在您下单上传稿件文档（或手动复制粘贴文档内容）后，语翼会在界面右侧自动统计出该文档的字数，免去了您在平台外手动统计的麻烦。</span>-->
<!--            </p>									</div>-->
<!--        </el-collapse-item>-->
<!--        <el-collapse-item title="12、语翼woordee是否支持自动检测翻译文档的源语言？" name="12">-->
<!--          <div class="info">-->
<!--            <span style="font-family:&quot;font-size:14px;">语翼暂不支持自动检测文档源语言。</span><br />-->
<!--            <span style="font-family:&quot;font-size:14px;">考虑到文档内可能会出现多个语种导致检测失败的情况，语翼暂时不会自动检测文档的源语言。</span><br />									</div>-->
<!--        </el-collapse-item>-->
<!--        <el-collapse-item title="13、如何查看订单状态？" name="13">-->
<!--          <div class="info">-->
<!--            <p><span style="font-family:&quot;font-size:14px;">您有如下两种方式可以查看订单状态：</span><br/><span style="font-family:&quot;font-size:14px;">1. 您可以通过登录<a href="../../index.html" target="_blank">语翼官网</a>，点击搜索框右侧的【我的订单】按钮查看您的历史订单。</span><br/><span style="font-family:&quot;font-size:14px;">2. 进入首页右上角【个人中心】进入【个人资料】页面，扫描您的专属二维码后可在微信端【我的订单】查看订单状态。</span><br/></p>									</div>-->
<!--        </el-collapse-item>-->
<!--        <el-collapse-item title="14、语翼woordee的翻译订单可以取消吗？" name="14">-->
<!--          <div class="info">-->
<!--            <p>-->
<!--              <span style="font-family:'Microsoft YaHei';"><span style="font-size:14px;">若您下错了订单（选择了错误源语言或目标语言，或者上传了错误的文档等），</span></span>-->
<!--            </p>-->
<!--            <p>-->
<!--              <span style="font-family:'Microsoft YaHei';"><span style="font-size:14px;">在支付之前，您可以点击【取消订单】按钮取消订单。</span></span><span style="font-family:&quot;font-size:14px;">若您的订单24小时内未支付，订单将自动取消。</span>-->
<!--            </p>-->
<!--            <span style="font-family:&quot;font-size:14px;">如您订单已支付，系统提示正在匹配译员，译员未接单前，您可以联系在线客服人员帮你取消订单；</span><br />-->
<!--            <span style="font-family:&quot;font-size:14px;">如译员已经接单进行翻译，则订单无法取消，请您谅解。</span><br />									</div>-->
<!--        </el-collapse-item>-->
<!--        <el-collapse-item title="15、语翼woordee是否支持修改订单？" name="15">-->
<!--          <div class="info">-->
<!--            <p><span style="font-family:&quot;font-size:14px;">语翼目前暂不支持修改订单。</span><br/><span style="font-family:&quot;font-size:14px;">如果您下错单（如选错源语言或目标语言、上传了错误文档等）且尚未支付，您可以点击首页【快速下单】再次下单并进行支付即可。</span><br/><span style="font-family:&quot;font-size:14px;">如果您下错单但已支付订单，在译员接单前您可以联系在线客服进行订单修改或者取消订单。</span><br/><span style="font-family:&quot;font-size:14px;">如译员已经接单进行翻译，则此订单已产生翻译费用，订单无法取消，请您谅解。但你仍然可以紧急联系在线客服或者您的客户经理帮您申请退回未产生的部分费用。</span><br/></p>									</div>-->
<!--        </el-collapse-item>-->
<!--        <el-collapse-item title="16、语翼woordee是否支持收藏译员？收藏译员有什么作用？" name="16">-->
<!--          <div class="info">-->
<!--            <span style="font-family:&quot;font-size:14px;">语翼支持收藏译员。在译员接单后，如果您和译员的在线沟通非常顺畅，并且在您的订单完成后，您通过查看译稿，如果您对稿件质量很满意，您可以点击“收藏译员”按钮收藏此译员。</span><br />-->
<!--            <span style="font-family:&quot;font-size:14px;">收藏译员后，您后续下单后，系统会根据您稿件的所在领域和内容优先匹配您已收藏的译员。</span><br />									</div>-->
<!--        </el-collapse-item>-->
<!--        <el-collapse-item title="17、语翼是否有译员黑名单的功能？" name="17">-->
<!--          <div class="info">-->
<!--            <p><span style="font-family:&quot;font-size:14px;">语翼V2.4版本已上线译员黑名单功能，用户可以通过【个人中心】&gt; 【合作的译员】里点击头像左侧的屏蔽图标将此译员进行屏蔽，后续您的订单都不会分配到该译员名下。</span></p>									</div>-->
<!--        </el-collapse-item>-->
      </el-collapse>
    </ul>
  </div>
</template>

<script>
export default {
  name: "page1",
  data() {
    return {
      activeName: '0'
    };
  }
}
</script>

<style scoped>
.info {
  background: #f2f2f2;
  padding: 15px 20px;
  border-radius: 10px;
}
</style>
