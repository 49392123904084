<template>
<div class="page7">
  <ul class="faqList">
    <el-collapse class="list" v-model="activeName" accordion>
      <el-collapse-item title="1、什么是钜惠翻译包？" name="1">
        <div class="info">
          <p>1、钜惠翻译包是面向有翻译需求用户提供的翻译服务费与会员服务优惠组合。</p><p>2、购买钜惠翻译包将获得「翻译服务费」；每个包含有不同的内容，您可以按需选择合适的包。</p><p>3、「翻译服务费」为预存费用，在个人中心-我的翻译包中查看。</p><p>4、新用户购买赠送语翼会员</p><p><br/></p>									</div>
      </el-collapse-item>
    </el-collapse>
  </ul>
</div>
</template>

<script>
export default {
  name: "page7",
  data() {
    return {
      activeName: '0'
    };
  }
}
</script>

<style scoped>
.info {
  background: #f2f2f2;
  padding: 15px 20px;
  border-radius: 10px;
}
</style>
