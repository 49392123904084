<template>
  <div class="page2">
    <ul class="faqList">
      <el-collapse class="list" v-model="activeName" accordion>
        <el-collapse-item title="1、如何下单？" name="1">
          <div class="info">
            <p>您需要先注册并登陆账号。</p><p></p><p>1. 点击<a href="/members" style="white-space: normal;"> 【会员专享】 </a>查看套餐<a href="/members" style="white-space: normal;"></a></p><p>2. 选择一个合适的套餐，点击立即购买，然后选择购买数量</p><p>3. 选择支付方式，点击去支付进行支付。</p><p><br/></p>									</div>
        </el-collapse-item>
        <el-collapse-item title="2、如何使用优惠券？" name="2">
          <div class="info">
            <p><span style="font-family:">当您在购买页面点击下单时，如果有可以使用的优惠券</span><br/><span style="font-family:">页面会弹出选择优惠券，您只需要点击选择相应的优惠券即可<a href="../../price.html">【会员专享】</a>进入购买页面查看。</span><br/></p>									</div>
        </el-collapse-item>
        <el-collapse-item title="3、如何选择购买套餐数量？" name="3">
          <div class="info">
            <p><span style="font-family:">当您在购买页面点击下单时，您可以选择您的购数量。</span><br/><span style="font-family:">详情可点击首页导航栏<a href="../../price.html">【会员专享】</a>进入购买页面查看。</span><br/></p>									</div>
        </el-collapse-item>
        <el-collapse-item title="4、支付流程及方式？" name="4">
          <div class="info">
            <p><span style="font-family: 微软雅黑, &quot;Microsoft YaHei&quot;; font-size: 14px;">为确保翻译质量和您的根本利益，您需要提前购买套餐。即您在下单购买套餐时，平台收到您的付款后会为您更新您的字符余量等内容。</span><span style="font-family: 微软雅黑, &quot;Microsoft YaHei&quot;; font-size: 14px;">我们支持支付宝、微信、等多种支付方式。</span></p><p><br/></p><p><span style="font-family: 微软雅黑, &quot;Microsoft YaHei&quot;; font-size: 14px;">温馨提示:</span></p><p><br/></p><p><span style="font-family: 微软雅黑, &quot;Microsoft YaHei&quot;; font-size: 14px;">如果付款过程遇到问题，请您联系在线网络客服。</span></p>	</div>
        </el-collapse-item>
        <el-collapse-item title="5、已经付款订单依然显示支付中？" name="5">
          <div class="info">
            <p>您在支付订单后，订单状态依然显示支付中。</p><p><br/></p><p>更新订单：</p><p>1. 登录CC翻译官网;</p><p>2. 点击右上方<a href="/personal/MyOrder">【我的订单】</a>按钮进入订单列表</p><p>3. 点击操作栏下的刷新按钮即可刷新订单。</p><p><br/></p></div>
        </el-collapse-item>
        <el-collapse-item title="6、付款成功的订单确显示支付失败？" name="6">
          <div class="info">
            <p><span style="font-family:&quot;font-size:14px;">遇到已经付款成功但是订单支付失败的情况请扫描右下方二维码添加客服手动更新订单</span></p></div>
        </el-collapse-item>
        <el-collapse-item title="7、如何查看以前的订单？" name="7">
          <div class="info">
            <p><span style="font-family: 微软雅黑, "></span>点击右上方<a href="/personal/MyOrder">【我的订单】</a>按钮进入订单列表，左上方选择需要查询订单的时间范围，就可以查询出来该时间段的所有订单</p></div>
        </el-collapse-item>
        <el-collapse-item title="8、如何使用订单号查询订单？" name="8">
          <div class="info">
            <span style="font-family:&quot;font-size:14px;">点击右上方<a href="/personal/MyOrder">【我的订单】</a>按钮进入订单列表，右上方输入要查询的订单号进行查询</span><br /></div>
        </el-collapse-item>
<!--        <el-collapse-item title="9、管家单如何支付？" name="9">-->
<!--          <div class="info">-->
<!--            <p><span style="font-family: 微软雅黑, ">您收到管家完成的报价短信后，登陆语翼进入我的订单中完成在线支付即可。</span></p><p><span style="font-family: 微软雅黑, ">您还可以进入【个人中心】-【我的翻译包】-【充值】页面进行充值，使用账户余额支付。</span></p>									</div>-->
<!--        </el-collapse-item>-->
<!--        <el-collapse-item title="10、下单时标准级与专业级的区别？" name="10">-->
<!--          <div class="info">-->
<!--            <p>-->
<!--              <span style="font-family:&quot;font-size:14px;">标准级一般适用于非专业的场合或领域，比如零售或消费品，个人使用材料等要求没有那么高，用于内部交流的场景。</span><br />-->
<!--              <span style="font-family:&quot;font-size:14px;">专业级更适用于商务洽谈等专业场合，包括医疗及医疗设备，媒体宣传/发言稿等专业度要求很高的领域，同时专业级稿件对译员老师的专业性、翻译经验以及准确度要求也极高。我们建议您根据稿件类型和用途来选择标准/专业级翻译，可以更精准匹配您此次的翻译需求。</span><br />-->
<!--              <span style="font-family:&quot;font-size:14px;"></span>-->
<!--            </p>									</div>-->
<!--        </el-collapse-item>-->
      </el-collapse>
    </ul>
  </div>
</template>

<script>
export default {
  name: "page2",
  data() {
    return {
      activeName: '0'
    };
  }
}
</script>

<style scoped>
.info {
  background: #f2f2f2;
  padding: 15px 20px;
  border-radius: 10px;
}
</style>
