<template>
<div class="page5">
  <ul class="faqList">
    <el-collapse class="list" v-model="activeName" accordion>
      <el-collapse-item title="1、什么是跨境经营包" name="1">
        <div class="info">
          <p>1、跨境经营包是面向跨境电商用户提供的Listing服务优惠组合。</p><p>2、购买跨境经营包将获得「listing服务券」，每个包含有不同的内容，您可以按需选择合适的包。</p><p>3、「listing服务券」可用于源语种为中文的listing撰写、listing优化服务，不同的「listing服务券」支持不同的站点。</p><p>4、不同的包中的「listing服务券」使用有效期不同，有效期见包中描述。</p><p>5、「listing服务券」在个人中心-我的优惠券中查看。</p><p>6、新用户购买赠送语翼会员</p><p><br/></p>									</div>
      </el-collapse-item>
    </el-collapse>
  </ul>
</div>
</template>

<script>
export default {
  name: "page6",
  data() {
    return {
      activeName: '0'
    };
  }
}
</script>

<style scoped>
.info {
  background: #f2f2f2;
  padding: 15px 20px;
  border-radius: 10px;
}
</style>
