<template>
  <div id="app">
    <navbar/>
<!--    <router-view></router-view>-->
    <div id="v-content" v-bind:style="{minHeight: Height+'px'}"><router-view /></div>
<!--    <footer1/>-->
    <side/>
    <div v-if="!$route.meta.keepAlive">
      <x-footer/>
    </div>
  </div>
</template>

<script>
  import Navbar from './components/navbar/Navbar';
  import xFooter from '@/components/footer/Footer.vue';
  import side from "@/components/side/side";

  export default {
    name:"App",
    components : {
        Navbar,
        xFooter,
        side,
      },
    data() {
      return {
        Height: 0
      }
    },
    mounted(){
      //动态设置内容高度 让footer始终居底   header+footer的高度是160
      // this.Height = document.documentElement.clientHeight - 294;
      // //监听浏览器窗口变化　
      // window.onresize = ()=> {this.Height = document.documentElement.clientHeight -294}
      //动态设置内容高度 让footer始终居底   header+footer的高度是160
      this.Height = document.documentElement.clientHeight - 294;
      //监听浏览器窗口变化　
      window.onresize = ()=> {this.Height = document.documentElement.clientHeight -294}
    }
  }
</script>


<style>
  /* 全局CSS导入 */
  @import '~@/assets/css/normalize.css';
  @import '~@/assets/css/base.css';
  @import '../public/static/plugin/layui/css/layui4d5f.css?v=3.3.49';
  @import '../public/static/common/css/style4d5f.css?v=3.3.49';
  @import '../public/static/plugin/mCustomScrollbar/jquery.mCustomScrollbar4d5f.css?v=3.3.49';
  @import '../public/static/common/css/wdpublic4d5f.css?v=3.3.49';
  @import '../public/static/common/font_svg/iconfont4d5f.css?v=3.3.49';
  @import '../public/static/common/css/footer4d5f.css?v=3.3.49';
</style>
