<template>
    <div class="wd-content">
      <div class="layui-container">
        <div class="user-left wd-shadow">
          <!--个人中心左侧边栏-->
          <div v-if="this.$store.getters.userInfo.usertype!=='3'" class="user-menu-l">
            <!--侧边列表-->
            <ul class="um-list">
              <li class="list">
                <h2 class="primary active">
                  <i class="layui-icon layui-icon-username"></i><span>个人中心</span>
                </h2>
                <!--                </ul>-->
                <ul class="childer">
                  <li v-for="(item, index) in items">
                    <a
                        href=""
                        @click="itemOnClick(index)"
                        :class="{ active: index === cIndex }"
                    >{{ item.text }}</a
                    >
                  </li>
                </ul>
              </li>
              <li class="list">
                <h2 class="primary">
                  <i class="layui-icon layui-icon-set"></i><span>个人设置</span>
                </h2>
                <ul class="childer">
                  <li v-for="(item, index) in items1">
                    <a
                        href=""
                        @click="itemOnClick1(index)"
                        :class="{ active: index+4 === cIndex }"
                    >{{ item.text }}</a
                    >
                  </li>
                </ul>
              </li>
              <div v-if="this.$store.getters.userInfo.isagent==='Y'"  class="user-menu-l">
                <li class="list">
                  <h2 class="primary">
                    <i class="layui-icon layui-icon-user"></i><span>代理信息</span>
                  </h2>
                  <ul class="childer">
                    <li v-for="(item, index) in itemspro">
                      <a
                          href=""
                          @click="itemOnClickpro(index)"
                          :class="{ active: index+6 === cIndex }"
                      >{{ item.text }}</a
                      >
                    </li>
                  </ul>
                </li>
              </div>
              <span class="hoverLine"></span>
            </ul>
          </div>
          <div v-if="this.$store.getters.userInfo.usertype==='3'" class="user-menu-l">
            <!--侧边列表-->
            <ul class="um-list">
              <li class="list">
                <h2 class="primary active">
                  <i class="layui-icon layui-icon-username"></i><span>个人中心</span>
                </h2>
                <!--                </ul>-->
                <ul class="childer">
                  <li v-for="(item, index) in items2">
                    <a
                        href=""
                        @click="itemOnClick2(index)"
                        :class="{ active: index === cIndex }"
                    >{{ item.text }}</a
                    >
                  </li>
                </ul>
              </li>
              <li class="list">
                <h2 class="primary">
                  <i class="layui-icon layui-icon-set"></i><span>个人设置</span>

                </h2>
                <ul class="childer">
                  <li v-for="(item, index) in items3">
                    <a
                        href=""
                        @click="itemOnClick3(index)"
                        :class="{ active: index+1 === cIndex }"
                    >{{ item.text }}</a
                    >
                  </li>
                </ul>
              </li>
              <span class="hoverLine"></span>
            </ul>
          </div>
          <!--个人中心左侧边栏 结束-->
        </div>
        <!--        <div class="usercenter-content usercenter-cont">-->
        <!--内容部分-->
        <router-view></router-view>
        <!--        </div>-->
        <!--内容部分 结束-->
      </div>
    </div>
</template>

<script>
import axios from "axios";

export default {
  name: "personal",
  data(){
    return{
      items: [
        { route: "/personal/MainPage", text:"我的主页" },
        { route: "/personal/MyOrder", text:"我的订单" },
        // { route: "/personal/MyNeed", text:"我的需求" },
        // { route: "/personal/MyPackage", text:"我的翻译包" },
        { route: "/personal/MyCoupon", text:"我的优惠券" },
        { route: "/personal/SubAccount", text:"子账户管理" },
      ],
      items1:[
        {route:"/personal/Data",text:"个人资料"},
        {route:"/personal/Security",text:"账号安全"},
      ],
      itemspro:[
        {route:"/personal/ProxyCustomerList",text:"代理客户列表"},
        {route:"/personal/ProxyBuyRecords",text:"客户充值记录"},
        {route:"/personal/ProxyExtraRecords",text:"佣金提取记录"},
      ],
      items2: [
        { route: "/personal/MainPage", text:"我的主页" },
        // { route: "/personal/MyOrder", text:"我的订单" },
        // { route: "/personal/MyCoupon", text:"我的优惠券" },
        // { route: "/personal/SubAccount", text:"子账户管理" },
      ],
      items3:[
        {route:"/personal/Data",text:"个人资料"},
        {route:"/personal/Security",text:"账号安全"},
      ],
      route:['/personal/MainPage','/personal/MyOrder','/personal/MyCoupon','/personal/SubAccount','/personal/Data','/personal/Security'],
      routepro:['/personal/MainPage','/personal/MyOrder','/personal/MyCoupon','/personal/SubAccount','/personal/Data','/personal/Security','/personal/ProxyCustomerList','/personal/ProxyBuyRecords','/personal/ProxyExtraRecords',],
      route1:['/personal/MainPage','/personal/Data','/personal/Security'],
      route2:['/personal/Security/Main','/personal/Security/ChangePassword','/personal/Security/ChangeEmail'],
      cIndex: 0,
    }
  },
  methods:{
    itemOnClick(index) {
      event.preventDefault(); //阻止默认事件

      // 路由跳转
      // if (this.$route.path !== this.items[index].route) {
        this.$router.push(this.items[index].route);
        this.cIndex = index;
      // }
    },
    itemOnClick1(index) {
      event.preventDefault(); //阻止默认事件

      // 路由跳转
      // if (this.$route.path !== this.items1[index].route) {
        this.$router.push(this.items1[index].route);
        this.cIndex = index+4;
      // }
    },
    itemOnClickpro(index){
      event.preventDefault(); //阻止默认事件
      // 路由跳转
      // if (this.$route.path !== this.items1[index].route) {
      this.$router.push(this.itemspro[index].route);
      this.cIndex = index+6;
      // }
    },
    itemOnClick2(index) {
      event.preventDefault(); //阻止默认事件

      // 路由跳转
      // if (this.$route.path !== this.items[index].route) {
        this.$router.push(this.items2[index].route);
        this.cIndex = index;
      // }
    },
    itemOnClick3(index) {
      event.preventDefault(); //阻止默认事件

      // 路由跳转
      // if (this.$route.path !== this.items1[index].route) {
        this.$router.push(this.items3[index].route);
        this.cIndex = index+1;
      // }
    },
  },
  created() {
    // console.log(this.items.indexOf(this.$route.path))
    if(this.$store.getters.userInfo.usertype!=='3'&&this.route.indexOf(this.$route.path)>=0){
          this.cIndex=this.route.indexOf(this.$route.path);
    }
    if(this.$store.getters.userInfo.isagent==='Y'&&this.routepro.indexOf(this.$route.path)>=0){
          this.cIndex=this.routepro.indexOf(this.$route.path);
    }
    if(this.$store.getters.userInfo.usertype==='3'&&this.route1.indexOf(this.$route.path)>=0){
          this.cIndex=this.route1.indexOf(this.$route.path);
    }
    if(this.$store.getters.userInfo.usertype!=='3'&&this.route2.indexOf(this.$route.path)>=0){
      this.cIndex=5;
    }
    if(this.$store.getters.userInfo.usertype==='3'&&this.route2.indexOf(this.$route.path)>=0){
      this.cIndex=2;
    }

    axios.get("/api/customer/customerMng/queryCustomer?id="+this.$store.getters.userInfo.id).then(res=>{
      if(res.data.success){
        this.$store.commit('handleUserInfo',res.data.result);
      }
    })
    if(this.$store.getters.userInfo.usertype!=='3'&&(this.$store.getters.userInfo.invitationcode===null||this.$store.getters.userInfo.invitationcode==="")){
      axios.get("/api/customer/customerMng/InvitationCode?id="+this.$store.getters.userInfo.id).then(res=>{
        let userInfo=this.$store.getters.userInfo;
        userInfo.invitationcode=res.data.result.invitationcode;
        userInfo.invitationlink=res.data.result.invitationlink;
      })
    }
  }
}
</script>

<style scoped>

.usercenter-cont {
  display: block;
  float: left;
  width: 905px;
  margin-left: 15px;
  margin-bottom: 40px;
}
.wd-content {
  /*margin-top: 15px;*/
  display: block;
}
.layui-container {
  min-width: 1170px !important;
  margin-bottom: 30px;
}
ul.um-list {
  margin-top: 20px;
  padding-bottom: 30px;
  padding-left: 60px;
}
ul.um-list .list {
  position: relative;
  width: 100%;
  text-align: left;
  line-height: 36px;
}
ul.um-list .list h2.primary {
  font-size: 0;
  margin: 8px 0;
}
ul.um-list .list i.layui-icon {
  margin-right: 4px;
}
ul.um-list .list span {
  width: 80px;
  color: #484848;
  font-size: 16px;
  line-height: 14px;
  text-align: left;
}
ul.um-list .list span {
  display: inline-block;
  vertical-align: middle;
}
ul.um-list .childer {
  text-align: left;
  display: block;
  padding-left: 20px;
}
ul.um-list .list a.active, ul.um-list .list a:hover {
  color: #1aba79;
}
ul.um-list .childer a {
  color: #999;
  font-size: 14px;
  line-height: 14px;
  position: relative;
}
.user-left {
  display: block;
  float: left;
  width: 215px;
  height: 600px;
  background: #fff;
  border-radius: 4px;
  margin-bottom: 30px;
  min-height: 600px;
}


</style>
