<template>
  <div class="page3">
    <ul class="faqList">
      <el-collapse class="list" v-model="activeName" accordion>
        <el-collapse-item title="1、CC翻译平台翻译实力如何？" name="1">
          <div class="info">
            <span style="font-family:&quot;font-size:14px;">CC翻译平台与全世界最优秀的翻译引擎合作，致力于做到精准、实时翻译。</span></div>
        </el-collapse-item>
        <el-collapse-item title="2、如何查看我的套餐和字符余量" name="2">
          <div class="info">
            <span style="font-family:&quot;font-size:14px;">登录官网，进入<a href="/personal" style="white-space: normal;">【个人中心】</a>即可查看当前使用套餐和字符余量。</span><br />
           </div>
        </el-collapse-item>
        <el-collapse-item title="3、如何开通子账户？" name="3">
          <div class="info">
            <span style="font-family:&quot;font-size:14px;">1、如果您不是高级账户，请先登录官网，请点击导航栏的<a href="/members" style="white-space: normal;">【会员专享】</a>购买套餐，升级成为高级账户。</span><br>
            <span style="font-family:&quot;font-size:14px;">2、如果您已经成为企业账户，请先登录官网，进入<a href="/personal" style="white-space: normal;">【个人中心】</a>，选择右侧边栏的<a href="/personal/SubAccount" style="white-space: normal;">【子账户管理】</a>进行子账户的添加与删除操作。</span></div>
        </el-collapse-item>
        <el-collapse-item title="4、如何查看优惠券过期时间？" name="4">
          <div class="info">
            <p><span style="font-family: 微软雅黑, &quot;Microsoft YaHei&quot;; font-size: 14px;">请登录官网，进入<a href="/personal" style="white-space: normal;">【个人中心】</a>，选择右侧边栏的<a href="/personal/MyCoupon" style="white-space: normal;">【我的优惠券】</a>即可查看所有优惠券信息。</span></p></div>
        </el-collapse-item>
        <el-collapse-item title="5、如何修改个人信息？" name="5">
          <div class="info">
            <p><span style="font-family:&quot;font-size:14px;"></span><span style="font-family: &quot;font-size:14px;&quot;;">请登录官网，进入<a href="/personal" style="white-space: normal;">【个人中心】</a>，选择右侧边栏的<a href="/personal/Data" style="white-space: normal;">【我的资料】</a>可以查看并修改个人信息及更改绑定的邮箱号。</span></p></div>
        </el-collapse-item>
        <el-collapse-item title="6、如何修改密码？" name="6">
          <div class="info">
            <p><span style="font-family:&quot;font-size:14px;"></span><span style="font-family: &quot;font-size:14px;&quot;;">请登录官网，进入<a href="/personal" style="white-space: normal;">【个人中心】</a>，选择右侧边栏的<a href="/personal/Security" style="white-space: normal;">【账号安全】</a>点击修改密码，根据指引完成操作即可修改密码。</span></p></div>
        </el-collapse-item>
        <el-collapse-item title="7、CC翻译支持小语种的翻译么？" name="7">
          <div class="info">
            <span style="font-family: &quot;Microsoft YaHei&quot;; font-size: 14px;" >CC翻译提供30多语种翻译服务，包括西班牙语、土耳其语、阿拉伯语、泰语、波兰语、丹麦语等多个小语种在线翻译服务。</span><br />
            <span style="font-family: &quot;Microsoft YaHei&quot;; font-size: 14px;" >同时我们也在调动资源团队开发更多语种服务，满足更多用户需求。如您有其他疑问可以直接联系在线客服咨询。</span><br />									</div>
        </el-collapse-item>
<!--        <el-collapse-item title="7、如何联系语翼翻译平台？" name="7">-->
<!--          <div class="info">-->
<!--            <span style="font-family:  &quot;Microsoft YaHei&quot;; font-size: 14px;" microsoft="" >语翼提供多种沟通方式以满足您多样化的需求：</span><br />-->
<!--            <span style="font-family: &quot;Microsoft YaHei&quot;; font-size: 14px; " microsoft="" >A.</span><span> </span><span style="font-family: &quot;Microsoft YaHei&quot;; font-size: 14px; " microsoft="">联系在线客服：访问平台官网woordee.com，点击右侧浮窗“在线客服”.</span><br />-->
<!--            <span style="font-family: &quot;Microsoft YaHei&quot;; font-size: 14px; " microsoft="" >B.</span><span> </span><span style="font-family: &quot;Microsoft YaHei&quot;; font-size: 14px; " microsoft="" >拨打400电话：400-0506-071</span><br />-->
<!--            <span style="font-family: &quot;Microsoft YaHei&quot;; font-size: 14px; " microsoft="" >C.</span><span> </span><span style="font-family: &quot;Microsoft YaHei&quot;; font-size: 14px; " microsoft="" >关注语翼微信公众号yuyi-woordee并绑定个人账号: </span><br />-->
<!--            <span style="font-family: &quot;Microsoft YaHei&quot;; font-size: 14px; " microsoft="" >可联系在线客服，查看订单状态，领取礼包等。</span><br />-->
<!--            <span style="font-family: &quot;Microsoft YaHei&quot;; font-size: 14px;" microsoft="" >D.</span><span> </span><span style="font-family: &quot;Microsoft YaHei&quot;; font-size: 14px; " microsoft="" >QQ在线沟通：添加语翼官方QQ 790326788进行在线沟通。</span><br />-->
<!--            <span style="font-family: &quot;Microsoft YaHei&quot;; font-size: 14px; " microsoft="">E.</span><span> </span><span style="font-family: &quot;Microsoft YaHei&quot;; font-size: 14px; " microsoft="" >加入用户体验QQ群：496723303（群名：语翼-Woordee用户交流）</span><br />-->
<!--            <span style="font-family: &quot;Microsoft YaHei&quot;; font-size: 14px; " microsoft="">F.</span><span> </span><span style="font-family: &quot;Microsoft YaHei&quot;; font-size: 14px; " microsoft="" >E-mail沟通：客户建议与反馈邮箱 cservice.com@woordee.com</span>									</div>-->
<!--        </el-collapse-item>-->
      </el-collapse>
    </ul>
  </div>
</template>

<script>
export default {
  name: "page3",
  data() {
    return {
      activeName: '0'
    };
  }
}
</script>

<style scoped>
.info {
  background: #f2f2f2;
  padding: 15px 20px;
  border-radius: 10px;
}
</style>
