<template>
  <div class="section">
    <h2 class="fd-titel">找回密码</h2>
    <div class="wd-center fdpwd-box">
      <el-form :model="ruleForm" status-icon :rules="rules" ref="ruleForm" label-width="100px" class="demo-ruleForm">
        <el-form-item prop="email" label="邮箱">
          <el-input v-model="ruleForm.email" @blur="handleEmailCheck" :oninput="this.ruleForm.email = this.ruleForm.email.replace(/\s+/g,'')">
          </el-input>
          <span class="tishi">{{ this.emailInfo }}</span>
        </el-form-item>
        <el-form-item prop="email" label="验证码">
          <el-input v-model="ruleForm.captcha"  placeholder="请输入验证码" :oninput="ruleForm.captcha = ruleForm.captcha.replace(/\s+/g,'')" @blur="handleCaptchaCheck"><el-button style="padding-right:10px" slot="suffix" type="text" v-show="Verification" @click="send">获取验证码</el-button>
            <el-button style="padding-right:10px" slot="suffix" type="text" v-show="!Verification" @click="again"><span>{{this.timer}}</span>秒后获取</el-button></el-input>
          <span class="tishi">{{ this.captchaInfo}}</span>
        </el-form-item>
        <el-form-item label="新密码" prop="pass">
          <el-input type="password" v-model="ruleForm.pass" :oninput="ruleForm.pass = ruleForm.pass.replace(/\s+/g,'')" auto-complete="new-password" autocomplete="off"></el-input>
        </el-form-item>
        <el-form-item label="确认新密码" prop="checkPass">
          <el-input type="password" v-model="ruleForm.checkPass" :oninput="ruleForm.checkPass = ruleForm.checkPass.replace(/\s+/g,'')" auto-complete="new-password" autocomplete="off"></el-input>
        </el-form-item>
      </el-form>
      <button class="layui-btn wd-center btn-login editPwd_1_login" type="button" :disabled="flag" @click="changePass">确认修改</button>
    </div>
  </div>

</template>

<script>
import axios from "axios";

export default {
  name: "Lost",
  data() {
    var validatePass = (rule, value, callback) => {
      if (value === '') {
        callback(new Error('请输入密码'));
      } else {
        if (this.ruleForm.checkPass !== '') {
          this.$refs.ruleForm.validateField('checkPass');
        }
        callback();
      }
    };
    var validatePass2 = (rule, value, callback) => {
      if (value === '') {
        callback(new Error('请再次输入密码'));
      } else if (value !== this.ruleForm.pass) {
        callback(new Error('两次输入密码不一致!'));
      } else {
        callback();
      }
    };
    return {
      flag:false,//防止重复提交，暂时禁用按钮标志
      Verification:true,    //通过v-show控制显示获取还是倒计时
      timer:60,      //定义初始时间为60s
      captchaInfo:'',
      emailInfo:'',
      ruleForm: {
        pass: '',
        checkPass: '',
        email:'',
        captcha:'',
      },
      rules: {
        pass: [
          { validator: validatePass, trigger: 'blur' }
        ],
        checkPass: [
          { validator: validatePass2, trigger: 'blur' }
        ],
      }
    };
  },
  methods:{
    handleEmailCheck() {
      var reg=/^([a-zA-Z]|[0-9])(\w|\-)+@[a-zA-Z0-9]+\.([a-zA-Z]{2,4})$/;
      if(!reg.test(this.ruleForm.email)){
        // callback(new Error("请输入正确的邮箱号"))
        this.emailInfo='请输入正确邮箱'
      }else{
        this.emailInfo=''
        var params = {
          email: this.ruleForm.email,
        };
        axios.post("/api/customer/chatCustomer/validateEmail",params).then(res=>{
          // console.log("1111")
          console.log(res.data.exist)
          if(res.data.result.exist!==1){
            this.emailInfo='该邮箱未注册';
          }else{
            this.emailInfo=''
          }
        })
      }
    },
    handleCaptchaCheck(){
      if(this.captcha===null||this.captcha===''){
        this.captchaInfo='请输入验证码'
      }else{
        this.captchaInfo='';
      }
    },
    again(){
      this.$message({
        message:this.timer+"后再获取",
        type:'warning'
      })
    },
    send(){
      this.handleEmailCheck();
      if(this.emailInfo!==''||this.rules.email===''){
          this.emailInfo='请输入正确的邮箱';
      }else {
        this.Verification = false;      //点击button改变v-show的状态
        let auth_timer = setInterval(() => {  //定时器设置每秒递减
          this.timer--;        //递减时间
          if (this.timer <= 0) {
            this.timer = 60
            this.Verification = true;    //60s时间结束还原v-show状态并清除定时器
            clearInterval(auth_timer)
          }
        }, 1000)
        var params = {
          email: this.ruleForm.email,
        };
        axios.post("/api/customer/sendCode/emailSms", params).then(res => {
          if (res.data.success) {
            this.$message({
              message: res.data.message,
              type: 'success'
            });
          } else {
            this.$message.error(res.data.message)
          }
        })
      }
    },
    changePass(){
      if(this.ruleForm.captcha===''){
        this.$message({
          message:'请输入验证码',
          type: 'warning'
        });
      }else if(this.ruleForm.pass===''){
        this.$message({
          message:'请输入密码',
          type: 'warning'
        });
      }else if(this.ruleForm.checkPass===''){
        this.$message({
          message:'请再次输入密码',
          type: 'warning'
        });
      }else if(this.ruleForm.pass!==this.ruleForm.checkPass) {
        this.$message({
          message: '两次密码不一致',
          type: 'warning'
        });
      }else{
        this.flag=true;
        axios.post("/api/customer/customerMng/changePassword",this.ruleForm).then(res=>{
          if(res.data.success) {
            this.$message({
              message: res.data.message,
              type: 'success'
            });
            setTimeout(() => {
              this.$router.push({path: '/signIn'})
            }, 2000)
          }else{
            this.$message.error(res.data.message)
          }
        }).finally(() => {
          setTimeout(() => {
            this.flag = false;
          }, 2000)
        });
      }
    },
  },
}
</script>

<style scoped>
.section {
  width: 370px;
  position: absolute;
  height: 340px;
  top: 30%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.btn-login{
  display: block;
  width: 300px;
  margin-left: 100px;
  height: 40px;
  margin-top: 20px;
  background: #1aba79;
  border-radius: 4px;
  color: #fff;
  line-height: 24px;
}
.tishi{
  color: #F83333;
  font-size: 1px;
}
.section h2 {
  margin-left:200px;
  margin-bottom: 30px;
  font-size: 22px;
  color: #222;
  line-height: 24px;
}
</style>
