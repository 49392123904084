<template>
<div class="home">
  <div class="newwoordee">
    <div class="content">
      <i class="rotop"></i>
      <i class="roleft"></i>
      <i class="rorigjt"></i>
      <i class="romini"></i>
      <i class="rojt"></i>
      <i class="tree"></i>
      <i class="img-new"></i>
      <p class="p1">CC翻译全新产品上线</p>
      <p class="p2">新用户赠送30000字符<br>
        一次购买10组以上<span style="color: #FF5722;font-weight: bolder;">9折</span>优惠<br>
        一次购买50组以上<span style="color: #fa0202;font-weight: bolder;">8折</span>优惠</p>

      <a href="/create" target="_blank" class="link"><i class="ipre"></i><span>立即领取</span></a>
    </div>
  </div>
  <div class="art-list-box box-cross content" id="index_rec_siteTr" style="min-height: 453px"> <p class="title"><i class="iconfont icon-crossBorderExperts"></i><span>CC翻译下载中心</span></p>
    <div class="list-ul">
      <div class="list-li" onclick="window.open('https://wwd.lanzoul.com/b03vauj9i','_blank')">
        <div class="box-top">
          <img src="../../../public/static/common/img/i_08_windows_1.png" alt="" class="avatar">
          <div class="top-right">
            <p class="tr">
              <span class="trname">Windows</span></p>
            <p class="order-num">下载次数：<span>100000+</span></p>
            <p class="languar">
              <span>CC翻译桌面版-Windows-64位下载</span>
            </p>
          </div>
        </div>
        <div class="artbox art-1 server-list-box">
          <p class="art-title">服务支持</p>
          <ul class="list-ul">
            <li>※ windows 7</li>
            <li>※ windows 10</li>
            <li>※ windows 11</li>
          </ul>
        </div> <div class="artbox art-2 des-box">
        <p class="art-title">欢迎使用</p>
        <p class="des">
          WhatsApp,Telegram,Line,Google Voice,Facebook,Mssenger,Instagram,Twitter,LinkedIn,Zalo,Tinder,Titok,
          Discord,Skypi,bip,VK Messenger
        </p>
        <a class="know-more-link">立即下载</a> </div> </div>
      <div class="list-li" onclick="window.open('https://wwd.lanzoul.com/b03vauj9i','_blank')">
        <div class="box-top">
          <img src="../../../public/static/common/img/i_08_mac_1.png" alt="" class="avatar">
          <div class="top-right">
            <p class="tr">
              <span class="trname">Mac</span>  </p>
            <p class="order-num">下载次数：<span>10000+</span></p>
            <p class="languar"><span>CC翻译桌面版-Mac安装包下载</span></p></div>
        </div>
        <div class="artbox art-1 server-list-box"><p class="art-title">服务支持</p>
          <ul class="list-ul">
            <li>macOS 12.0 “Monterey”</li>
            <li>macOS 11.0 "Big Sur"</li>
            <li>macOS 10.15 "Catalina"</li>
            <li>Mac OS X 10.6 "Snow Leopard"</li>
            <li>Mac OS X 10.5 "Leopard"</li>
            <li>Mac OS X 10.4 "Tiger"</li>
          </ul>
        </div>
        <div class="artbox art-2 des-box"><p class="art-title">欢迎使用</p>
          <p class="des">
            WhatsApp,Telegram,Line,Google Voice,Facebook,Mssenger,Instagram,Twitter,LinkedIn,Zalo,Tinder,Titok,
            Discord,Skypi,bip,VK Messenger
          </p>
          <a class="know-more-link">立即下载</a>
        </div>
  </div>
  </div>
  </div>
  <div class="evaluate-box">
    <div class="content">
      <p class="title"><i class="iconfont icon-goodEvaluation"></i><span>口碑评价</span></p>
<!--      <div class="pages-link-box">-->
<!--        <a href="javascripts:;" class="btn-prev evaluatePrev"><i class="iconfont icon-leftArrow"></i></a>-->
<!--        <a href="javascripts:;" class="btn-next evaluateNext"><i class="iconfont icon-rightArrow1"></i></a>-->
<!--      </div>-->
      <div class="swiperContents">
        <div class="swiper-containers swiper-container-horizontal">
          <div class="swiper-wrapper" id="commentBox" style="transform: translate3d(-1546.67px, 0px, 0px); transition-duration: 0ms;">
            <div class="swiper-slide swiper-slide-duplicate" data-swiper-slide-index="7" style="width: 366.667px; margin-right: 20px;">
              <div class="main">
                <div class="left">
                  <div class="huiyuan">
                    <span style="background: url(https://filemanager.woordee.com/woordee_avatar_001.jpg?x-oss-process=image/resize,m_lfit,h_40,w_40) no-repeat;background-size: cover;"></span> </div>
                  <div class="text"> <p class="text-top">
                    <span class="nickname">用户51124833</span>
                    <span class="pltime">2022-07-05</span> </p>
                    <p class="pldes">  服务内容：英语-&gt;简体中文 </p> </div> </div>
                <p class="info" id="infos"> 态度好，译文比较专业。 </p>
<!--                <ul class="evaluate">-->
<!--                  <li>交稿速度快</li>-->
<!--                  <li>回复很及时</li>-->
<!--                  <li>内容精美</li>-->
<!--                  <li>下次再合作</li>-->
<!--                </ul>-->
              </div>
            </div>
            <div class="swiper-slide swiper-slide-duplicate" data-swiper-slide-index="8" style="width: 366.667px; margin-right: 20px;">
              <div class="main">
                <div class="left">
                <div class="huiyuan">
                <span style="background: url(https://filemanager.woordee.com/woordee_avatar_001.jpg?x-oss-process=image/resize,m_lfit,h_40,w_40) no-repeat;background-size: cover;"></span> </div>
                  <div class="text">
                    <p class="text-top">
                      <span class="nickname">用户13876872</span>
                      <span class="pltime">2022-07-04</span> </p>
                    <p class="pldes">  服务内容：简体中文-&gt;英语 </p> </div> </div>
                <p class="info" id="infos"> 表达润色很完美，期待下次合作 </p>
                <ul class="evaluate">
                  <li>交稿速度快</li>
                  <li>内容精美</li>
                  <li>下次再合作</li>
                  <li>服务态度好</li>   </ul> </div>
            </div>
            <div class="swiper-slide swiper-slide-duplicate" data-swiper-slide-index="9" style="width: 366.667px; margin-right: 20px;">
              <div class="main">
                <div class="left">
                <div class="huiyuan">
                <span style="background: url(https://filemanager.woordee.com/woordee_avatar_001.jpg?x-oss-process=image/resize,m_lfit,h_40,w_40) no-repeat;background-size: cover;"></span> </div>
                  <div class="text">
                    <p class="text-top">
                    <span class="nickname">用户81343435</span>
                      <span class="pltime">2022-07-01</span> </p>
                    <p class="pldes">  服务内容：Listing撰写-&gt;Amazon德国站 </p> </div> </div>
                <p class="info" id="infos"> 服务态度很好，很细心标注问题 </p>
<!--                <ul class="evaluate">-->
<!--                  <li>回复很及时</li>-->
<!--                  <li>下次再合作</li>-->
<!--                  <li>服务态度好</li>-->
<!--                  <li>交稿速度快</li>   </ul> -->
              </div>
              </div>
            <div class="swiper-slide swiper-slide-prev" data-swiper-slide-index="0" style="width: 366.667px; margin-right: 20px;">
              <div class="main">
                <div class="left">
                  <div class="huiyuan">
                    <span style="background: url(https://filemanager.woordee.com/woordee_avatar_001.jpg?x-oss-process=image/resize,m_lfit,h_40,w_40) no-repeat;background-size: cover;"></span> </div>
                <div class="text">
                  <p class="text-top">
                    <span class="nickname">用户00032134</span>
                  <span class="pltime">2022-07-19</span> </p>
                  <p class="pldes">  服务内容：简体中文-&gt;英语 </p> </div> </div>
                <p class="info" id="infos"> 内容翻译相对比较准确，简洁不累赘 </p>
<!--                <ul class="evaluate">-->
<!--                  <li>服务态度好</li>-->
<!--                  <li>回复很及时</li>-->
<!--                  <li>内容精美</li>-->
<!--                  <li>下次再合作</li>   </ul> -->
              </div>
              <div class="footer">
              <span class="ortrs">接单专家</span>
                <div class="trer-info">
                  <a href="/translator/WE16106821TR" target="_blank">
              <img src="https://filemanager.woordee.com/1510726922312.png?x-oss-process=image/resize,m_lfit,h_26,w_26" alt="">
              <span class="name">毛伟英语</span>
                    <span class="tag-note">资深译员</span> </a> </div> </div> </div>
            <div class="swiper-slide swiper-slide-active" data-swiper-slide-index="1" style="width: 366.667px; margin-right: 20px;">
              <div class="main">
                <div class="left">
                  <div class="huiyuan">
                    <span style="background: url(https://filemanager.woordee.com/b27ad467ab4573523c6d9f2ecf6d8e99bbb4b857.jpg?x-oss-process=image/resize,m_lfit,h_40,w_40) no-repeat;background-size: cover;"></span> </div>
                <div class="text"> <p class="text-top">
                  <span class="nickname">背番号8</span>
                  <span class="pltime">2022-10-02</span> </p>
                  <p class="pldes">  服务内容：其它服务 </p> </div> </div>
                <p class="info" id="infos"> ღ( ´･ᴗ･` )比心 </p>
<!--                <ul class="evaluate">  </ul> -->
              </div>
              </div>
            <div class="swiper-slide swiper-slide-next" data-swiper-slide-index="2" style="width: 366.667px; margin-right: 20px;">
              <div class="main">
              <div class="left">
                <div class="huiyuan">
                <span style="background: url(https://filemanager.woordee.com/woordee_avatar_001.jpg?x-oss-process=image/resize,m_lfit,h_40,w_40) no-repeat;background-size: cover;"></span> </div>
                <div class="text">
                <p class="text-top">
                  <span class="nickname">用户45638845</span>
                  <span class="pltime">2022-10-01</span> </p>
                  <p class="pldes">  服务内容：简体中文-&gt;英语 </p> </div> </div>
                <p class="info" id="infos"> 回复及时，效率高，认真仔细，原文的错别字都能修改 </p>
<!--                <ul class="evaluate">-->
<!--                  <li>内容精美</li>-->
<!--                  <li>下次再合作</li>-->
<!--                  <li>交稿速度快</li>-->
<!--                  <li>回复很及时</li>   </ul>-->
              </div>
            </div>
            <div class="swiper-slide" data-swiper-slide-index="3" style="width: 366.667px; margin-right: 20px;">
              <div class="main">
              <div class="left">
              <div class="huiyuan">
                <span style="background: url(https://filemanager.woordee.com/woordee_avatar_001.jpg?x-oss-process=image/resize,m_lfit,h_40,w_40) no-repeat;background-size: cover;"></span> </div>
                <div class="text">
                  <p class="text-top">
              <span class="nickname">用户54303218</span>
                    <span class="pltime">2022-09-26</span> </p>
                <p class="pldes">  服务内容：简体中文-&gt;日语 </p> </div> </div>
                <p class="info" id="infos"> 很不错，下次可以再合作 </p>
<!--                <ul class="evaluate">-->
<!--                  <li>服务态度好</li>-->
<!--                  <li>交稿速度快</li>-->
<!--                  <li>回复很及时</li>-->
<!--                  <li>内容精美</li>   </ul>-->
              </div>
              </div>
            <div class="swiper-slide" data-swiper-slide-index="4" style="width: 366.667px; margin-right: 20px;">
              <div class="main"> <div class="left"> <div class="huiyuan">
                <span style="background: url(https://filemanager.woordee.com/woordee_avatar_001.jpg?x-oss-process=image/resize,m_lfit,h_40,w_40) no-repeat;background-size: cover;"></span> </div>
                <div class="text">
                <p class="text-top">
                  <span class="nickname">海口市外事办公室</span>
                  <span class="pltime">2022-07-14</span> </p> <p class="pldes">  服务内容：英语-&gt;简体中文 </p> </div> </div>
                <p class="info" id="infos"> 老师太给力了，感谢关照 </p>
                <ul class="evaluate">  </ul> </div> <div class="footer">
              <span class="ortrs">接单专家</span> <div class="trer-info">
              <a href="/translator/WE1169817736613593088TR" target="_blank">
                <img src="http://filemanager.woordee.com/FgZV-Gd_i9Ri6QDeZNpM73H6qn0B?x-oss-process=image/resize,m_lfit,h_26,w_26" alt="">
                <span class="name">人生之翻篇</span> <span class="tag-note">资深译员</span> </a> </div> </div> </div>
            <div class="swiper-slide" data-swiper-slide-index="5" style="width: 366.667px; margin-right: 20px;">
              <div class="main">
                <div class="left">
                <div class="huiyuan">
                  <span style="background: url(https://filemanager.woordee.com/woordee_avatar_001.jpg?x-oss-process=image/resize,m_lfit,h_40,w_40) no-repeat;background-size: cover;"></span> </div>
                  <div class="text"> <p class="text-top">
                    <span class="nickname">用户35148979</span>
                    <span class="pltime">2022-07-13</span> </p>
                    <p class="pldes">  服务内容：简体中文-&gt;英语 </p> </div> </div>
                <p class="info" id="infos"> 语翼网中英翻译最棒的小姐姐 没有之一 </p>
<!--                <ul class="evaluate">-->
<!--                  <li>服务态度好</li>-->
<!--                  <li>回复很及时</li>-->
<!--                  <li>内容精美</li>  <li>下次再合作</li>   </ul>-->
              </div>
              </div>
            <div class="swiper-slide" data-swiper-slide-index="6" style="width: 366.667px; margin-right: 20px;">
              <div class="main">
                <div class="left">
                  <div class="huiyuan">
                    <span style="background: url(https://filemanager.woordee.com/woordee_avatar_001.jpg?x-oss-process=image/resize,m_lfit,h_40,w_40) no-repeat;background-size: cover;"></span> </div>
                  <div class="text"> <p class="text-top">
                    <span class="nickname">用户00001297</span>
                    <span class="pltime">2022-07-12</span> </p>
                    <p class="pldes">  服务内容：简体中文-&gt;日语 </p> </div> </div>
                <p class="info" id="infos"> 挺好的 帮我把同义词近义词也写出来了 </p>
                <ul class="evaluate">
                  <li>服务态度好</li>
                  <li>下次再合作</li>   </ul> </div>
              </div>
            <div class="swiper-slide" data-swiper-slide-index="7" style="width: 366.667px; margin-right: 20px;">
              <div class="main">
                <div class="left">
                  <div class="huiyuan">
                    <span style="background: url(https://filemanager.woordee.com/woordee_avatar_001.jpg?x-oss-process=image/resize,m_lfit,h_40,w_40) no-repeat;background-size: cover;"></span> </div>
                  <div class="text">
                    <p class="text-top">
                      <span class="nickname">用户51124833</span>
                      <span class="pltime">2022-07-05</span> </p>
                    <p class="pldes">  服务内容：英语-&gt;简体中文 </p> </div> </div>
                <p class="info" id="infos"> 态度好，译文比较专业。 </p>
<!--                <ul class="evaluate">-->
<!--                <li>交稿速度快</li>-->
<!--                  <li>回复很及时</li>-->
<!--                  <li>内容精美</li>-->
<!--                  <li>下次再合作</li>   </ul> -->
              </div>
             </div>
            <div class="swiper-slide" data-swiper-slide-index="8" style="width: 366.667px; margin-right: 20px;">
              <div class="main">
                <div class="left">
                <div class="huiyuan">
                <span style="background: url(https://filemanager.woordee.com/woordee_avatar_001.jpg?x-oss-process=image/resize,m_lfit,h_40,w_40) no-repeat;background-size: cover;"></span> </div>
                  <div class="text">
                    <p class="text-top">
                      <span class="nickname">用户13876872</span>
                      <span class="pltime">2022-07-04</span> </p>
                    <p class="pldes">  服务内容：简体中文-&gt;英语 </p> </div> </div>
                <p class="info" id="infos"> 表达润色很完美，期待下次合作 </p>
<!--                <ul class="evaluate">-->
<!--                  <li>交稿速度快</li>-->
<!--                  <li>内容精美</li>-->
<!--                  <li>下次再合作</li>-->
<!--                  <li>服务态度好</li>   </ul> -->
              </div>
              </div>
            <div class="swiper-slide" data-swiper-slide-index="9" style="width: 366.667px; margin-right: 20px;">
              <div class="main">
                <div class="left">
                <div class="huiyuan">
                  <span style="background: url(https://filemanager.woordee.com/woordee_avatar_001.jpg?x-oss-process=image/resize,m_lfit,h_40,w_40) no-repeat;background-size: cover;"></span> </div>
                  <div class="text"> <p class="text-top">
                    <span class="nickname">用户81343435</span>
                    <span class="pltime">2022-07-01</span> </p>
                    <p class="pldes">  服务内容：Listing撰写-&gt;Amazon德国站 </p> </div> </div>
                <p class="info" id="infos"> 服务态度很好，很细心标注问题 </p>
                <ul class="evaluate">
                  <li>回复很及时</li>
                  <li>下次再合作</li>
                  <li>服务态度好</li>
                  <li>交稿速度快</li>   </ul> </div>
              </div>
            <div class="swiper-slide swiper-slide-duplicate swiper-slide-duplicate-prev" data-swiper-slide-index="0" style="width: 366.667px; margin-right: 20px;">
              <div class="main">
                <div class="left">
                  <div class="huiyuan">
                    <span style="background: url(https://filemanager.woordee.com/woordee_avatar_001.jpg?x-oss-process=image/resize,m_lfit,h_40,w_40) no-repeat;background-size: cover;"></span> </div>
                  <div class="text">
                    <p class="text-top">
                    <span class="nickname">用户00032134</span>
                      <span class="pltime">2022-07-19</span> </p>
                    <p class="pldes">  服务内容：简体中文-&gt;英语 </p> </div> </div>
                <p class="info" id="infos"> 内容翻译相对比较准确，简洁不累赘 </p>
<!--                <ul class="evaluate">-->
<!--                  <li>服务态度好</li>-->
<!--                  <li>回复很及时</li>-->
<!--                  <li>内容精美</li>-->
<!--                  <li>下次再合作</li>   </ul> -->
              </div>
              </div>
            <div class="swiper-slide swiper-slide-duplicate swiper-slide-duplicate-active" data-swiper-slide-index="1" style="width: 366.667px; margin-right: 20px;">
              <div class="main">
                <div class="left">
                  <div class="huiyuan">
                    <span style="background: url(https://filemanager.woordee.com/b27ad467ab4573523c6d9f2ecf6d8e99bbb4b857.jpg?x-oss-process=image/resize,m_lfit,h_40,w_40) no-repeat;background-size: cover;"></span> </div>
                  <div class="text">
                    <p class="text-top">
                    <span class="nickname">背番号8</span>
                      <span class="pltime">2022-07-18</span> </p>
                    <p class="pldes">  服务内容：其它服务 </p> </div> </div>
                <p class="info" id="infos"> ღ( ´･ᴗ･` )比心 </p>
                <ul class="evaluate">  </ul> </div>
              </div>
            <div class="swiper-slide swiper-slide-duplicate swiper-slide-duplicate-next" data-swiper-slide-index="2" style="width: 366.667px; margin-right: 20px;">
              <div class="main">
                <div class="left">
                  <div class="huiyuan">
                    <span style="background: url(https://filemanager.woordee.com/woordee_avatar_001.jpg?x-oss-process=image/resize,m_lfit,h_40,w_40) no-repeat;background-size: cover;"></span> </div>
                  <div class="text">
                    <p class="text-top">
                      <span class="nickname">用户45638845</span>
                      <span class="pltime">2022-07-15</span> </p>
                    <p class="pldes">  服务内容：简体中文-&gt;英语 </p> </div> </div>
                <p class="info" id="infos"> 回复及时，效率高，认真仔细，原文的错别字都能修改 </p>
<!--                <ul class="evaluate">-->
<!--                  <li>内容精美</li>-->
<!--                  <li>下次再合作</li>-->
<!--                  <li>交稿速度快</li>-->
<!--                  <li>回复很及时</li>   </ul>-->
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="company content">
    <p class="title"><i class="iconfont icon-goodCustomer"></i><span>合作企业</span></p>
    <!--企业认可-->
    <div class="cooper_Wrapper content" data-id="1" style="display: block">
      <div class="cooperativePartner">
        <ul class="container">
          <li class="translater_tool">
            <img src="../../../public/static/common/img/i_12_google.png" style="padding: 40px;">
<!--            <i class="haijie_hkj"></i>-->
          </li>
          <li class="translater_tool">
            <img src="../../../public/static/common/img/i_12_huawei.png" style="padding: 40px;">
          </li>
          <li class="translater_tool">
            <img src="../../../public/static/common/img/i_12_youdao.png" style="padding: 40px;">
          </li>
          <li class="translater_tool">
            <img src="../../../public/static/common/img/i_12_microsoft.png" style="padding: 40px;">
          </li>
        </ul>
        <ul class="container">
          <li class="translater_tool">
            <img src="../../../public/static/common/img/i_12_tencent.png" style="padding: 30px;">
          </li>
          <li class="translater_tool">
            <img src="../../../public/static/common/img/i_12_baidu.png" style="padding: 30px;">
          </li>
          <li class="translater_tool">
            <img src="../../../public/static/common/img/i_12_huoshan.png" style="padding: 30px;">
          </li>
          <li class="translater_tool">
            <img src="../../../public/static/common/img/i_12_amazon1.png" style="padding: 30px;">
          </li>
        </ul>

      </div>
    </div>
  </div>
</div>
</template>

<script>
export default {
  name: "home"
}
</script>

<style scoped>
/*@import "../../../public/static/web/css/index_v3234d5f.css?v=3.3.49";*/
/*@import "../../../public/static/common/css/swiper.min4d5f.css?v=3.3.49";*/
/*@import "../../../public/static/web/css/index_v3234d5f.css?v=3.3.49";*/
@import "../../../public/static/common/css/swiper.min4d5f.css?v=3.3.49";

.translater_tool{
  margin: 20px;
}

</style>
