<template>
  <div class="m_content wd-shadow userComon">
    <!-- <p class="cont-title">
        安全设置
    </p> -->
   <router-view></router-view>
  </div>
</template>

<script>
export default {
  name: "Security",
}
</script>

<style scoped>
/*.m_content .settingList li .email img {*/
/*  width: 427px;*/
/*  margin: -165px 0 0 -283px;*/
/*}*/
.m_content {
  display: block;
  float: left;
  width: 905px;
  margin-left: 15px;
  margin-bottom: 40px;
  background: #fff;
  border-radius: 4px;
  min-height: 600px;
}
/*.userComon {
  min-height: 350px !important;
}*/
.m_content .settingList {
  background: #fff;
}
.m_content .settingList li {
  overflow: hidden;
  margin-left: 30px;
  padding: 30px 0;
  border-bottom: 1px solid #eaeaea;
}
.m_content .settingList li .icon {
  float: left;
  width: 46px;
  height: 46px;
  border-radius: 46px;
  overflow: hidden;
}
img {
  display: inline-block;
  border: none;
  vertical-align: middle;
}
.m_content .settingList li .icon img {
  width: 427px;
  /*margin: -4px 0 0 -283px;*/
}
.m_content .settingList li .info {
  float: left;
  padding-left: 21px;
}
.m_content .settingList li .info p {
  color: #999;
  font-size: 14px;
  padding-top: 4px;
}
.m_content .settingList li .edit_icon img {
  width: 427px;
  margin: -56px 0 0 -337px;
}
.m_content .settingList li .submit {
  float: right;
  padding-right: 26px;
  margin-top: 10px;
}
.m_content .settingList li .submit button, .m_content .settingList li .submit a {
  border: 1px solid #1aba79;
  background: #fff;
  font-size: 12px;
  color: #1aba79;
  cursor: pointer;
  border-radius: 4px;
  padding: 4px 13px;
}

</style>
