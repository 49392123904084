import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    rowData : [],
    form: {//网络获取的数据
      devName: [],
      proType: [],
      firVer: [],
    },
    showName:''|| localStorage.getItem('showName'),
    userInfo: undefined||  JSON.parse(localStorage.getItem('userInfo')),
    subInfo:[],
    token:''||localStorage.getItem('token'),
  },
  mutations : {

    handleUserInfo: (state, userInfo) => {
      state.userInfo= userInfo
      // 把登录的用户信息保存到localStorage中，防止页面刷新，导致vuex重新启动，用户名就成为初始值（初始值为空）的情况
      // console.log(userInfo)
      // console.log(JSON.stringify(userInfo))
      localStorage.setItem('userInfo', JSON.stringify(userInfo))
    },
  handleShowName: (state, showName) => {
    state.showName= showName
    // 把登录的用户信息保存到localStorage中，防止页面刷新，导致vuex重新启动，用户名就成为初始值（初始值为空）的情况
    localStorage.setItem('showName', showName)
  },
    handleToken: (state, token) => {
      state.token= token
      // 把登录的用户信息保存到localStorage中，防止页面刷新，导致vuex重新启动，用户名就成为初始值（初始值为空）的情况
      localStorage.setItem('token', token)
    },

  remove:(state) => {
    localStorage.clear('showName')
    localStorage.clear('userInfo')
    localStorage.clear('token')
  },
    /**
     * @description 推送最新查询数据至Vuex
     * @param {*} state
     * @param {*} data
     */
    pushIpswlist(state,data){
      state.rowData = data;
    },

    /**
     * @description 推送最新请求数据至Vuex
     * @param {*} state
     * @param {Array} devName 设备名称 「允许为null」
     * @param {Array} proType 产品类型 「允许为null」
     * @param {Array} firVer  固件版本 「允许为null」
     */
    pushData(state,[devName,proType,firVer]){
      if(devName != null){
        state.form.devName = devName;
      }
      if(proType != null){
        state.form.proType = proType;
      }
      if(firVer != null){
        state.form.firVer = firVer;
      }
    }
  },
  getters:{
    showName: (state) => state.showName,
    userInfo: (state) =>state.userInfo,
    token: (state)=>state.token,
  }
})
