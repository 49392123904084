<template xmlns="http://www.w3.org/1999/html">
  <div style="margin-top: 60px ;">
    <!-- 主体内容 start -->
    <section class=" memberPrivileges">
      <div class="banner-top hidden">
        <div class="banner-img wd-center">
          <p class="banner-text1">看的见的价格，感受到的实惠</p>
          <p class="banner-text2">去中间化，让客户直接与译员对接</p>
        </div>
      </div>
      <div v-loading="loading" class="memberPrivilegesSection">
        <div class="mbp-before-box">
          <ul class="memberTitleBefore">
            <!--1-->
            <li class="tab-li li-1">
              <div class="title">
                <span class="note">{{ this.package[0].name}}</span>
                <span class="note2">适用于普通用户</span>
                <span class="hot">惠享</span>
              </div>
              <div class="center-box">
                <ul class="detail">
                  <li><i class="iconfont icon-qiepian"></i><p><span style="font-weight: bolder;color: #7356DD;">160万</span>字符</p></li>
                  <!-- <li><i class="iconfont icon-qiepian"></i><p>神经网络翻译引擎</p></li>
                  <li><i class="iconfont icon-qiepian"></i><p>支持超多语种相互翻译</p></li> -->
                  <li><i class="iconfont icon-qiepian"></i><p>套餐无时间限制，客服随时支持</p></li> <!--<span class="color-bule">购买即送会员卡</span>-->
                  <li><i class="iconfont icon-qiepian"></i><p>自动升级高级账户，支持子账户管理</p></li>
                  <li><i class="iconfont icon-qiepian"></i><p style="font-weight: bolder;color: slategray;">支持有道、百度、小牛、火山等翻译工具</p></li>
                </ul>
                <p class="h3">特惠价<span class="price">¥{{ this.package[0].currentprice}}</span><span class="color-orange">元</span></p>
                <p class="p2">原价¥{{ this.package[0].money}}</p>
                <a class="view-buy buyPackBtn" @click="goBuy(0)">
                  <span>立即购买</span>
                  <i class="sheng">省</i>
                </a>
              </div>
            </li>
            <!--2-->
            <li class="tab-li li-2">
              <div class="title">
                <span class="note">{{ this.package[1].name}}</span>
                <span class="note2">适用于高级用户</span>
                <span class="hot">惠享</span>
              </div>
              <div class="center-box">
                <ul class="detail">
                  <li><i class="iconfont icon-qiepian"></i><p><span style="font-weight: bolder;color: #7356DD;">160万</span>字符</p></li>
                  <!-- <li><i class="iconfont icon-qiepian"></i><p>人工智能赋能智能翻译</p></li> -->
                  <li><i class="iconfont icon-qiepian"></i><p>无时间限制，客服随时支持，支持子账户</p></li>
                  <li><i class="iconfont icon-qiepian"></i><p>支持chatGPT智能回复</p></li>
                  <li><i class="iconfont icon-qiepian"></i><p style="font-weight: bolder;color: slategray;">支持谷歌、腾讯、chatGPT、有道等翻译工具</p></li>
                </ul>
                <p class="h3">特惠价<span class="price">¥{{ this.package[1].currentprice}}</span><span class="color-orange">元</span></p>
                <p class="p2">原价¥{{ this.package[1].money}}</p>
                <a class="view-buy buyPackBtn" @click="goBuy(1)">
                  <span>立即购买</span>
                  <i class="sheng">省</i>
                </a>
              </div>
            </li>
            <!--3-->
            <li class="tab-li li-3">
              <div class="title">
                <span class="note">{{ this.package[2].name}}</span>
                <span class="note2">适用于资深用户</span>
                <span class="hot">超级惠享</span>
              </div>
              <div class="center-box">
                <ul class="detail">
                  <li><i class="iconfont icon-qiepian"></i><p><span style="font-weight: bolder;color: #7356DD;">160万</span>字符</p></li>
                  <!-- <li><i class="iconfont icon-qiepian"></i><p>最高的数据安全性</p></li> -->
                  <!-- <li><i class="iconfont icon-qiepian"></i><p>快速、准确、安全的翻译</p></li> -->
                  <li><i class="iconfont icon-qiepian"></i><p>无时间限制，客服随时支持，支持子账户</p></li>
                  <li><i class="iconfont icon-qiepian"></i><p>支持chatGPT智能回复</p></li>
                  <li><i class="iconfont icon-qiepian"></i><p style="font-weight: bolder;color: slategray;">Deepl、谷歌、chatGPT、有道等翻译工具</p></li>
                </ul>
                <p class="h3">特惠价<span class="price">¥{{ this.package[2].currentprice}}</span><span class="color-orange">元</span></p>
                <p class="p2">原价¥{{ this.package[2].money}}</p>
                <a class="view-buy buyPackBtn" @click="goBuy(2)">
                  <span>立即购买 </span>
                  <i class="sheng">惠</i>
                </a>
              </div>
            </li>
            <br></br>
            <li class="tab-li li-1">
              <div class="title">
                <span class="note">{{ this.package[3].name}}</span>
                <span class="note2">适用于专职用户</span>
                <span class="hot">惠享</span>
              </div>
              <div class="center-box">
                <ul class="detail">
                  <li><i class="iconfont icon-qiepian"></i><p><span style="font-weight: bolder; color: rgb(115, 86, 221);">30天</span>内字符无限畅用</p></li>
                  <li><i class="iconfont icon-qiepian"></i><p>支持多种语种精准翻译</p></li>
                  <li><i class="iconfont icon-qiepian"></i><p>不支持子账户管理</p></li>
                  <li><i class="iconfont icon-qiepian"></i><p style="font-weight: bolder;color: slategray;">支持有道、百度、火山、小牛翻译通道</p></li>
                </ul>
                <p class="h3">特惠价<span class="price">¥{{ this.package[3].currentprice}}</span><span class="color-orange">元</span></p>
                <p class="p2">原价¥{{ this.package[3].money}}</p>
                <a class="view-buy buyPackBtn" @click="goBuy(3)">
                  <span>立即购买 </span>
                  <i class="sheng">省</i>
                </a>
              </div>
            </li>
            <li class="tab-li li-2">
              <div class="title">
                <span class="note">{{ this.package[4].name}}</span>
                <span class="note2">适用于专职用户</span>
                <span class="hot">惠享</span>
              </div>
              <div class="center-box">
                <ul class="detail">
                  <li><i class="iconfont icon-qiepian"></i><p><span style="font-weight: bolder; color: rgb(115, 86, 221);">30天</span>内字符无限畅用</p></li>
                  <li><i class="iconfont icon-qiepian"></i><p>支持多种语种精准翻译</p></li>
                  <li><i class="iconfont icon-qiepian"></i><p>不支持子账户管理</p></li>
                  <li><i class="iconfont icon-qiepian"></i><p style="font-weight: bolder;color: slategray;">支持谷歌、腾讯、chatGPT、有道等翻译工具</p></li>
                </ul>
                <p class="h3">特惠价<span class="price">¥{{ this.package[4].currentprice}}</span><span class="color-orange">元</span></p>
                <p class="p2">原价¥{{ this.package[4].money}}</p>
                <a class="view-buy buyPackBtn" @click="goBuy(4)">
                  <span>立即购买 </span>
                  <i class="sheng">省</i>
                </a>
              </div>
            </li>
            <li class="tab-li li-3">
              <div class="title">
                <span class="note">{{ this.package[5].name}}</span>
                <span class="note2">适用于专职用户</span>
                <span class="hot">超级惠享</span>
              </div>
              <div class="center-box">
                <ul class="detail">
                  <li><i class="iconfont icon-qiepian"></i><p><span style="font-weight: bolder; color: rgb(115, 86, 221);">30天</span>内字符无限畅用</p></li>
                  <li><i class="iconfont icon-qiepian"></i><p>支持多种语种精准翻译</p></li>
                  <li><i class="iconfont icon-qiepian"></i><p>不支持子账户管理</p></li>
                  <li><i class="iconfont icon-qiepian"></i><p style="font-weight: bolder;color: slategray;">Deepl、谷歌、chatGPT、有道等翻译工具</p></li>
                </ul>
                <p class="h3">特惠价<span class="price">¥{{ this.package[5].currentprice}}</span><span class="color-orange">元</span></p>
                <p class="p2">原价¥{{ this.package[5].money}}</p>
                <a class="view-buy buyPackBtn" @click="goBuy(5)">
                  <span>立即购买 </span>
                  <i class="sheng">惠</i>
                </a>
              </div>
            </li>
          </ul>
        </div>
      </div>
    </section>
    <!-- 主体内容 end -->
    <div v-loading="loadingPay" class="payMethod" id="pay1" v-if="this.$store.getters.showName !==null&&this.$store.getters.userInfo !==null">
      <h3><i class="iconfont icon-danjuzhifu"></i>请选择购买数量：</h3>
      <div class="payWhat">
        <span class="title">购买内容：</span>
        <span v-if="this.select===''" class="empty emptypack" style="color: #FFBB22">请先选择您要购买的套餐</span>
        <div v-if="this.select!==''" class="paycard paycard-2">
          <p class="name">{{this.select.name}}</p>
<!--          <p class="explain">{{ this.select.remarks }}</p>-->
        </div>
      </div>
      <div class="payWhat">
        <span class="title">购买数量：</span>
        <span v-if="this.select===''" class="count">--</span>
        <div v-if="this.select!==''" class="paycard">
          <el-input-number  v-model="count" style="text-align: center;display:block;" controls-position="right" @input.native="number_change($event)" @change="handleChange" :min="1" :max="200" :precision="0"
                            :formatter="limitNumber" :parser="limitNumber"></el-input-number>
        </div>
      </div>
      <div class="payWhat">
        <span class="title">选择优惠券：</span>
        <span v-if="this.coupons.length===0" id="amountPay">暂无可使用优惠券</span>
        <el-link type="primary" @click="userCoupon"  v-if="this.coupons.length!==0">{{ this.selectCoupon===''?'点击使用优惠券':'已减免'+this.selectCoupon.deductionamount+'元'}}</el-link>
        <el-link class="no_use" type="info" @click="noUse"  v-if="this.selectCoupon!==''">不使用优惠券</el-link>
        <el-drawer
            title="优惠券"
            :visible.sync="couponFlag"
            direction="rtl">
          <div class="coupon-content coupon-cont">
            <div class="layui-tab layui-tab-brief">
              <div class="layui-tab-content">
                <div>
                  <!--优惠券列表-->
                  <ul v-if="coupons.length!==0" class="voucher-list" >
                    <li v-for="(item, index) in coupons" class="voucher-box mzq unuse-box" @click="couponSelect(index)">
                      <div class="img-voucher">
                        <div class="border-l">
                        </div>
                        <p><o>¥</o>
                          <span>{{ item.deductionamount }}</span></p>
                      </div>
                      <div class="voucher-cont">
                        <div class="cont-top">
                          <span class="icon">满减券</span>
                          <span class="title" title="满500元减100元">满{{ item.paylowerlimit }}元减{{ item.deductionamount }}元</span> </div>
                        <span class="packageType">适用套餐：{{ item.chatPackageNames }}</span>
                        <div class="cont-center">
                          <span class="time">{{ item.endtime }} 失效</span>
                          <!--                          <a href="/members" class="layui-btn to-use">立即使用</a>-->
                        </div>
                        <div class="cont-bottom">
                          <!--&lt;!&ndash;                  <span class="text">使用规则</span>&ndash;&gt;-->
                          <!--                  <o class="icon-zhu"></o>-->
                          <div class="wd-shadow-around notes-text">
                            <div class="triangle_border_up">
                              <span></span> </div>
                            <!--                    <div class="text-content"> 适用于翻译与listing所有订单类型，订单金额满500元立减100元。 </div>-->
                          </div>
                        </div>
                      </div>
                    </li>
                  </ul>
                  <!--空态-->
                  <div v-if="coupons.length===0" id="no-message" class="no-message">
                    <i class="wd-center"></i>
                    <p>您暂时还没有优惠券</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </el-drawer>


      </div>
      <p class="payMoney">应付金额：
        <span v-if="this.total===''" id="amountPay">--</span>
        <span v-if="this.select!==''" id="amountPay">￥{{ this.total }}</span>
        <span v-if="this.discount<1&&this.discount>0" style="color: #F83333;font-size: 20px;padding-left: 50px" >平台已为您打{{ this.discount*10 }}折</span>
      </p>
<!--      <img src="@/assets/img/i_52.png" style="width: 25%;clear: both;display: block;margin-left: 0px">-->
      <ul class="type">
        <li v-for="(item, index) in items" :class="{ alipay:index===1,wechat_navtive:index===0, active:index===paymethod}" @click="itemOnClick(index)">
      </li>
      </ul>
        <div class="b">
          <button class="layui-btn payBtn" lay-submit="" lay-filter="formPay" :disabled="flag" @click="goCheck">确认支付</button>
          <span style="margin-left: 30px;color: rgb(115, 86, 221);">(支付有任何疑问请联系客服)</span>
        </div>
    </div>
    <el-dialog
        title="微信扫码"
        :visible.sync="dialogVisible"
        width="13%"
        :before-close="handleClose"
        center>
<!--      <iframe height=200 width=220 :src="imgUrl" frameborder=0 allowfullscreen></iframe>-->
      <img :src="imgUrl" style=""/>
      <span slot="footer" class="dialog-footer">
<!--    <el-button @click="dialogVisible = false">取 消</el-button>-->
    <el-button type="primary" @click="handleClose">我已完成支付
    </el-button>
      </span>
    </el-dialog>
    <el-dialog
        title="支付宝扫码"
        :visible.sync="dialogVisible1"
        width="13%"
        :before-close="handleClose"
        center>
<!--      <iframe height=200 width=220 :src="imgUrl" frameborder=0 allowfullscreen></iframe>-->
      <img :src="imgUrl" style=""/>
      <span slot="footer" class="dialog-footer">
<!--    <el-button @click="dialogVisible = false">取 消</el-button>-->
    <el-button type="primary" @click="handleClose">我已完成支付</el-button>
  </span>
    </el-dialog>
<!--    <el-dialog-->
<!--      title="是否完成支付"-->
<!--      :visible.sync="dialogVisible1"-->
<!--      width="13%"-->
<!--      :before-close="handleClose"-->
<!--      center>-->
<!--    <span slot="footer" class="dialog-footer">-->
<!--&lt;!&ndash;    <el-button @click="dialogVisible = false">取 消</el-button>&ndash;&gt;-->
<!--    <el-button type="primary" @click="handleClose">我已完成支付</el-button>-->
<!--  </span>-->
<!--  </el-dialog>-->


  </div>
</template>

<script>
import vuex from "@/store";
import axios from "axios";

export default {
  name: "members",
  data(){
    return{
      loading:true,
      loadingPay:false,
      //订单id
      orderId:'',
      alipay:'alipay',
      paymethod:0,//默认微信支付
      imgUrl:'',
      dialogVisible:false,
      dialogVisible1:false,
      // dialogVisible2:false,
      //选中的套餐
      select:'',
      count:1,//购买数量
      total:0,//总价
      amountofrelief:0,//减免金额
      //折扣
      discountFlag:false,
      discount:1,
      flag:true,
      //优惠券
      coupons:[],
      //选中的优惠券
      selectCoupon:'',
      //优惠券弹框标志
      couponFlag:false,
      //折扣
      discounts:[],
      //套餐
      package:[{
        name:'',
        money:'',
        currentprice:'',
        remarks:'',
      }
      ,{
          name:'',
          money:'',
          currentprice:'',
          remarks:'',
      }
      ,{
          name:'',
          money:'',
          currentprice:'',
          remarks:'',
      },{
          name:'',
          money:'',
          currentprice:'',
          remarks:'',
        },{
          name:'',
          money:'',
          currentprice:'',
          remarks:'',
        }
      ],
      items:[0,1],
      cIndex:-1,
    }
  },
  methods:{
    number_change(e) {
      //在输入的时候就要判断只能输入正整数
      e.target.value = e.target.value.replace(/[^0-9]/g, '')
      //   然后在每次keyup时把值赋给v-model
      // console.log(e.target.value)
      // if (!e.target.value) {
      //   // 这里给undefined是因为不给值就会默认变为1，不会为空，导致用户输入不了别的数值
      //   this.count=undefined;
      // }else{
      //   this.count = e.target.value
      // }
    },
    //取消使用优惠券
    noUse(){
      this.selectCoupon='';
      this.amountofrelief=0;
      this.handleChange();
    },
    //选择优惠券
    couponSelect(index){
      this.selectCoupon=this.coupons[index];
      this.amountofrelief=this.selectCoupon.deductionamount;
      this.handleChange();
      //关闭弹窗
      this.couponFlag=false;
    },
    //打开优惠券弹框
    userCoupon(){
      this.couponFlag=true;
      this.handleChange();
    },
    handleClose(done) {
      this.$confirm('是否已经完成支付？')
          .then(_ => {
            this.dialogVisible=false;
            this.dialogVisible1=false;
            // clearInterval(timer)
            this.$router.push({path:'/personal/MyOrder'})
            done();
          })
          .catch(_ => {});
    },
    itemOnClick(index){
      this.paymethod=index;
    },
    //购买数量改变触发
    handleChange(){
      if(this.count>200){
        this.count=200;
      }
      // console.log(this.count)
      if(this.count>=10&&this.count<50){
        this.discount=this.discounts[0].value;
      }else if(this.count>=50){
        this.discount=this.discounts[1].value;
      }else{
        this.discount=1;
      }
      this.total=this.select.currentprice*this.count*this.discount-this.amountofrelief;
      let params={
        username:this.$store.getters.userInfo.username,
        total:this.total,
        packageId:this.select.id,
      }
      axios.post("/api/customer/couponMng/queryCoupon",params).then(res=>{
        if(res.data.success){
          // console.log(res.data.result)
          this.coupons=res.data.result;
        }
      })
      // console.log("jjj"+this.num)
      if(this.count<=0||this.total<=0||this.count===undefined||this.total===undefined){
        this.flag=true;
      }else{
        this.flag=false;
      }
    },
    goBuy(item) {
      if(vuex.getters.showName ===null|| vuex.getters.userInfo ===null){
        this.$message('请先登录再购买');
        this.$router.push({path:"/signIn"})
      }else{
        if(vuex.getters.userInfo.usertype==='3'){
          this.$message({
            showClose:true,
            message:'请联系企业主账户购买！'
          });
          this.$notify.info({
            title: '提示',
            message: '请联系企业主账户购买！'
          });
        }else if(vuex.getters.userInfo.usertype==='2'&&this.package[item].charcount<=0){
          this.$message({
            showClose:true,
            message:'高级账户不能购买此套餐！'
          });
          this.$notify.info({
            title: '提示',
            message: '高级账户不能购买此套餐！'
          });
        }else {
          if (vuex.getters.showName === null || vuex.getters.userInfo === null) {
            this.$message('请先登录再购买');
            this.$router.push({path: "/signIn"})
          } else {
            this.noUse();
            this.select = this.package[item];
            this.total = this.select.currentprice * this.count - this.amountofrelief;
            this.flag = false;
            // let toElement = document.getElementById('pay1')
            // toElement.scrollIntoView
            // window.scrollTo(0, window.screen.height-400);
            window.scrollTo(0, document.documentElement.scrollHeight - document.documentElement.clientHeight - 250)
            let params = {
              username: this.$store.getters.userInfo.username,
              total: this.total,
              packageId: this.select.id,
            }
            axios.post("/api/customer/couponMng/queryCoupon", params).then(res => {
              if (res.data.success) {
                // console.log(res.data.result)
                this.coupons = res.data.result;
              }
            })
          }
        }
      }
    },
    goCheck(){
      // this.cIndex=-1;
      // this.cIndex = this.package.findIndex(
      //     (item) => item.id === this.$store.getters.userInfo.packageid
      // );
      console.info(this.$store.getters.userInfo);
      console.info(this.package);
      if(this.$store.getters.userInfo.packageid!==this.package[4].id&&this.select.id!==this.$store.getters.userInfo.packageid){
        this.$confirm('您购买的套餐与当前已有套餐不一致，购买后原套餐字符量将会清零，是否继续？', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(() => {
          this.goPay();
        }).catch(() => {
          this.$message({
            type: 'info',
            message: '已取消购买'
          });
        });
      }else{
        this.goPay();
      }
    },
    goPay() {
      this.flag = true;
      if (this.count <= 0 || this.total <= 0 || this.count === undefined || this.total === undefined) {
        this.$message.error("购买数量不能小于一")
      } else {
        var params = {
          createBy: this.$store.getters.userInfo.id,
          // realpay: this.select.money * this.count - this.amountofrelief,   //实付金额
          // amountofrelief: this.amountofrelief,    //减免金额
          // originalamount: this.select.money * this.count,  //原始金额
          count: this.count,//购买数量
          // numberofchars: this.select.nums * this.count,//本次充值字符量
          packageId: this.select.id,//套餐id
          couponId:this.selectCoupon.id,//使用的优惠券id
          paymethod: this.paymethod,//付款方式（支付宝/微信)
          title: this.select.name,//套餐名称
          body: this.select.name,//套餐描述
        }
        // console.log(this.select)
        this.loadingPay=true;
        axios.post("/api/customer/buyServiceMng/applyPay",params).then(res=>{
          if(res.data.success){
            if(this.paymethod===0){
              this.imgUrl=res.data.result.payData;
              this.dialogVisible=true;
              // console.log(res.data.result);
            }else{
              this.dialogVisible1=true;
              this.imgUrl=res.data.result.payData;
              this.orderId=res.data.result.mchOrderNo;
              // console.log(res.data.result);
              // window.open(res.data.result.payData);
              this.lunxun();
            }
          }else{
            this.$message.error(res.data.message)
          }
          //按钮禁止两秒，防止重复下单
          this.loadingPay=false;
        }).finally(() => {
          setTimeout(() => {
            this.flag = false;
          }, 2000)
        });
      }
    },
    // methods 正则替换小数点
    limitNumber(value) {
      if (typeof value === 'string') {
        return !isNaN(Number(value)) ? value.replace(/\./g, '') : 1
      } else if (typeof value === 'number') {
        return !isNaN(value) ? String(value).replace(/\./g, '') : 1
      } else {
        return 0
      }
    },
    getPayState () {
      axios.get("api/customer/chatOrders/chatOrders/queryByOrderId",{params:{orderId:this.orderId}}).then(res=>{
        if(res.data.result.paystatus==='1'){
          this.dialogVisible=false;
          this.dialogVisible1=false;
          // clearInterval(timer);
          this.$router.push({path:'/personal/MyOrder'});
        }
      })
    },
    lunxun(){
      window.timer=setInterval(() => {
        setTimeout(this.getPayState(), 0);
      }, 5000);
    },
  },
  created() {
    this.loading=true;
    axios.get("/api/customer/packageMng/queryPackage").then(res=>{
      // console.log(res.data.result);
      if(res.data.success){
        this.package=res.data.result.packages;
        this.discounts=res.data.result.discounts;
      }
      this.loading=false;
    })
  },
  destroyed() {
    clearInterval(timer);
  },
  beforeRouteLeave(to,from,next){
    next();
    clearInterval(timer);
  }
}
</script>

<style scoped>
@import '../../../public/static/user/css/member/memberPrivileges-3134d5f.css?v=3.3.49';
.tishi{
  color: #F83333;
  font-size: 20px;
}
.payMethod .type li.active {
  position: relative;
  border-color: #00be73;
}
.packageType {
  display: inline-block;
  vertical-align: sub;
  font-size: 12px;
  font-family: ArialMT;
  color: #ccc;
}
.payMethod .type li.active:after {
  content: '';
  width: 0;
  height: 0;
  display: inline-block;
  position: absolute;
  right: 0;
  bottom: 0;
  border-right: 24px solid #00be73;
  border-top: 24px solid transparent;
  border-left: 24px solid transparent;
}
.payMethod .type li.alipay {
  background: url(../../../public/static/user/img/member/icon-alipay.png) no-repeat center;
}
.payMethod .type li:not(:last-of-type) {
  margin-right: 20px;
}
.payMethod .type li {
  width: 192px;
  height: 65px;
  border: 1px solid #e0e0e0;
  border-radius: 4px;
  float: left;
  cursor: pointer;
  box-sizing: border-box;
}
.b{
  margin-top: 80px;
  /*margin-right: 10px;*/
}
.payMethod .type li.wechat_navtive {
  background: url(../../../public/static/user/img/member/icon-wechat.png) no-repeat center;
}
.m
.g{
  display: flex;
}
p.p2 {
  font-size: 16px;
  font-family: MicrosoftYaHei,"\5FAE\8F6F\96C5\9ED1";
  text-decoration: line-through;
  color: #bfbfbf;
  line-height: 24px;
}
.payMethod {
  width: 1146px;
  height: 520px;
  box-sizing: border-box;
  display: block;
  padding: 32px 0 40px 48px;
  margin: 0 auto;
  margin-top: 54px;
  text-align: left;
  border: 1px solid #eaeaea;
  border-radius: 4px;
  box-shadow: 0 0 8px 4px rgba(0,0,1,0.02);
}
.payMethod h3 {
  font-family: MicrosoftYaHei,"\5FAE\8F6F\96C5\9ED1";
  font-size: 24px;
  color: #222;
  margin-bottom: 36px;
}
.payMethod h3 i {
  color: #c2c2c2;
  font-size: 24px;
  margin-right: 5px;
}
.payMethod .payWhat {
  font-family: MicrosoftYaHei,"\5FAE\8F6F\96C5\9ED1";
  font-size: 16px;
  color: #666;
  margin-bottom: 36px;
}
.payMethod .payMoney {
  font-family: MicrosoftYaHei,"\5FAE\8F6F\96C5\9ED1";
  font-size: 16px;
  color: #666;
  margin-bottom: 12px;
}
.payMethod .payMoney span {
  font-size: 30px;
  color: #fb2;
}
.count {
  font-size: 30px;
  color: #fb2;
}
.payMethod .payBtn {
  width: 125px;
  height: 40px;
  background: #00be73;
  border-radius: 4px;
  margin-top: 10px;
}
.layui-btn {
  display: inline-block;
  height: 38px;
  line-height: 38px;
  padding: 0 18px;
  background-color: #009688;
  color: #fff;
  white-space: nowrap;
  text-align: center;
  font-size: 14px;
  border: none;
  border-radius: 2px;
  cursor: pointer;
}
.payMethod .payWhat .paycard.paycard-2 {
  background: linear-gradient(-40deg, rgba(213,193,154,0.98) 0, #e7d6b5 100%);
  vertical-align: text-top;
  width: 188px;
}
.payMethod .payWhat .paycard {
  width: auto;
  height: 56px;
  border-radius: 4px;
  display: inline-block;
  margin-top: -18px;
  padding: 10px;
  box-sizing: border-box;
}
.payMethod .payWhat .paycard .name {
  display: table-cell;
  font-size: 30px;
  font-weight: bold;
  color: #424247;
  /*line-height: 2;*/
  padding-left: 30px;
}
.payMethod .payWhat .paycard .explain {
  font-size: 12px;
  color: #5e5e66;
  line-height: 1;
  margin-top: 5px;
}

.coupon-cont {
  display: block;
  float: left;
  width: 616px;
  margin-left: 15px;
  margin-bottom: 40px;
  background: #fff;
  border-radius: 4px;
}
.layui-tab {
  margin: 10px 0;
  text-align: left!important;
}
.coupon-cont .layui-tab-title {
  padding-left: 20px;
}
.coupon-cont .layui-tab-title .layui-this {
  color: #1aba79 !important;
}
.coupon-cont .layui-tab-title li {
  color: #484848;
  padding: 0 10px;
}
.coupon-cont .layui-tab-content {
  padding: 20px;
}
.coupon-cont .layui-tab-content .search-box input {
  width: 235px;
  height: 32px;
  vertical-align: middle;
  display: inline-block;
  border-radius: 4px;
}
.layui-input, .layui-textarea {
  display: block;
  width: 100%;
  padding-left: 10px;
}
.layui-input, .layui-select, .layui-textarea {
  height: 38px;
  line-height: 1.3;
  line-height: 38px\9;
  border-width: 1px;
  border-style: solid;
  background-color: #fff;
  border-radius: 2px;
}
.coupon-cont .layui-tab-content .search-box .btn-search {
  width: 90px;
  height: 32px;
  margin-left: 10px;
  vertical-align: middle;
  display: inline-block;
  background: #1aba79;
  border-radius: 4px;
  color: #fff;
  line-height: 32px;
}
.layui-show {
  display: block!important;
}
.coupon-cont .layui-tab-content ul.voucher-list {
  display: inline-block;
  margin-top: 30px;
}
.coupon-cont .layui-tab-content ul.voucher-list li.voucher-box {
  cursor: pointer;
  width: 276px;
  height: 104px;
  box-sizing: border-box;
  display: block;
  float: left;
  background: #fff;
  border: 1px solid rgba(0,0,0,0.02);
  box-shadow: 0 1px 4px rgba(0,0,0,0.01), 0 4px 8px rgba(0,0,0,0.02);
  border-radius: 4px;
  margin-right: 12px;
  margin-bottom: 16px;
}
.coupon-cont .layui-tab-content ul.voucher-list li.mzq .img-voucher {
  background: #58cd91;
}
.coupon-cont .layui-tab-content ul.voucher-list li.voucher-box .img-voucher {
  position: relative;
  width: 80px;
  height: 100%;
  display: block;
  float: left;
  vertical-align: top;
}
.coupon-cont .layui-tab-content ul.voucher-list li.mzq .img-voucher .border-l {
  background: url(../../../public/static/common/img/img.png) no-repeat -3px 0;
  background-color: white;
  /*background: white;*/
}
.coupon-cont .layui-tab-content ul.voucher-list li.voucher-box .img-voucher .border-l {
  display: block;
  width: 3px;
  height: 100%;
  position: absolute;
  left: -3px;
}
.coupon-cont .layui-tab-content ul.voucher-list li.voucher-box .img-voucher p o {
  font-size: 14px;
}
.coupon-cont .layui-tab-content ul.voucher-list li.voucher-box .img-voucher p {
  text-align: center;
  font-size: 22px;
  font-family: ArialMT;
  color: #fff;
  line-height: 102px;
}
.coupon-cont .layui-tab-content ul.voucher-list li.voucher-box .voucher-cont {
  position: relative;
  display: block;
  float: left;
  width: 192px;
  vertical-align: top;
  box-sizing: border-box;
  padding: 10px 10px 0 10px;
  height: 100%;
}
.coupon-cont .layui-tab-content ul.voucher-list li.voucher-box .voucher-cont .cont-top .icon {
  display: inline-block;
  vertical-align: middle;
  width: 43px;
  height: 16px;
  font-size: 12px;
  text-align: center;
  line-height: 16px;
  line-height: 15px\0;
  color: #fff;
  border-radius: 2px;
}
.coupon-cont .layui-tab-content ul.voucher-list li.mzq .cont-top .icon {
  background: #67bf95;
}
.coupon-cont .layui-tab-content ul.voucher-list li.voucher-box .voucher-cont .cont-top .title {
  display: inline-block;
  max-width: 124px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  vertical-align: middle;
  color: #666;
}
.coupon-cont .layui-tab-content ul.voucher-list li.voucher-box .voucher-cont .cont-center {
  margin-top: 10px;
  width: 100%;
  height: 26px;
}
.coupon-cont .layui-tab-content ul.voucher-list li.voucher-box .voucher-cont .cont-center .time {
  display: inline-block;
  vertical-align: sub;
  font-size: 12px;
  font-family: ArialMT;
  color: #ccc;
}
.coupon-cont .layui-tab-content ul.voucher-list li.voucher-box .voucher-cont .cont-center .to-use {
  display: inline-block;
  float: right;
  vertical-align: sub;
  text-align: center;
  font-size: 12px;
  line-height: 22px;
  background: #fff;
  padding: 0;
  width: 66px;
  height: 24px;
  border-radius: 13px;
}
.coupon-cont .layui-tab-content ul.voucher-list li.mzq .to-use {
  color: #67bf95;
  border: 1px solid #67bf95;
}
.to-use{
  margin-top: 13px;
}

.coupon-cont .layui-tab-content ul.voucher-list li.voucher-box .voucher-cont .cont-bottom {
  position: relative;
  width: 100%;
  margin-top: 5px;
  border-top: 1px dashed #eaeaea;
}
.coupon-cont .layui-tab-content ul.voucher-list li.voucher-box .voucher-cont .cont-bottom .text {
  font-size: 12px;
  color: #ccc;
  line-height: 26px;
  float: left;
  cursor: default;
}
.coupon-cont .layui-tab-content ul.voucher-list li.voucher-box .voucher-cont .cont-bottom .icon-zhu {
  position: relative;
  display: block;
  float: left;
  width: 12px;
  height: 12px;
  margin-top: 8px;
  margin-right: 2px;
  margin-left: 2px;
  background: url(../../../public/static/common/img/icon_spirit.png) -20px -210px;
  cursor: pointer;
}
.notes-text {
  display: none;
  width: 250px;
  height: auto;
  background: #fff;
  border: 1px solid #f5f5f5;
  position: absolute;
  z-index: 999;
  top: 30px;
  left: -68px;
  border-radius: 4px;
}
.notes-text .triangle_border_up {
  width: 0;
  height: 0;
  border-width: 0 10px 10px;
  border-style: solid;
  border-color: transparent transparent #f5f5f5;
  position: absolute;
  top: -10px;
  left: 114px;
}
.notes-text .text-content {
  box-sizing: border-box;
  width: 100%;
  height: 100%;
  padding: 20px;
  font-size: 12px;
  line-height: 20px;
  color: #484848;
  top: 0;
}
.icon-zhu:hover .notes-text{
  display: block;
  width: 250px;
  height: auto;
  background: #fff;
  border: 1px solid #f5f5f5;
  position: absolute;
  z-index: 999;
  top: 30px;
  left: -68px;
  border-radius: 4px;
}
.no-message {
  box-sizing: border-box;
  width: 500px;
  float: left;
  margin-bottom: 100px;
}
.no-message i {
  width: 100px;
  height: 100px;
  background: url(../../../public/static/common/img/common-icon.png) -419px -4px no-repeat;
  border-radius: 50%;
  display: block;
  margin-top: 100px;
}
.wd-center {
  margin-left: auto;
  margin-right: auto;
  display: block;
}
.no-message p {
  height: 16px;
  font-size: 16px;
  font-family: MicrosoftYaHei;
  font-weight: 400;
  color: #666;
  line-height: 24px;
  display: block;
  text-align: center;
  margin-top: 20px;
}
.no_use{
  margin-left: 15px;
}
</style>
