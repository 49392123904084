<template>
  <div class="headerFexd headerFexdV322 ">
    <div class="wd-opcity"></div>
    <div class="wd-header clear">
      <div class="headerContent">
        <div class="logo" data-im="0" data-sys="0">
          <a href="/home">
            <div style="width: 212px;">
              <div class="ico" style="float: left;"></div>

              <div  style="float: left;font-size: 20px;color: #725BE3; font-weight: bolder;padding-left: 10px;margin-top: 20px;">CC翻译</div>
            </div>
          </a>
        </div>

        <div class="navList">
          <ul class="nav-ul">
<!--            <li>
              <a href="javascript:void(null);" class="name">
                语翼专家
              </a>
              <div class="triangle_border_down">
              </div>
              <div class="childer">
                <ul>
                  <li>
                    <a href="translator46cc.html?stype=1">资深译员</a>
                  </li>
                  <li>
                    <a href="translator88bb.html?stype=2">跨境优化师</a>
                  </li>
                </ul>
              </div>
            </li>-->
<!--            <li>-->
<!--              <a href="/order">-->
<!--                <i class="iconfont icon-nav-fastOrder icon-qo"></i>-->
<!--                快速下单-->
<!--              </a>-->
<!--            </li>-->
            <li>
              <a :class="{ active: 0 === cIndex }" href="/home">首页</a>
            </li>
            <li>
              <a :class="{ active: 1 === cIndex }" href="/download">
                软件下载
              </a>
<!--              <div class="childer1 on-show" style="width: 85px">
                <a href="https://square.woordee.com/" target="_blank">跨境免费咨询</a>
              </div>-->
            </li>
<!--            <li>
              <a href="https://square.woordee.com/" target="_blank" class="name">
                语翼广场
              </a>
              <div class="childer1 on-show" style="width: 85px">
                <a href="https://square.woordee.com/" target="_blank">跨境免费咨询</a>
              </div>
            </li>
            -->
            <li>
              <a :class="{ active: 2 === cIndex }" href="/members">套餐购买</a>
              <div class="childer1 on-show">
                  <a href="/members">注册送字符</a>
              </div>
            </li>

            <li>
              <a :class="{ active: 3 === cIndex }" href="/pricing">平台价格</a>
            </li>
            <li>
              <a :class="{ active: 4 === cIndex }" href="/about">关于CC翻译</a>
            </li>
            <li>
              <a :class="{ active: 5 === cIndex }" href="/problem">使用说明</a>
            </li>
<!--            <li>
              <a href="##" class="name">
                关于语翼
              </a>
              <div class="triangle_border_down">
              </div>
              <div class="childer">
                <ul>
                  <li>
                    <a href="service.html">产品服务</a>
                  </li>
                  <li>
                    <a href="customer.html">客户心声</a>
                  </li>
                  <li>
                    <a href="faq.html">常见问题</a>
                  </li>
                  <li>
                    <a href="news.html">行业资讯</a>
                  </li>
                  <li>
                    <a href="https://point.woordee.com/">积分兑换</a>
                  </li>
                </ul>
              </div>
            </li>-->
          </ul>
        </div>
        <div class="loginInfo">
          <div class="login" v-if="!$store.getters.showName">
            <div class="login_register"><a href="/signIn" class="btn"
                                           onclick="woordeeGio({eventId:'regPageShow',eventLevelVariables:{triggerPage_var:'导航栏_登录','triggerItem_var':'登录'}},3,window.location.href)">
              登录 </a> <a href="/create" class="btn register"
                         onclick="woordeeGio({eventId:'regPageShow',eventLevelVariables:{triggerPage_var:'导航栏_注册有礼','triggerItem_var':'注册有礼'}},3,window.location.href)">
              <i class="icon-lrc"></i> <span>注册有礼</span> </a></div>
          </div>
          <div class="info"v-if="$store.getters.showName">
            <div class="personalCenter">
              <a class="order" title="我的订单" href="/personal/MyOrder"></a>
<!--              <li class="el-icon-share" title="邀请链接" href="" @click="share">-->
<!--&lt;!&ndash;                <div style="display: none">localhost:8080?invitationcode={{this.$store.getters.userInfo.invitationcode}}</div>&ndash;&gt;-->
<!--                <p class="layui-badge-dot im-unread" style="position: absolute;top: -3px;left: 14px;display: none" data-im="0" data-sys="0"></p>-->
<!--              </li>-->
              <div class="personal">
                <a href="/personal">
                  <i class="icon" id="iconUser"></i>
                </a>
                <div class="showHover">
                  <div class="personal_center" style="opacity: 1;display: block">
                    <div class="login_out"> <p> <a href="/personal">个人中心</a> </p>
                      <p class="pading">|</p>
                      <p> <a href="" @click="logout">退出</a> </p> </div>
                    <div class="personalInfo">
                      <!--vip:铜牌会员，vip1:银牌会员,vip2:金牌会员,vip3:铂金会员--> <!--<div class="vip"></div>-->
                      <a class="vip" href="/personal" ><img src="../../assets/img/avatar.jpg"></a>
                      <div class="phone">
                        <p class="phoneNumber">{{ $store.getters.userInfo.name }}</p>
                        <p class="vipInfo"> {{ this.$store.getters.userInfo.usertype==='2'? "企业会员":(this.$store.getters.userInfo.usertype==='1'?"普通会员":"子账户")}} <span v-if="this.$store.getters.userInfo.usertype!=='3'" class="time">邀请码： {{this.$store.getters.userInfo.invitationcode}} </span> </p>
                      </div>
                    </div>
                    <div class="gotoRecharge">
                      <div class="left">
                        <p class="price">{{ this.$store.getters.userInfo.remainingchars }}</p>
                        <p class="text"> 字符数量 </p> </div>
                      <div v-if="this.$store.getters.userInfo.usertype!=='3'" class="right"> <a href="/members" class="routerLink" onclick="woordeeGio({eventId:'walletPageView',eventLevelVariables:{triggerPage_var:'导航个人信息页','triggerItem_var':'去充值','leftAmount_var':'0.00'}},3)">
                        <span class="iconfont icon-chongzhi"></span>
                        <span class="text">去充值</span> </a> </div> </div>
<!--                    <div class="integral"> -->
<!--                      <ul> -->
<!--                        <li>-->
<!--                          <a href="/user/pointlog.html#point">-->
<!--                    <div class="integral"> <ul> <li><a href="/user/pointlog.html#point">-->
<!--                      <p>积分</p>-->
<!--                      <p>0</p> </a> </li>-->
<!--                      <li><a href="/user/pointlog.html#gold">-->
<!--                        <p>奖励金</p>-->
<!--                        <p>0</p> </a> </li> </ul> </div>-->
                    <div class="membershipUpgrade">
                      <ul>
                        <li> <a href="/members">开通会员</a> </li>
                        <li class="exchangeVip">
                          <a href="javaScript:void (0)">兑换会员</a> </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

<!--  <div class="navbar" :class="{ bgc1: this.isBgc1, bgc2: this.isBgc2, }">
    <div class="navbar-logo">
      <img src="../../assets/img/logo.png" alt="" />
      <h2>CC翻译</h2>
    </div>

    <div class="navbar-item">
      <ul>
        <li v-for="(item, index) in items">

          <a
            href=""
            @click="itemOnClick(index)"
            :class="{ active: index === cIndex }"
            >{{ item.text }}</a
          >
        </li>
      </ul>

      <ul v-if="!$store.getters.showName">
        <li v-for="(item, index) in items2">
          <a
              href=""
              @click="itemOnClick1(index)"
              :class="{ active: (4+index) === cIndex }"
          >{{ item.text }}</a
          >
        </li>

      </ul>
      <ul v-if="$store.getters.showName">
        <li>
          <a
              href=""
              @click="itemOnClick3()"
              :class="{ active: 7 === cIndex} "
          ><i class="el-icon-user" /> {{ $store.getters.userInfo.name }}</a
          >
        </li>

      </ul>
      <div class="select">
        <ChannelSelected></ChannelSelected>
      </div>
    </div>

  </div>-->
</template>

<script>
import ChannelSelected from "../channelSelected/ChannelSelected";
import axios from "axios";
export default {
  name: "Navbar",
  components: {
    ChannelSelected
  },
  data() {
    return {
      // showname:false,
      // userInfo:{
      //
      // },
      logoutflag:true,
      flag:false,
      items:[
          { route: "/home" },
          { route: "/download"},
          { route: "/members"},
          { route: "/pricing"},
          { route: "/about"},
      ],
      items1:["/problem/page1","/problem/page2","/problem/page3","/problem/page4","/problem/page5"],
      // items: [
      //   { route: "/home", text: this.$t('home') },
      //   { route: "/download", text: this.$t('download') },
      //   // { route: "/about", text: this.$t('about') },
      //   { route: "/pricing", text: this.$t('pricing') },
      //   { route: "/problem", text: this.$t('support') },
      // ],
      // items2:[
      //   { route: "/signIn", text: this.$t('signIn') },
      //   { route: "/create", text: this.$t('create') },
      // ],
      cIndex: 0,
      isBgc1: true,
      isBgc2: false,
    };
  },
  methods: {
    share(){
      this.$alert('这是一段内容', '标题名称', {
        confirmButtonText: '确定',
        callback: action => {
          this.$message({
            type: 'info',
            message: `action: ${ action }`
          });
        }
      });
    },
    logout() {
      let loginInfo={
          username:this.$store.getters.userInfo.username,
      }
      axios.post('/api/customer/customerMng/logout',loginInfo).then(res=>{
          if(!res.data.success){
            this.logoutflag=false;
          }
      })
      if(this.logoutflag){
        this.$store.commit('remove');
        this.$router.push({path :"/signIn"});
      }
    },
    itemOnClick(index) {
      event.preventDefault(); //阻止默认事件

      // 路由跳转
      if (this.$route.path != this.items[index].route) {
        this.$router.push(this.items[index].route);
        this.cIndex = index;
        this.changBgc();
      }
    },
    itemOnClick1(index){
      event.preventDefault(); //阻止默认事件

      // 路由跳转
      if (this.$route.path != this.items2[index].route) {
        this.$router.push(this.items2[index].route);
        this.cIndex = 4+index;
        this.changBgc();
      }
    },
    itemOnClick3(){
      event.preventDefault(); //阻止默认事件

      // 路由跳转
      if (this.$route.path != "/person") {
        this.$router.push("/person");
        this.cIndex = 7;
        this.changBgc();
      }
    },

    // 修改背景颜色
    // changBgc() {
    //   if (this.$route.path == "/home" || this.$route.path == "/shalouapp") {
    //     this.isBgc1 = true;
    //     this.isBgc2 = false;
    //     this.isBgc3 = false;
    //   }
    //
    //   if (this.$route.path == "/ipsw" || this.$route.path == "/help") {
    //     this.isBgc1 = false;
    //     this.isBgc2 = true;
    //     this.isBgc3 = false;
    //   }
    //
    //   if (this.$route.path == "/cooperate") {
    //     this.isBgc1 = false;
    //     this.isBgc2 = false;
    //     this.isBgc3 = true;
    //   }
    // },
  },
  created() {
    /**
     * 刷新时 同步 cIndex 、 背景颜色
     *
     */
    this.cIndex = this.items.findIndex(
      (item) => item.route === this.$route.path
    );
    if(this.items1.indexOf(this.$route.path)>=0){
        this.cIndex=5;
    }
    // this.changBgc();
  },



};
</script>
<!--<link rel="stylesheet" type="text/css" href="static/web/css/index_v3234d5f.css?v=3.3.49"/>
<link rel="stylesheet" type="text/css" href="static/common/css/swiper.min4d5f.css?v=3.3.49"/>-->
<style scoped>
/* 全局CSS导入 */
/*@import '../../../public/static/web/css/index_v3234d5f.css?v=3.3.49';
@import '../../../public/static/common/css/swiper.min4d5f.css?v=3.3.49';*/

.showHover{
  display: none;
}
.personal:hover .showHover{
  display: block;
}
/*.navbar-logo img {*/
/*  width: 50px;*/
/*  height: 50px;*/
/*  margin-left: 16px;*/
/*  margin-right: 8px;*/
/*}*/

/*.navbar-logo h2 {*/
/*  color: #fff;*/
/*  line-height: 80px;*/
/*  font-size: 1.5rem;*/
/*  font-weight: 500;*/
/*  letter-spacing: 1px;*/
/*}*/

/*.navbar-item {*/
/*  height: 80px;*/
/*  flex: 3;*/
/*}*/

/*.navbar-item ul {*/
/*  list-style: none;*/
/*}*/

/*.navbar-item li {*/
/*  height: 80px;*/
/*  line-height: 80px;*/
/*  width: 120px;*/
/*  float: left;*/
/*  margin: 0 15px;*/
/*  text-align: center;*/
/*}*/
/*.select{*/
/*  height: 80px;*/
/*  line-height: 80px;*/
/*  width: 120px;*/
/*  float: left;*/
/*  margin: 0 15px;*/
/*  text-align: center;*/
/*}*/
/*.navbar-item a {*/
/*  text-decoration: none;*/
/*  color: #fff;*/
/*  width: 100%;*/
/*  height: 100%;*/
/*  display: block;*/
/*}*/

.active {
  color: #0aa967;
}
</style>
