<template>
  <div class="coupon-cont wd-shadow">
    <div class="cont"> <el-table
        v-loading="loading"
        :data="customerList"
        style="width: 100%">
      <el-table-column
          prop="username"
          label="用户名"
          width="200">
      </el-table-column>
      <el-table-column
          prop="email"
          label="邮箱">
      </el-table-column>
      <el-table-column
          prop="usertype"
          label="用户类型"
      >
      </el-table-column>
    </el-table>
    </div>
    <div class="position">
      <el-pagination
          @current-change="handleCurrentChange"
          :current-page.sync="page.pageNo"
          :page-size="page.pageSize"
          layout="total, prev, pager, next"
          :total="page.total">
      </el-pagination>
    </div>
  </div>
</template>

<script>
import axios from "axios";

export default {
  name: "ProxyCustomer",
  data(){
    return{
      loading:true,
      customerList:[],
      page:{
        total:0,
        pageNo:1,
        pageSize:7,
      },
    }
  },
  methods:{
    handleCurrentChange(){
      this.loading=true;
      var parms={
        pageNo:this.page.pageNo,
        pageSize:this.page.pageSize,
      }
      axios.post("/api/customer/chatCustomer/queryAgentCustomersPageList",parms).then(res=> {
        // console.log("id"+this.$store.getters.userInfo.id)
        if (res.data.success) {
          this.customerList = res.data.result.records;
          this.customerList.map(function (val) {
            if(val.usertype === '1'){
              val.usertype = '普通用户'
            }else if(val.usertype ==='2'){
              val.usertype = '企业用户'
            }else if(val.usertype === '4'){
              val.usertype = '代理用户'
            }
          })
          this.page.total = res.data.result.total;
        }
        this.loading=false;
      })
    }
  },
  created() {
    this.handleCurrentChange();
  }
}
</script>

<style scoped>

</style>
