<template>
  <div class="coupon-cont wd-shadow">
    <div class="cont"> <el-table
        v-loading="loading"
        :data="extractRecordsList"
        style="width: 100%">
      <el-table-column
          prop="createTime"
          label="时间"
          width="200">
      </el-table-column>
      <el-table-column
          label="反佣比例">
        <template slot-scope="scope">
          {{ getCommissionrate(scope.row)}}%
        </template>
      </el-table-column>
      <el-table-column
          prop="reqamount"
          label="提取金额"
      >
      </el-table-column>
      <el-table-column
          label="状态"
      >
        <template slot-scope="scope">
          {{getSettleState(scope.row)}}
        </template>
      </el-table-column>
      <el-table-column
          label="操作"
          width="100">
        <template slot-scope="scope">
          <el-button v-if="scope.row.settlestate===1" @click="queryOrderInfo(scope.row)" type="text" size="medium">查看详情</el-button>
        </template>
      </el-table-column>
    </el-table>
      <el-dialog title="结算订单列表" :visible.sync="dialogTableVisible">
        <el-table :data="orderList" v-loading="loadingOrder">
          <el-table-column property="orderid" label="订单号" width="200"></el-table-column>
          <el-table-column label="套餐" property="chatPackageId_dictText" width="150"></el-table-column>
          <el-table-column property="realpay" label="实付金额"></el-table-column>
          <el-table-column property="numberofpackages" label="购买数量"></el-table-column>
          <el-table-column property="createTime" label="充值时间" width="200"></el-table-column>
        </el-table>
          <el-pagination
              @current-change="handleCurrentChangeOrder"
              :current-page.sync="pageOrder.pageNo"
              :page-size="pageOrder.pageSize"
              layout="total, prev, pager, next"
              :total="pageOrder.total">
          </el-pagination>
      </el-dialog>
    </div>
    <div class="position">
      <el-pagination
          @current-change="handleCurrentChange"
          :current-page.sync="page.pageNo"
          :page-size="page.pageSize"
          layout="total, prev, pager, next"
          :total="page.total">
      </el-pagination>
    </div>
  </div>
</template>

<script>
import axios from "axios";

export default {
  name: "ProxyExtractRecords",
  data(){
    return{
      dialogTableVisible:false,
      loadingOrder:true,
      loading:true,
      orderList:[],
      proxy:[],
      extractRecordsList:[],
      page:{
        total:0,
        pageNo:1,
        pageSize:7,
      },
      pageOrder:{
        total:0,
        pageNo:1,
        pageSize:5,
      },
    }
  },
  methods:{
    queryOrderInfo(row){
      this.dialogTableVisible=true;
      this.proxy=row;
      this.getOrderList();
    },
    getOrderList(){
      this.loadingOrder=true;
      const params={
          settleid:this.proxy.id,
          pageNo:this.pageOrder.pageNo,
          pageSize:this.pageOrder.pageSize,
      }
      axios.get("/api/customer/chatOrders/chatOrders/list",{
        params
      }).then(res=> {
        if (res.data.success) {
          this.orderList= res.data.result.records;
          this.pageOrder.total = res.data.result.total;
        }
        this.loadingOrder=false;
      })
    },
    getCommissionrate(row){
      return row.commissionrate*100
    },
    getSettleState(row){
      if(row.settlestate===0){
        return '结算中'
      }else if(row.settlestate===1){
        return '已完成'
      }else if(row.settlestate===2){
        return '退回'
      }
    },
    handleCurrentChangeOrder(){
      this.getOrderList();
    },
    handleCurrentChange(){
      this.loading=true;
      var parms={
        pageNo:this.page.pageNo,
        pageSize:this.page.pageSize,
      }
      axios.post("/api/customer/chatCustomer/queryAgentCustomersSettlementPageList",parms).then(res=> {
        // console.log("id"+this.$store.getters.userInfo.id)
        if (res.data.success) {
          this.extractRecordsList = res.data.result.records;
          this.page.total = res.data.result.total;
        }
        this.loading=false;
      })
    }
  },
  created() {
    this.handleCurrentChange();
  }
}
</script>

<style scoped>

</style>

