<template>
<div class="page8">
  <ul class="faqList">
    <el-collapse class="list" v-model="activeName" accordion>
      <el-collapse-item title="1、会员有哪些权益" name="1">
        <div class="info">
          <p>1.更低的价格：会员根据级别享受不同的折扣，级别越高折扣力度越大，相当于单单省钱；&nbsp;</p><p>2.更多重特权：更多「收藏译员」机会，帮您打造专属翻译团队；&nbsp;</p><p>3.更长质保期：“质保”为语翼推出的质量保障服务，即如译稿不满意，您有权利要求译员免费提供修改直至满意为止；银牌/金牌/铂金会员分别享有37/40/45天免费质保，质保期更长，免去您的后顾之忧。</p><p><br/></p>									</div>
      </el-collapse-item>
      <el-collapse-item title="2、什么是语翼会员" name="2">
        <div class="info">
          <p>语翼是定位于中小企业用户的翻译服务商，但同样适合个人消费者。不设最低消费，只要您不定期有翻译需求，都可以按需选择会员套餐，以使每次服务均获得折扣，获取更为长久的节流。</p><p><br/></p>									</div>
      </el-collapse-item>
    </el-collapse>
  </ul>
</div>
</template>

<script>
export default {
  name: "page8",
  data() {
    return {
      activeName: '0'
    };
  }
}
</script>

<style scoped>
.info {
  background: #f2f2f2;
  padding: 15px 20px;
  border-radius: 10px;
}
</style>
