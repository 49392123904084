<template>
  <div class="coupon-cont wd-shadow">
    <div class="dataSearch">
      <el-date-picker
          v-model="value"
          type="datetimerange"
          :picker-options="pickerOptions"
          range-separator="至"
          start-placeholder="开始日期"
          end-placeholder="结束日期"
          value-format="yyyy-MM-dd HH:mm:ss"
          align="right"
          @change="dataSearch">
      </el-date-picker>
      <div class="orderSearch">
        <el-form :inline=true>
          <el-form-item label="订单号：">
            <el-input  v-model="orderId" placeholder="请输入订单号">
            </el-input>
          </el-form-item>
         <el-form-item>
           <el-button type="primary"  icon="el-icon-search" @click="searchByorderId">查询</el-button>
         </el-form-item>
        </el-form >

      </div>
    </div>
    <div class="cont"> <el-table
        :data="order"
        v-loading="loading"
        style="width: 100%"
        :row-class-name="tableRowClassName">
      <el-table-column
          prop="orderid"
          label="订单号"
          width="200">
      </el-table-column>
      <el-table-column
          prop="pname"
          label="套餐种类"
      >
      </el-table-column>
      <el-table-column
          prop="create_time"
          label="支付时间"
          width="200">
      </el-table-column>
      <!--      <el-table-column-->
      <!--          prop="originalamount"-->
      <!--          label="原始金额">-->
      <!--      </el-table-column>-->
      <!--      <el-table-column-->
      <!--          prop="amountofrelief"-->
      <!--          label="减免金额">-->
      <!--      </el-table-column>-->
      <el-table-column
          prop="realpay"
          label="实付金额">
      </el-table-column>
      <el-table-column
          prop="paystatus"
          label="订单状态"
      >
      </el-table-column>
        <el-table-column
            label="操作"
            width="100">
          <template slot-scope="scope">
            <el-button icon="el-icon-refresh-left" v-if="scope.row.paystatus==='支付中'" @click="updateOrderInfo(scope.row)" type="text" size="medium">刷新</el-button>
          </template>
      </el-table-column>
    </el-table>
    </div>
    <div class="position">
      <el-pagination
          @current-change="handleCurrentChange"
          :current-page.sync="page.pageNo"
          :page-size="page.pageSize"
          layout="total, prev, pager, next"
          :total="page.total">
      </el-pagination>
    </div>
  </div>

</template>

<script>
import axios from "axios";

export default {
  name: "MyOrder",
  data(){
    return{
      loading:true,
      orderId:'',
      order:[],
      page:{
        total:0,
        pageNo:1,
        pageSize:7,
      },
      pickerOptions: {
        shortcuts: [{
          text: '最近一周',
          onClick(picker) {
            const end = new Date();
            const start = new Date();
            start.setTime(start.getTime() - 3600 * 1000 * 24 * 7);
            picker.$emit('pick', [start, end]);
          }
        }, {
          text: '最近一个月',
          onClick(picker) {
            const end = new Date();
            const start = new Date();
            start.setTime(start.getTime() - 3600 * 1000 * 24 * 30);
            picker.$emit('pick', [start, end]);
          }
        }, {
          text: '最近三个月',
          onClick(picker) {
            const end = new Date();
            const start = new Date();
            start.setTime(start.getTime() - 3600 * 1000 * 24 * 90);
            picker.$emit('pick', [start, end]);
          }
        }]
      },
      value:'',
    }
  },
  methods:{
    searchByorderId(){
        this.loading=true;
        if(this.orderId===''){
          this.dataSearch();
        }else{
          axios.get("/api/customer/buyServiceMng/queryOrderById?orderid="+this.orderId).then(res=>{
            if(res.data.success){
              this.order=[];
              if(res.data.result!==null) {
                this.order[0] = res.data.result;
                this.page.total = 1;
                this.order.map(function (val) {
                  if (val.paystatus === '0') {
                    val.paystatus = '支付中'
                  } else if (val.paystatus === '1') {
                    val.paystatus = '支付成功'
                  } else if (val.paystatus === '2') {
                    val.paystatus = '支付失败'
                  }
                })
                this.$message({
                  message: res.data.message,
                  type: 'success'
                })
              }
            }else{
              this.page.total=0;
              this.order=[];
              this.$message({
                message:res.data.message,
                type:'warning'
              })
            }
            this.loading=false;
          })
        }
    },
    dataSearch(){
      // console.log(this.value)
      this.loading=true;
      let parms={
        id:this.$store.getters.userInfo.id,
        pageNo:this.page.pageNo,
        pageSize:this.page.pageSize,
        date:this.value,
      }
      // console.log(111)
      axios
          .post("/api/customer/buyServiceMng/list",parms).then(res=> {
        // console.log(111)
        // console.log("id"+this.$store.getters.userInfo.id)
        if (res.data.success) {
          // console.log("====="+res.data.result.total)
          // console.log(res.data.result.data)
          this.order = res.data.result.data;
          this.order.map(function (val) {
            if(val.paystatus === '0'){
              val.paystatus = '支付中'
            }else if(val.paystatus === '1'){
              val.paystatus = '支付成功'
            }else if(val.paystatus === '2'){
              val.paystatus = '支付失败'
            }
          })
          this.page.total = res.data.result.total;
        }
        this.loading=false;
      })
    },
    updateOrderInfo(item){
      //手动查单
      this.loading=true;
      let parmas={
        mchOrderNo:item.orderid,
      }
      axios.post("/api/customer/buyServiceMng/manualQueryOrder",parmas).then(res=>{
        if(res.data.success) {
          item = res.data.result;
          this.handleCurrentChange();
          this.$message({
            message: '查询成功',
            type: 'success'
          });
        }else{
          this.$message.error(res.data.message);
        }
        this.loading=false;
      })

    },
    tableRowClassName({row, rowIndex}) {
      if (this.order[rowIndex].paystatus === '支付中') {
        return 'warning-row';
      }else if (this.order[rowIndex].paystatus === '支付成功') {
        return 'success-row';
      }else if(this.order[rowIndex].paystatus === '支付失败'){
        return 'default-row';
      }
    },
    handleCurrentChange(){
      this.loading=true;
      var parms={
        id:this.$store.getters.userInfo.id,
        pageNo:this.page.pageNo,
        pageSize:this.page.pageSize,
        date:this.value,
      }
      axios
          .post("/api/customer/buyServiceMng/list",parms).then(res=> {
        // console.log("id"+this.$store.getters.userInfo.id)
        if (res.data.success) {
          // console.log("====="+res.data.result)
          // console.log(res.data.result.data)
          this.order = res.data.result.data;
          this.order.map(function (val) {
            if(val.paystatus === '0'){
              val.paystatus = '支付中'
            }else if(val.paystatus === '1'){
              val.paystatus = '支付成功'
            }else if(val.paystatus === '2'){
              val.paystatus = '支付失败'
            }
          })
          this.page.total = res.data.result.total;
          // this.tableData = res.data.result.;
          // this.page.total = res.data.result.total;
        }
        this.loading=false;
      })
    },
  },
  created() {
    this.handleCurrentChange();
  }
}
</script>

<style>
.dataSearch{
  display: block;
  float: left;
  width: 855px;
  margin-left: 25px;
  margin-top: 10px;
  margin-bottom: 0px;
  background: #fff;
  border-radius: 4px;
}
.orderSearch{
  width: 400px;
  float: right;
  margin-right: 0px;
}
.cont{
  display: block;
  float: left;
  width: 100%;
  /*margin-left: 25px;*/
  margin-bottom: 10px;
  background: #fff;
  border-radius: 4px;
}
.position{
  display: block;
  float: left;
  width: 855px;
  margin-left: 25px;
  margin-bottom: 10px;
  background: #fff;
  border-radius: 4px;
}
.coupon-cont {
  display: block;
  float: left;
  width: 905px;
  margin-left: 15px;
  margin-bottom: 40px;
  background: #fff;
  border-radius: 4px;
  min-height: 600px;
}
.el-table .warning-row {
  background: oldlace;
}

.el-table .success-row {
  background: #f0f9eb;
}
.el-table .default-row {
  background: #f0f0f0;
}
</style>
