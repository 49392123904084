<template>
  <div class="personal-content wd-shadow userComon">
    <!-- <h3 class="title">个人资料</h3> -->
    <!--表单-->
    <form class="layui-form" action="" lay-filter="personal">
<!--      <div class="layui-form-item edit-avatar">-->
<!--        <label class="layui-form-label">头像</label>-->
<!--        <div class="layui-input-block">-->
<!--          <a id="editTitleImg" href="javascript:;">-->
<!--            <img id="imageUrl" class="avatar" src="https://filemanager.woordee.com/woordee_avatar_001.jpg?x-oss-process=image/resize,m_lfit,h_80,w_80" alt="" title="">-->
<!--            <span class="iconfont icon-picture"></span>-->
<!--          </a>-->
<!--        </div>-->
<!--      </div>-->
      <div class="layui-form-item">
        <div class="icon" style="width: 50px;height: 50px;float: left;">
          <img src="../../../../public/static/common/img/i_09_nicheng1.png" width="35" height="35" alt="">
        </div>
        <div style="float: left;padding-left: 10px;">

          <label class="layui-form-label">昵称</label>
          <div class="layui-input-block">
            <div class="field-read" v-if="!editNameFlag">
              <span>{{ this.$store.getters.userInfo.name }}</span>
              <a v-if="this.$store.getters.userInfo.usertype!=='3'" class="edit-phone" @click="editName">编辑</a>
            </div>
            <div class="edit-item" v-if="editNameFlag">
              <input id="nickName" name="nick_name" maxlength="20" type="text" required="" lay-verify="required" placeholder="请输入昵称" autocomplete="off" class="layui-input" v-model="user.name" >
              <span class="btn cancel" _name="nick_name" @click="cancleName">取消</span>
              <span class="btn sure" _name="nick_name" @click="update">保存</span>
            </div>
          </div>
          <div class="Validform_checktip left2">
                    <span class="Validform_checktip Validform_wrong layui-hide">请输入2-50位字符
                    </span>
          </div>
        </div>
      </div>
      <div v-if="this.$store.getters.userInfo.usertype!=='3'" class="layui-form-item">
        <div class="icon" style="width: 50px;height: 50px;float: left;">
          <img src="../../../../public/static/common/img/i_09_email2.png" width="35" height="35" alt="">
        </div>
        <div style="float: left;padding-left: 10px;">
          <label class="layui-form-label">邮 &nbsp;&nbsp;&nbsp;&nbsp;箱</label>
          <div class="layui-input-block">
            <div class="field-read">
              <span>{{ this.$store.getters.userInfo.email}}</span>
              <a class="edit-phone" href="/personal/Security/ChangeEmail">更改邮箱</a>
            </div>
          </div>
        </div>
      </div>
      <div v-if="this.$store.getters.userInfo.usertype==='3'" class="layui-form-item">
        <div class="icon" style="width: 50px;height: 50px;float: left;">
          <img src="../../../../public/static/common/img/i_09_account.png" width="35" height="35" alt="">
        </div>
        <div style="float: left;padding-left: 10px;">
          <label class="layui-form-label">账 &nbsp;&nbsp;&nbsp;&nbsp;号</label>
          <div class="layui-input-block">
            <div class="field-read">
              <span>{{ this.$store.getters.userInfo.username}}</span>
            </div>
          </div>
        </div>
      </div>
      <div class="layui-form-item">
        <div class="icon" style="width: 50px;height: 50px;float: left;">
          <img src="../../../../public/static/common/img/i_09_shengyu.png" width="35" height="35" alt="">
        </div>
        <div style="float: left;padding-left: 10px;">

          <label class="layui-form-label">剩 余 字 符</label>
          <div class="layui-input-block">
            <div class="field-read">
              <span>{{ this.$store.getters.userInfo.remainingchars}}</span>
              <a v-if="this.$store.getters.userInfo.usertype!=='3'" class="edit" href="/members">去充值</a>
            </div>
          </div>
        </div>
      </div>
      <div v-if="this.$store.getters.userInfo.usertype!=='3'" class="layui-form-item">
        <div class="icon" style="width: 50px;height: 50px;float: left;">
          <img src="../../../../public/static/common/img/i_09_yaoqingma1.png" width="35" height="35" alt="">
        </div>
        <div style="float: left;padding-left: 10px;">

          <label class="layui-form-label">邀 请 码</label>
          <div class="layui-input-block">
            <div class="field-read" >
              <div id="invitationcode" style="float: left">
                <span>{{ this.$store.getters.userInfo.invitationcode}}</span>
              </div>
              <a class="edit" @click="handleDivCopy1()">复制</a>
            </div>
          </div>
        </div>
      </div>
      <div v-if="this.$store.getters.userInfo.usertype!=='3'" class="layui-form-item">
        <div class="icon" style="width: 50px;height: 50px;float: left;">
          <img src="../../../../public/static/common/img/i_09_yaoqinglianjie1.png" width="41" height="41" alt="">
        </div>
        <div style="float: left;padding-left: 10px;">

          <label class="layui-form-label">邀 请 链 接</label>
          <div class="layui-input-block">
            <div class="field-read">
              <div  id="invitationlink" style="float: left;">
                <span>{{ this.$store.getters.userInfo.invitationlink}}</span>
              </div>
              <a class="edit" @click="handleDivCopy2()">复制</a>
            </div>
          </div>
        </div>
      </div>
    </form>
  </div>
</template>

<script>
import axios from "axios";

export default {
  name: "Data",
  data(){
    return{
      user:{
        name:'',
      },
      editNameFlag:false,
    }
  },
  methods:{
    editName(){
      this.user.name=this.$store.getters.userInfo.name;
      this.editNameFlag=true;
    },
    cancleName(){
      this.editNameFlag=false;
    },
    update(){
      if(this.user.name.trim()!==''){
        let params=this.$store.getters.userInfo;
        params.name=this.user.name;
        axios.post("/api/customer/customerMng/update",params)
            .then(res=>{
              if(res.data.success){
                // console.log(res)
                this.$store.commit('handleUserInfo',res.data.result.customer);
                this.model=res.data.result.customer;
                this.$message({
                  message: res.data.message,
                  type: 'success'
                });
                this.editNameFlag=false;
              }else{
                this.$message.error(res.data.message);
              }
            })
      }
    },
    handleDivCopy1() {
      const div = document.getElementById("invitationcode");
      const input = document.createElement("input");
      document.body.appendChild(input);
      input.value = div.innerText;
      input.select();
      try {
        if (document.execCommand("copy", false)) {
          this.$message({
            message: '复制成功',
            type: 'success'
          });
        } else {
          //handleDomMsg("div 内容复制失败");
          this.$message.error("复制失败")
          // message.info("复制失败");
        }
      } catch (error) {
        console.log(error, "error")
      } finally {
        input.remove();
      }
    },
    // 复制 div 内容
    handleDivCopy2() {
      const div = document.getElementById("invitationlink");
      const input = document.createElement("input");
      document.body.appendChild(input);
      input.value = div.innerText;
      input.select();
      try {
        if (document.execCommand("copy", false)) {
          // message.info("复制成功");
          this.$message({
            message: '复制成功',
            type: 'success'
          });
        } else {
          //handleDomMsg("div 内容复制失败");
          // message.info("复制失败");
          this.$message.error("复制失败")
        }
      } catch (error) {
        console.log(error, "error")
      } finally {
        input.remove();
      }
    },
  },
}
</script>

<style scoped>
.personal-content {
  display: block;
  float: left;
  width: 905px;
  background: #fff;
  margin-left: 15px;
  margin-bottom: 40px;
  min-height: 600px;
}
.personal-content .layui-form {
  padding-left: 30px;
}
.personal-content .layui-form .layui-form-item {
  margin: 0;
  padding-top: 30px;
  box-sizing: border-box;
  height: 100px;
  border-bottom: 1px solid #eaeaea;
}
.personal-content .layui-form .layui-form-item .layui-form-label {
  padding: 0 16px 0 0;
  font-size: 16px;
  color: #666;
  line-height: 40px;
  text-align: left;
}
.layui-form-label {
  float: left;
  display: block;
  padding: 9px 15px;
  width: 100px;
  font-weight: 400;
  line-height: 20px;
  text-align: right;
}
.personal-content .layui-form .layui-form-item .layui-input-block .field-read {
  height: 40px;
  line-height: 40px;
}
.personal-content .layui-form .layui-form-item .layui-input-block .field-read span {
  font-size: 16px;
  font-family: MicrosoftYaHei,"\5FAE\8F6F\96C5\9ED1";
  color: #222;
  margin-right: 20px;
  max-width: 670px;
  float: left;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  word-break: break-all;
}
.personal-content .layui-form .layui-form-item .layui-input-block .field-read .edit, .personal-content .layui-form .layui-form-item .layui-input-block .field-read .add, .personal-content .layui-form .layui-form-item .layui-input-block .field-read .edit-phone {
  font-size: 16px;
  font-family: MicrosoftYaHei,"\5FAE\8F6F\96C5\9ED1";
  color: #1aba79;
  cursor: pointer;
  float: left;
}
.edit.edit-phone:before, .personal-content .layui-form .layui-form-item .layui-input-block .field-read .add.edit-phone:before, .personal-content .layui-form .layui-form-item .layui-input-block .field-read .edit-phone.edit-phone:before {
  content: '';
  display: inline-block;
  width: 16px;
  height: 14px;
  background: url(../../../../public/static/common/img/img_6.png) -46px -55px;
  margin-right: 8px;
  margin-bottom: -2px;
}
.personal-content .layui-form .layui-form-item .layui-input-block .layui-input, .personal-content .layui-form .layui-form-item .layui-input-block .layui-select {
  width: 410px;
  height: 40px;
  border-color: #d3d3d3;
  border-radius: 4px;
  color: #222;
}
.personal-content .layui-form .layui-form-item .layui-input-block .btn.cancel {
  background: #fff;
  border: 1px solid #1aba79;
  color: #1aba79;
}
.personal-content .layui-form .layui-form-item .layui-input-block .btn {
  width: 80px;
  height: 40px;
  font-size: 14px;
  line-height: 40px;
  text-align: center;
  border-radius: 4px;
  box-sizing: border-box;
  cursor: pointer;
  display: block;
  float: left;
  margin-left: 10px;
}
.personal-content .layui-form .layui-form-item .layui-input-block .btn.sure {
  background: #1aba79;
  border: 1px solid #fff;
  color: #fff;
}
.personal-content .layui-form .layui-form-item .layui-input-block .layui-input {
  display: block;
  float: left;
}

</style>
