<template>
  <div class="usercenter-content usercenter-cont">
    <!--内容上部 start-->
    <div class="usercenter-top wd-shadow"> <!--span class="tag-icon">邀请好友得福利</span-->
      <div class="cont-1">
        <div class="like-amout-img "><img src="../../../assets/img/avatar.jpg"></div>
        <div class="inline-2">
              <p class="p-1">
                <span class="pname">{{ this.$store.getters.userInfo.name }}</span> <a v-if="this.$store.getters.userInfo.usertype!=='3'" href="/personal/Data" class="el-icon-edit"></a>
              </p>

          <p class="p-2 silver">
            <span class="noble">{{ this.$store.getters.userInfo.usertype==='2'? "企业会员":(this.$store.getters.userInfo.usertype==='1'?"普通会员":"子账户")}}</span>

<!--            <div v-if="this.$store.getters.userInfo.usertype!=='3'">-->
          <span v-if="this.$store.getters.userInfo.usertype!=='3'" >邀请码： <span  id="invitationcode">{{this.$store.getters.userInfo.invitationcode}} </span> </span>
            <a v-if="this.$store.getters.userInfo.usertype!=='3'" title="复制邀请码"  class="el-icon-copy-document1 shubiao" @click="handleDivCopy()">
              <a href="#" class="to-christmas" style="color: #fff">复制邀请码</a>
              <img src="../../../../public/static/common/img/i_10_copyIt.png"  style="cursor:pointer" width="25" height="25">
            </a>
<!--              <div class="childer1 on-show">
                <a href="/members">钜惠来袭</a>
              </div>-->
          <span style="display: none"  id="invitationlink">{{this.$store.getters.userInfo.invitationlink}} </span>
          <a v-if="this.$store.getters.userInfo.usertype!=='3'" title="复制邀请链接"  class="el-icon-copy-document2 shubiao" @click="handleDivCopy1()">
            <a href="#" class="to-christmas2" style="color: #fff">复制邀请链接</a>
            <img src="../../../../public/static/common/img/i_10_copyLink.png" class="shubiao"  width="30" height="30">
          </a>
          <!--          <a class="layui-btn exchangeVipBox" href="javaScript:void (0)">购买套餐</a>-->
          <a v-if="this.$store.getters.userInfo.usertype!=='3'" class="layui-btn btn-upgrade" href="/members">购买套餐</a>
          </p>

        </div>
          <span v-if="this.$store.getters.userInfo.packageid!==null||this.$store.getters.userInfo.usertype==='3'" class="package">当前使用套餐：<span class="packageName">{{this.package.name}}</span></span>
          <span v-if="this.$store.getters.userInfo.usertype!=='3'&&this.$store.getters.userInfo.packageid===null" class="package">您暂未开通套餐 <a class="edit" href="/members">去充值</a></span>
        <div v-if="this.package.code!=='baoyue'">
          <div class="qr-code-right">
            <div class="qr-code">
              <div v-if="this.$store.getters.userInfo.usertype!=='3'">
                <el-select @change="changePackeage" v-model="translationtool" placeholder="请选择">
                  <el-option
                      v-for="item in translationtools"
                      :key="item.id"
                      :label="item.functionname"
                      :value="item.id">
                  </el-option>
                </el-select>
              </div>
              <div v-if="this.$store.getters.userInfo.usertype==='3'">
                <el-select @change="changePackeage" disabled v-model="translationtool" placeholder="请选择">
                  <el-option
                      v-for="item in translationtools"
                      :key="item.id"
                      :label="item.functionname"
                      :value="item.id">
                  </el-option>
                </el-select>
              </div>
            </div>
            <p>当前使用翻译通道</p>
            <div  v-if="this.$store.getters.userInfo.usertype==='3'">
              <p style="color:#FFA500">修改通道请联系企业主账户</p>
            </div>
          </div>
        </div>

      </div>
      <div v-if="this.$store.getters.userInfo.usertype!=='3'"  class="cont-2">
        <div class="cont-list p-2"><a class="order" title="去充值" href="/members">
          <p>{{this.$store.getters.userInfo.remainingchars}}</p>
          <span>字符余量</span></a></div>
        <div class="cont-list p-1">
          <a class="order" title="去充值" href="/members">
            <p>{{ this.$store.getters.userInfo.usedamount }}</p>
            <span>已使用字符</span></a></div>
        <div class="cont-list p-3"><a title="管理子账户" href="/personal/SubAccount">
          <p>{{ this.$store.getters.userInfo.subacccount }}</p>
          <span>子账户总数
          </span> </a></div>
        <div class="cont-list p-3"><a title="管理子账户" href="/personal/SubAccount">
          <p>{{ this.$store.getters.userInfo.openedsubacccount}}</p>
          <span>已开通子账户数量</span> </a></div>
        <div class="cont-list p-4">
          <a title="查看优惠券" href="/personal/MyCoupon">
            <p>{{ this.couponCount}}</p>
            <span>优惠券
<!--              <i class="overdue">5张即将过期</i>-->
            </span>
          </a>
        </div>
      </div>
      <div v-if="this.$store.getters.userInfo.usertype==='3'" class="cont-2">
        <div class="cont-list p-2">
          <p>{{this.$store.getters.userInfo.remainingchars}}</p>
          <span>字符余量</span> </div>
        <div class="cont-list p-1">
            <p>0</p>
            <span>已使用字符</span></div>
      </div>
    </div>
    <div class="entrance">
      <ul class="entrance-ul">
      </ul>
    </div>
  </div>
</template>

<script>
import axios from "axios";

export default {
  name: "MainPage",
  data(){
    return{
      package:[],
      translationtool:'',
      translationtoolname:'',
      couponCount:0,
      translationtools:[],
      //未开通(只有百度翻译）
      translationtools1:[{
        createBy: "admin",
        createTime: "2022-03-18 16:49:45",
        functioncode: "baiduTranslation",
        functiondescription: "百度翻译",
        functionname: "百度翻译",
        id: "1504741836748550146",
        remarks: "",
        sysOrgCode: "A01",
        updateBy: null,
        updateTime: null,
      },],

    }
  }
  ,methods:{
    changePackeage(){
      let params=this.$store.getters.userInfo;
      params.ctranslationtools=this.translationtool;
      axios.post("/api/customer/customerMng/updateTools",params).then(res=>{
        if(res.data.success){
          this.$message({
            message:res.data.message,
            type:'success'
          })
        }else{
          this.$message.error(res.data.message);
        }
        this.refresh();
      })
    },
    handleDivCopy(){
      const div = document.getElementById("invitationcode");
      const input = document.createElement("input");
      document.body.appendChild(input);
      input.value = div.innerText;
      input.select();
      try {
        if (document.execCommand("copy", false)) {
          this.$message({
            message: '复制成功',
            type: 'success'
          });
        } else {
          //handleDomMsg("div 内容复制失败");
          this.$message.error("复制失败")
          // message.info("复制失败");
        }
      } catch (error) {
        console.log(error, "error")
      } finally {
        input.remove();
      }
    },
    handleDivCopy1(){
      const div = document.getElementById("invitationlink");
      const input = document.createElement("input");
      document.body.appendChild(input);
      input.value = div.innerText;
      input.select();
      try {
        if (document.execCommand("copy", false)) {
          this.$message({
            message: '复制成功',
            type: 'success'
          });
        } else {
          //handleDomMsg("div 内容复制失败");
          this.$message.error("复制失败")
          // message.info("复制失败");
        }
      } catch (error) {
        console.log(error, "error")
      } finally {
        input.remove();
      }
    },
    refresh(){
      axios.get("/api/customer/customerMng/queryCustomer?id="+this.$store.getters.userInfo.id).then(res=>{
        if(res.data.success){
          this.$store.commit('handleUserInfo',res.data.result);
        }
      })
      this.translationtool=this.$store.getters.userInfo.ctranslationtools;
      let params={
        username:this.$store.getters.userInfo.username,
      }
      axios.post('/api/customer/couponMng/queryCount',params).then(res=>{
        if(res.data.success){
          this.couponCount=res.data.result;
        }
      })
    }
  },
  created() {
    // console.log(this.$store.getters.userInfo.ctranslationtools)
    this.translationtool=this.$store.getters.userInfo.ctranslationtools;
    axios.get("/api/customer/customerMng/queryCustomer?id="+this.$store.getters.userInfo.id).then(res=>{
      if(res.data.success){
        this.$store.commit('handleUserInfo',res.data.result);
        this.translationtool=res.data.result.ctranslationtools;
      }
    })
    let params={
      username:this.$store.getters.userInfo.username,
    }
    axios.post('/api/customer/couponMng/queryCount',params).then(res=>{
      // console.log("111"+res.data)
      if(res.data.success){
        this.couponCount=res.data.result;
      }
    })
    if(this.$store.getters.userInfo.usertype!=='3'&&(this.$store.getters.userInfo.invitationcode===null||this.$store.getters.userInfo.invitationcode==="")){
      axios.get("/api/customer/customerMng/InvitationCode?id="+this.$store.getters.userInfo.id).then(res=>{
        let userInfo=this.$store.getters.userInfo;
        userInfo.invitationcode=res.data.result.invitationcode;
        userInfo.invitationlink=res.data.result.invitationlink;
      })
    }
    if(this.$store.getters.userInfo.usertype==='3'){
      axios.get("/api/customer/packageMng/queryBySub?superenterid="+this.$store.getters.userInfo.superenterid).then(res=>{
        // console.log(res.data.result.translationtools)
        if(res.data.success){
          this.package = res.data.result.package;
          this.translationtools=res.data.result.translationtools;
          // console.log(this.translationtools)
        }else{
          this.$message({
            message: '当前使用套餐查询失败，请刷新重试',
            type: 'warning'
          });
        }
      })
    }else {
      if (this.$store.getters.userInfo.packageid !== null) {
        axios.get("/api/customer/packageMng/queryById?id=" + this.$store.getters.userInfo.packageid).then(res => {
          // console.log(res.data.result.translationtools)
          if (res.data.success) {
            this.package = res.data.result.package;
            this.translationtools=res.data.result.translationtools;
          } else {
            this.$message({
              message: '当前使用套餐查询失败，请刷新重试',
              type: 'warning'
            });
          }
        })
      }else{
        this.translationtools=this.translationtools1;
      }
    }
  },
}
</script>

<style scoped>
  .el-icon-copy-document1:hover > .to-christmas{
    display: block;
    color: #fff;
    width: 70px;
    text-align: center;
    padding: 4px 0;
    font-size: 12px;
    line-height: 12px;
    background: #fb2;
    box-shadow: 0 1px 5px 0 rgb(255 187 34 / 43%);
    border-radius: 10px;
    position: absolute;
    bottom: 12px;
    left: 50%;
    transform: translateX(-50%);
  }
 .to-christmas {
  display: none;
  color: #fff;
  width: 70px;
  text-align: center;
  padding: 4px 0;
  font-size: 12px;
  line-height: 12px;
  background: #fb2;
  box-shadow: 0 1px 5px 0 rgb(255 187 34 / 43%);
  border-radius: 10px;
  position: absolute;
  bottom: 12px;
  left: 50%;
  transform: translateX(-50%);
 }
  .el-icon-copy-document2:hover > .to-christmas2{
    display: block;
    color: #fff;
    width: 75px;
    text-align: center;
    padding: 4px 0;
    font-size: 12px;
    line-height: 12px;
    background: #fb2;
    box-shadow: 0 1px 5px 0 rgb(255 187 34 / 43%);
    border-radius: 10px;
    position: absolute;
    bottom: 12px;
    left: 55%;
    transform: translateX(-50%);
  }
  .to-christmas2 {
    display: none;
    color: #fff;
    width: 70px;
    text-align: center;
    padding: 4px 0;
    font-size: 12px;
    line-height: 12px;
    background: #fb2;
    box-shadow: 0 1px 5px 0 rgb(255 187 34 / 43%);
    border-radius: 10px;
    position: absolute;
    bottom: 12px;
    left: 50%;
    transform: translateX(-50%);
  }



  .edit{
  color: #1aba79;
}
.usercenter-cont {
  display: block;
  float: left;
  width: 905px;
  margin-left: 15px;
  margin-bottom: 40px;
}
.usercenter-top {
  height: 258px;
  padding: 40px 40px 15px 40px;
  box-sizing: border-box;
  background: #fff;
  border-radius: 4px;
  position: relative;
  font-size: 0;
  min-height: 600px;
}
.cont-1 {
  margin-top: 26px;
  padding-bottom: 60px;
  border-bottom: 1px solid #eaeaea;
}
.usercenter-cont .usercenter-top .cont-1 .like-amout-img {
  width: 60px;
  height: 60px;
  float: left;
}
.usercenter-cont .usercenter-top .cont-1 .inline-1, .usercenter-cont .usercenter-top .cont-1 .inline-2 {
  display: inline-block;
  vertical-align: top;
}
.usercenter-top .cont-1 .inline-2 .p-1 {
  height: 20px;
  font-size: 20px;
  line-height: 20px;
  margin-top: 4px;
  display: block;
  color: #484848;
}
.usercenter-cont .usercenter-top .cont-1 .inline-2 .p-1 .pname {
  max-width: 260px;
  display: inline-block;
  vertical-align: middle;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  margin-left: 0;
}
.usercenter-cont .usercenter-top .cont-1 .inline-2 .p-1 .edit {
  margin-left: 10px;
  cursor: pointer;
  display: inline-block;
  vertical-align: middle;
}
.exchangeVipBox {
  display: block;
  box-sizing: border-box;
  position: absolute;
  right: 180px;
  top: 6px;
  width: 96px;
  height: 40px;
  line-height: 39px;
  padding: 0;
  font-size: 16px;
  color: #fff;
  background: #1aba79;
  border-radius: 4px;
}
.package{
  display: block;
  box-sizing: border-box;
  position: absolute;
  right: 200px;
  top: 69px;
  width: 150px;
  height: 40px;
  line-height: 39px;
  padding: 0;
  font-size: 16px;
  border-radius: 4px;
}
.usercenter-top .cont-1 .inline-2 .btn-upgrade {
  display: block;
  box-sizing: border-box;
  position: absolute;
  right: 116px;
  top: 6px;
  width: 96px;
  height: 40px;
  line-height: 39px;
  padding: 0;
  font-size: 16px;
  color: #fff;
  background: #1aba79;
  border-radius: 4px;
}
.btn-upgrade {
  right: 100px !important;
}
.usercenter-cont .usercenter-top .cont-1 .qr-code-right {
  position: absolute;
  top: 69px;
  right: 30px;
}
.usercenter-cont .usercenter-top .cont-1 .qr-code {
  width: 106px;
  height: 50px;
  /*margin-right: 30px;*/
  display: block;
  box-sizing: border-box;
  padding: 2px;
  /*background: url(../../img/account_center/icon_usercenter.png) no-repeat -161px -237px;*/
}
.usercenter-cont .usercenter-top .cont-1 .qr-code-right p {
  font-family: MicrosoftYaHei;
  font-size: 13px;
  color: #999;
  margin-top: 6px;
}
.usercenter-cont .usercenter-top .cont-1 .inline-2 .p-2 {
  margin-top: 14px;
  height: 14px;
  font-size: 14px;
  line-height: 14px;
  color: #999;
}
.usercenter-top .cont-1 .inline-2 .p-2 i {
  vertical-align: top;
  display: inline-block;
  width: 16px;
  height: 14px;
  margin-left: 2px;
  margin-right: 2px;
}
.usercenter-cont .usercenter-top .cont-1 .inline-2 .p-2 span.noble {
  color: #ff9626;
  margin-right: 15px;
}
.packageName{
  color: #ff9626;
}
.usercenter-top .cont-1 .inline-2 {
  position: relative;
  margin-left: 24px;
  width: 514px;
}
.usercenter-cont .usercenter-top .cont-1 .inline-2 .p-1 .edit:before {
  content: '';
  display: block;
  width: 16px;
  height: 14px;

  /*background: url(../../img/account_center/personal-icon.png) -46px -55px;*/
}
/*p {*/
/*  display: block;*/
/*  margin-block-start: 1em;*/
/*  margin-block-end: 1em;*/
/*  margin-inline-start: 0px;*/
/*  margin-inline-end: 0px;*/
/*}*/
.usercenter-top .cont-2 {
  height: 50px;
  margin-top: 10px;
  font-size: 0;
}
.usercenter-top .cont-2 .cont-list {
  box-sizing: border-box;
  width: calc(20%);
  font-size: 14px;
  display: inline-block;
  border-right: 1px solid #eaeaea;
  text-align: center;
}
.usercenter-cont .usercenter-top .cont-2 .cont-list p {
  font-size: 18px;
  height: 18px;
  display: block;
  line-height: 18px;
  color: #1aba79;
  position: relative;
}
.usercenter-top .cont-2 .cont-list span {
  font-size: 14px;
  height: 14px;
  line-height: 14px;
  display: block;
  margin-top: 6px;
  color: #666;
  position: relative;
}
.usercenter-cont .usercenter-top .cont-2 .cont-list .overdue {
  display: inline-block;
  font-style: inherit;
  font-size: 12px;
  color: #fff;
  padding: 4px 10px;
  position: absolute;
  top: -30px;
  left: 112px;
  word-break: keep-all;
  background: #fff4db;
  color: #ffb200;
  border: 1px solid #ffb200;
  border-radius: 4px;
  box-shadow: 0 1px 7px 0 rgba(255,255,255,0.24), 0 3px 4px 0 rgba(255,187,35,0.24);
}
.usercenter-cont .usercenter-top .cont-2 .cont-list .overdue:before {
  content: '';
  width: 0;
  height: 0;
  position: absolute;
  border: 3px solid;
  border-color: #ffbb23 transparent transparent #ffbb23;
  left: 12px;
  bottom: -6px;
}
.usercenter-cont .usercenter-top .cont-2 .cont-list .overdue:after {
  content: '';
  width: 0;
  height: 0;
  position: absolute;
  border: 3px solid;
  border-color: #fff4db transparent transparent #fff4db;
  left: 13px;
  bottom: -4px;
}
.shubiao{
  cursor: pointer;
  padding: 5px;
}

</style>
