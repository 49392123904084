<template>
  <div style="margin-top: 60px ;">
    <div class="productservice" id="bodyContent">
      <div class="banner_ali">
        <div class="container">
          <div class="leftText fl" >
            <p class="title">关于CC翻译</p>
            <p>精选全球精准翻译工具，提供高质服务</p>
            <p>人工智能赋能翻译服务</p>
<!--            <a href="javascript:void(0);" onclick="M.alertQuote()">价格评估</a>-->
          </div>
          <div class="fr rightImg"></div>
        </div>
      </div>
      <div class="menu">
        <div class="box">
          <div class="item">
            <div class="icon">
              <img src="static/web/img/productservice/icon_14d5f.png?v=3.3.49">
            </div>
            <div class="word">
              <p>注册说明</p>
              <p>用户注册成功之后，充值满300即可自动升级为企业账户。</p>
            </div>
          </div>
          <div class="item">
            <div class="icon">
              <img src="static/web/img/productservice/icon_24d5f.png?v=3.3.49">
            </div>
            <div class="word">
              <p>软件充值</p>
              <p>本软件按照字符使用量扣费，<br>
                VIP1:200元/160万字符，<br>
                VIP2:320元/160万字符。<br>
                VIP3:680元/160万字符。
                </p>
            </div>
          </div>
          <div class="item">
            <div class="icon">
              <img src="static/web/img/productservice/icon_34d5f.png?v=3.3.49">
            </div>
            <div class="word">
              <p>翻译技巧</p>
              <p>本软件与全球最优翻译引擎合作，AI智能翻译。</p>
            </div>
          </div>
          <div class="item">
            <div class="icon">
              <img src="static/web/img/productservice/icon_44d5f.png?v=3.3.49">
            </div>
            <div class="word">
              <p>软件更新</p>
              <p>本软件不定期更新，用户如发现软件有更新提示，请到官网下载最新版本使用。</p>
            </div>
          </div>
        </div>
      </div>
      <div class="service_center">
        <p>CC翻译软件功能</p>
        <ul>
          <li>
            <div class="item">
              <div class="icon">
                <img src="static/web/img/productservice/icon_x_14d5f.png?v=3.3.49">
              </div>
              <div class="word">
                <p>自动翻译</p>
                <p>收发消息根据设置语言自动翻译,无需频繁操作</p>
              </div>
            </div>
            <div class="item">
              <div class="icon">
                <img src="static/web/img/productservice/icon_x_24d5f.png?v=3.3.49">
              </div>
              <div class="word">
                <p>多种翻译源</p>
                <p>针对小语种，升级企业账户，可以使用谷歌神经网络AI，精准翻译</p>
              </div>
            </div>
          </li>
          <li>
            <div class="item">
              <div class="icon">
                <img src="static/web/img/productservice/icon_x_34d5f.png?v=3.3.49">
              </div>
              <div class="word">
                <p>账户权限</p>
                <p>集团公司可以升级企业账户，拥有开通子账户权限，方便管理公司员工账户
                </p>
              </div>
            </div>
            <div class="item">
              <div class="icon">
                <img src="static/web/img/productservice/icon_x_44d5f.png?v=3.3.49">
              </div>
              <div class="word">
                <p>软件集成</p>
                <p>CC翻译集成了WhatsApp，Telegram，Line，Zalo国外主流聊天软件
                </p>
              </div>
            </div>
          </li>
          <li>
            <div class="item">
              <div class="icon">
                <img src="static/web/img/productservice/icon_x_54d5f.png?v=3.3.49">
              </div>
              <div class="word">
                <p>多开使用</p>
                <p>免费支持多开使用，可以同时登录多个聊天窗口
                </p>
              </div>
            </div>
            <div class="item">
              <div class="icon">
                <img src="static/web/img/productservice/icon_x_64d5f.png?v=3.3.49">
              </div>
              <div class="word">
                <p>网络切换</p>
                <p>针对不同地区用户，客户端可以主动切换延迟最低的服务器
                </p>
              </div>
            </div>
          </li>
          <li>
            <div class="item">
              <div class="icon">
                <img src="static/web/img/productservice/icon_x_74d5f.png?v=3.3.49">
              </div>
              <div class="word">
                <p>好友统计</p>
                <p>已经集成Whatsapp、Line、Zalo好友统计功能</p>
              </div>
            </div>
            <div class="item">
              <div class="icon">
                <img src="static/web/img/productservice/icon_x_84d5f.png?v=3.3.49">
              </div>
              <div class="word">
                <p>更多功能</p>
                <p>更多功能请下载登录客户端使用发现</p>
              </div>
            </div>
          </li>
        </ul>
      </div>
      <div class="service_bottom">
        <p class="title">为什么选择CC翻译？</p>
        <div class="des">
          支持企业跨境出海，和有语言障碍的外国友人实时交流<br>
          支持多种即时通讯交流软件，支持全球大部分语种<br>
          即时交流无需复制粘贴，自动翻译，畅享极速交流体验
        </div>
        <ul>
          <li>
            <img src="static/web/img/productservice/icon_b_14d5f.png?v=3.3.49">
            <p>热门软件支持</p>
            <div>客户服务，敏捷为先<br>
              支持whatsapp Line Tinder Twitter Instagram
              Telegram Zalo Facebook持续开发各种聊天软件对接
              为您的客户提供优质的售前售后服务。</div>
          </li>
          <li>
            <img src="static/web/img/productservice/icon_b_24d5f.png?v=3.3.49">
            <p>无需招聘专业翻译</p>
            <div>销售跟进，攻察为胜<br>
              任何人可以学习和操作，只需要会打字即可。招聘学习门槛低，入门简单
              随时上线新项目，扩大无需等待</div>
          </li>
          <li>
            <img src="static/web/img/productservice/icon_b_34d5f.png?v=3.3.49">
            <p>180个行业和类目</p>
            <div>智能通讯，沟通无碍<br>
              不限制业行与类目，所有项目都可操作，支持全球大部分语言翻译，无需定制开发，快速接入，让即时沟通与翻译无缝结合，提高工作效率。</div>
          </li>
        </ul>
        <p class="button">
          <a  target="_blank" onclick="">立即体验CC翻译服务</a>
        </p>
      </div>
      <div class="upgrade">
        <div class="triangle_border_down">
          <span></span>
        </div>
        <p>现在开通会员，立享更多特权</p>
        <p>
          <a href="/members" target="_blank">升级会员</a>
        </p>
      </div>
    </div>
  </div>

</template>

<script>
export default {
  name: "About"
}
</script>

<style scoped>

@import "../../../public/static/common/css/common4d5f.css?v=3.3.49";
@import "../../../public/static/web/css/service_s4d5f.css?v=3.3.49";
@import "../../../public/static/web/css/productservice4d5f.css?v=3.3.49";
</style>
