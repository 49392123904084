<template>
  <div class="side">
    <ul>
<!--      <li class="service moquu_wserve">-->
<!--        <i class="iconfont icon-onlineService"></i>-->
<!--        <p>在线客服</p>-->
<!--        <div class="min-chider">在线客服</div>-->
<!--      </li>-->
<!--      <li class="phone">-->
<!--        <i class="iconfont icon-telephone"></i>-->
<!--        <p>客服电话</p>-->
<!--        <div class="chider show-cld">-->
<!--          <p class="p1">客服电话：400-0506-071</p>-->
<!--          <p class="p2">服务时间：</p>-->
<!--          <p class="p3">工 作 日：9:00~20:00 </p>-->
<!--          <p class="p3">周末/节假日：9:30~17:00 </p>-->
<!--        </div>-->
<!--      </li>-->
      <li class="scavenging">
        <i class="iconfont icon-QRcode"></i>
        <p>联系客服</p>
        <!-- <div class="chider">
            <img src="https://www.woordee.com/static/common/img/erweima.jpg?v=3.2.1.0" alt="">
            <p class="p1">扫码绑定微信账号</p>
            <p class="p2">手机端随时与<span class="color-o">专家沟通</span></p>
            <p class="p2">快速查看订单动态</p>
        </div> -->
        <div class="show-cld moquu_wshareh">
          <ul class="tab-item" >
            <li @mouseenter="enter(index)" @mouseleave="leave" v-for="(item, index) in items" :qr-name="item.name":class="{active:index===cIndex}">
              {{ item.text }}
          </li>
<!--            <li qr-name="item-wx">公众号</li>-->
<!--            <li :class="{ active: index=== cIndex }" qr-name="item-android">安卓下载</li>-->
<!--            <li qr-name="item-ios">IOS下载</li>-->
          </ul>
            <div class="qrcode-item "@mouseenter="enter1" >
              <div class="item-wx" v-if="cIndex===0">
                <img src="../../../public/static/common/img/weixin.jpg" class="qrCodeImg" alt="">
                <div class="qr-des">
                  <p class="t-des">微信客服二维码</p>
                  <p class="d-des">通过微信随时与客服沟通<br>快速沟通，优质服务</p>
                </div>
              </div>
            <div class="item-wx" v-if="cIndex===1">
              <a href="https://t.me/catchat01" target="_blank">
                <img src="../../../public/static/common/img/Telegram1.jpg" class="qrCodeImg" alt="">
                <div class="qr-des">
                  <p class="t-des">飞机客服01二维码</p>
                  <p class="d-des">通过飞机随时与客服沟通<br>快速沟通，优质服务</p>
                </div>
              </a>
            </div>
             <div class="item-wx" v-if="cIndex===2">
               <a href="https://t.me/catchat02" target="_blank">

                 <img src="../../../public/static/common/img/Telegram001.png" class="qrCodeImg" alt="">
                 <div class="qr-des">
                   <p class="t-des">飞机客服001二维码</p>
                   <p class="d-des">通过飞机随时与客服沟通<br>快速沟通，优质服务</p>
                 </div>
               </a>
             </div>
           </div>
          </div>
      </li>
<!--      <li class="Top">-->
<!--        <i class="iconfont icon-return"></i>-->
<!--        <p>返回顶部</p>-->
<!--        <div class="min-chider">返回顶部</div>-->
<!--      </li>-->
    </ul>
  </div>
</template>

<script>
export default {
  name: "side",
  data(){
    return{
      items: [
        { name: "item-wx", text:"微信" },
        { name: "item-android", text:"纸飞机01" },
        { name: "item-ios", text:"纸飞机001" },
      ],
      cIndex:0,

    }
  },
  methods:{
    enter(index){
        this.cIndex=index;
    },
    enter1(){
        this.cIndex=this.cIndex;
    },
    leave(){

    },
  }
}
</script>

<style scoped>
.show-cld{
  display: block;
}
.side:hover .show-cld{
  display: block;
}
</style>
