import Vue from 'vue'
import App from './App.vue'

import router from './router'
import store from './store'

import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';

import "./assets/css/global.css";

import i18n from "./i18n";
import preventReClick from './utils/preventRepeatClick.js'
import axios from "axios";
import Vuex from "vuex";


Vue.config.productionTip = false

//防抖
Vue.use(preventReClick);

Vue.use(ElementUI);
// 添加请求拦截器
axios.interceptors.request.use(function (config) {
    // 在发送请求之前做些什么
    // 判断是否存在token,如果存在将每个页面header添加token
    if (store.getters.token!==''&&store.getters.token!==undefined) {
        config.headers.common['X-Access-Token'] = store.getters.token;
    }
    return config
}, function (error) {
    router.push('/login')
    return Promise.reject(error)
})
axios.interceptors.response.use(function (response) {
    // 对响应数据做点什么
    return response
}, function (error) {
    // 对响应错误做点什么
    if (error.response) {
        switch (error.response.status) {
            case 401:
                router.push({path :"/signIn"})
                store.commit('remove');
                ElementUI.Notification({
                    type:'error',
                    title: '请重新登录',
                    message: '用户信息已失效，请重新登录！！！'
                });
        }
    }
    return Promise.reject(error)
})
new Vue({
    router,
    store,
    i18n,
    render: h => h(App)
}).$mount('#app')
