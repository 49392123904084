//封装一个组件，用来切换语言
<template>
  <div class="ChannelSelected">
    <el-dropdown @command="handleCommand">
      <span class="el-dropdown-link" style="color: white">
        {{ language }}<i class="el-icon-arrow-down el-icon--right"></i>
      </span>
      <el-dropdown-menu slot="dropdown">
        <el-dropdown-item command="zh">中文</el-dropdown-item>
        <el-dropdown-item command="en">English</el-dropdown-item>
      </el-dropdown-menu>
    </el-dropdown>
  </div>
</template>
<script>
export default {
  name: "ChannelSelected",
  data() {
    return {
      language: "",
      showname1:false,
    };
  },
  methods: {
    // 根据下拉框的中被选中的值切换语言
    handleCommand(command) {
      if (command === "zh") {
        this.locale = "中文";
      } else {
        this.locale = "English";
      }
      this.$i18n.locale = command;
      if(this.$store.state.showname){
        this.showname1=true;
      }
      window.sessionStorage.setItem("locale", command);
      window.location.reload();
      this.$store.state.showname=this.showname1;
    }
  },
  created() {
    this.language= this.$i18n.locale === "zh" ? "中文" : "English";
  }
};
</script>
<style lang="scss">
.ChannelSelected {
  .el-dropdown-link {
    &:hover {
      cursor: pointer;
    }
  }
}
</style>
