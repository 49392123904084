<template>
  <!--登录内容区start-->
  <div style="padding-top: 80px">
    <div v-if="showDisclaimers" class="disclaimers">
      <div class="shadow"></div>
      <div class="disclaimers-content">
        <clause></clause>
        <el-button :disabled="buttonDisabled" type="info" @click="disclaimersClick">{{ disclaimersBtnTxt }}</el-button>
      </div>
    </div>
    <div class="wd-content" >
      <div class="layui-container login-position">
        <div class="swimg-box" style="height:332px">
          <a href="#"><img src="../../../public/static/common/img/i_02.png"/></a>
        </div>
        <div class="login-box wd-shadow">
          <div class="wd-log" >
            <div class="wd-center icon-log" style="margin-top:25px"></div>
            <p class="text-log">智能翻译，通达世界</p>
            <div class="user-register" style="margin-bottom:25px;margin-top:20px">

              <!--            &lt;!&ndash;手机号注册start&ndash;&gt;-->
              <!--            <form class="wd-center layui-form register-from phone-from captcha-form" action="#" autocomplete="off">-->
              <!--              <div class="row">-->
              <!--                <div class="log-input">-->
              <!--                  <input type="text" maxlength="11" name="account" value="" autocomplete="off" datatype="mobile"-->
              <!--                         nullmsg="请输入手机号" errormsg="请输入正确的手机号"  placeholder="输入手机号" class="layui-input onlyNum">-->
              <!--                  <div class="Validform_checktip"></div>-->
              <!--                </div>-->

              <!--              </div>-->
              <!--              <div class="log-input" style="display:none;">-->
              <!--                &lt;!&ndash;验证码&ndash;&gt;-->
              <!--                <div id="phCode" class="log-input phpcode"></div>-->
              <!--              </div>-->
              <!--              &lt;!&ndash;验证码结束&ndash;&gt;-->
              <!--              <div class="row">-->
              <!--                <div class="log-input wd-relative">-->
              <!--                  <input type="text" name="vcode" autocomplete="off" placeholder="请输入4位数字验证码" datatype="n4-4" nullmsg="请输入验证码" errormsg="请输入正确验证码" errormsg="请输入正确验证码"  class="layui-input msg-code-input onlyNum" maxlength="4" autocomplete="off">-->
              <!--                  <button type="button" class="code_type_1 get-msg-code getCode" id="mobileSendCode" data-time="60" data-type="mobile">发送验证码</button>-->
              <!--                  <span class="Validform_checktip"></span>-->
              <!--                </div>-->

              <!--              </div>-->
              <!--              <div class="wd-center opera" style="display:none">-->
              <!--                <a href="javascript:;" class="wd-fr code_type_2 redio-msg by-redio getCode" data-time="60" data-type="voice">语音验证码</a>-->
              <!--              </div>-->
              <!--              <div class="row">-->
              <!--                <div class="log-input input-pwd">-->
              <!--                  <input type="password" name="pwd" maxlength="20" datatype="*6-20" placeholder="请输入密码(6-20位字符)" errormsg="请输入密码(6-20位字符)" nullmsg="请输入密码(6-20位字符)"  class="layui-input password noBlank" autocomplete="new-password">-->
              <!--                  <i class="passwordeye eye visible"></i>-->
              <!--                  <div class="Validform_checktip"></div>-->
              <!--                </div>-->

              <!--              </div>-->
              <!--              <div class="log-input invitation-code" >-->
              <!--                <input type="text" name="invite_code" value="" placeholder="(非必填)输入邀请码" class="layui-input" >-->
              <!--              </div>-->
              <!--              <div class="wd-center opera layui-form">-->
              <!--                <input id="clause1" lay-filter="clause1" type="checkbox" name="clause1" lay-skin="primary"-->
              <!--                       title="我已阅读并同意<a href='help/server.html' target='_blank' class='link'>《服务条款》</a>" >-->
              <!--                <a class="wd-fr by-address ph-to-ad" href="javascript:;" index="byaddress">邮箱注册</a>-->
              <!--              </div>-->
              <!--              &lt;!&ndash;验证码 &ndash;&gt;-->
              <!--              <input type="hidden" name="sid">-->
              <!--              <input type="hidden" name="token">-->
              <!--              <input type="hidden" name="sig">-->
              <!--              <input type="hidden" name="scene">-->
              <!--              <input type="hidden" name="from" value="">-->
              <!--              &lt;!&ndash;验证码 结束 &ndash;&gt;-->
              <!--              <button class="layui-btn wd-center btn-register clause1 " type="submit" >注  册</button>-->
              <!--            </form>-->
              <!--            &lt;!&ndash;手机号注册end&ndash;&gt;-->

              <!--邮箱注册start-->
              <!--            <form class="wd-center layui-form register-from phone-from captcha-form" action="#" autocomplete="off">-->
              <form class="wd-center layui-form register-from address-from" action="#" style="display:block" autocomplete="off">
                <div class="row">
                  <div class="log-input">
                    <!--                  <input name="account" value="" datatype="e" nullmsg="请输入邮箱" errormsg="请输入正确的邮箱" placeholder="请输入邮箱" class="layui-input">-->
                    <el-input v-model="username" placeholder="请输入用户名" @blur="handleUsernameCheck" :oninput="username = username.replace(/\s+/g,'')"></el-input>
                    <span class="tishi">{{ this.usernameInfo}}</span>
                    <div class="Validform_checktip"></div>
                  </div>

                </div>
                <div class="row">
                  <div class="log-input">
                    <!--                  <input name="account" value="" datatype="e" nullmsg="请输入邮箱" errormsg="请输入正确的邮箱" placeholder="请输入邮箱" class="layui-input">-->
                    <el-input v-model="email" placeholder="请输入邮箱" @blur="handleEmailCheck" :oninput="email = email.replace(/\s+/g,'')"></el-input>
                    <span class="tishi">{{ this.emailInfo}}</span>
                    <div class="Validform_checktip"></div>
                  </div>

                </div>
                <div id="phCode_email" class="log-input phpcode" style="display:none;"></div>
                <div class="row">
                  <div class="log-input wd-relative">
                    <!--                  <input type="text" name="vcode" placeholder="请输入4位数字验证码" datatype="n4-4" nullmsg="请输入验证码" errormsg="请输入正确验证码" class="layui-input msg-code-input onlyNum" maxlength="4" autocomplete="off">-->
                    <!--                  <button type="button" class="code_type_1 get-msg-code getCode" id="emailSendCode" data-time="60" data-type="email">发送验证码</button>-->
                    <el-input v-model="captcha"  placeholder="请输入验证码" :oninput="captcha = captcha.replace(/\s+/g,'')" @blur="handleCaptchaCheck"><el-button style="padding-right:10px" slot="suffix" type="text" v-show="Verification" @click="send">获取验证码</el-button>
                      <el-button style="padding-right:10px" slot="suffix" type="text" v-show="!Verification" @click="again"><span>{{this.timer}}</span>秒后获取</el-button></el-input>
                    <span class="tishi">{{ this.captchaInfo}}</span>
                    <!--                  <span class="Validform_checktip"></span>-->
                  </div>

                </div>
                <div class="row">
                  <div class="log-input input-pwd">
                    <!--                  <input type="password" name="pwd" maxlength="20" datatype="*6-20" placeholder="请输入密码(6-20位字符)" errormsg="请输入密码(6-20位字符)" nullmsg="请输入密码(6-20位字符)" class="layui-input password" autocomplete="new-password">-->
                    <el-input placeholder="请输入密码" v-model="password" show-password auto-complete="new-password" :oninput="password = password.replace(/\s+/g,'')" @blur="handlepwdCheck"></el-input>
                    <span class="tishi">{{this.passwordInfo }}</span>
                    <!--                  <i class="passwordeye eye visible"></i>-->
                    <div class="Validform_checktip"></div>
                  </div>
                </div>
                <div class="log-input invitation-code" style="">
                  <!--                <input type="text" name="invite_code" value="" placeholder="(非必填)输入邀请码"  class="layui-input">-->
                  <el-input placeholder="(非必填)输入邀请码" v-model="invitationcode" @blur="handleInvitationCheck" ></el-input>
                  <span class="tishi">{{this.invitationInfo }}</span>
                </div>
                <div class="wd-center opera layui-form">
                  <!--                <input id="clause1" lay-filter="clause1" type="checkbox" name="clause1" lay-skin="primary"-->
                  <!--                       title="我已阅读并同意<a href='help/server.html' target='_blank' class='link'>《服务条款》</a>" >-->
                  <el-checkbox v-model="checked">我已阅读并同意</el-checkbox>
                  <!--&lt;!&ndash;                <a class="wd-fr by-phone ph-to-ad" href="#" index="byphone">手机号注册</a>&ndash;&gt;-->
                  <a href='/clause' target='_blank' class='link'>《服务条款》</a>
                  <!--                <input id="clause1" lay-filter="clause1" type="checkbox" name="clause1" lay-skin="primary" title="我已阅读并同意<a href='/help/server.html' target='_blank' class='link'>《服务条款》</a>">-->
                </div>
              </form>
              <button class="layui-btn wd-center btn-register clause2" @click="register">注  册</button>
              <!--邮箱注册end-->
              <div class="bottom" style="display:block">
                <p>已有帐号？<a href="/signIn" id="logToReg" :disabled="flag" index="tologin">登录</a></p>
              </div>
            </div>
          </div>

        </div>
      </div>
    </div>
    <!--登录内容区end-->
  </div>

</template>

<script>
import axios from "axios";
import clause from "./clause.vue";

export default {
  name: "Create",
  data(){
    return{
      flag:false,//防止重复提交，暂时禁用按钮标志
      username:'',
      email:'',
      password:'',
      captcha:'',
      Verification:true,    //通过v-show控制显示获取还是倒计时
      timer:60,      //定义初始时间为60s
      emailInfo:'',
      usernameInfo:'',
      captchaInfo:'',
      passwordInfo:'',
      invitationcode:'',
      invitationInfo:'',
      invitationCheck:false,
      checked:false,
      buttonDisabled: true,
      showDisclaimers: false,
      disclaimersBtnTxt: '确认',
    }
  },
  components: {
    clause: clause
  },
  methods:{
    handleCaptchaCheck(){
      if(this.captcha===null||this.captcha===''){
        this.captchaInfo='请输入验证码'
      }else{
        this.captchaInfo='';
      }
    },
    handleUsernameCheck(){
      var reg=/^[a-z0-9A-Z]+$/;
      if(!reg.test(this.username)||this.username.length<5||this.username.length>20){
        this.usernameInfo='请输入长度为5-20的数字与字母组合';
      }else {
        let p={
          username:this.username,
        }
        axios.post("/api/customer/customerMng/queryUsername",p).then(res=>{
              if(res.data.success){
                this.usernameInfo='';
              }else{
                this.usernameInfo='用户名已存在';
              }
        })
      }
    },
    handleEmailCheck() {
      var reg=/^([a-zA-Z]|[0-9])(\w|\-)+@[a-zA-Z0-9]+\.([a-zA-Z]{2,4})$/;
      if(!reg.test(this.email)){
        // callback(new Error("请输入正确的邮箱号"))
        this.emailInfo='请输入正确邮箱'
      }else{
        this.emailInfo=''
        var params = {
          email: this.email,
        };
        axios.post("/api/customer/chatCustomer/validateEmail",params).then(res=>{
          // console.log("1111")
          // console.log(res.data.exist)
          if(res.data.result.exist===1){
            this.emailInfo='该邮箱已注册';
          }else{
            this.emailInfo=''
          }
        })
        // checkOnlyUser(params).then((res) => {
        //   if (res.success) {
        //     callback()
        //   } else {
        //     callback("邮箱号已存在！")
        //   }
        // })
      }
    },
    handleInvitationCheck(){
      this.invitationInfo='';
      this.invitationCheck=false;
      if(this.invitationcode!==null&&this.invitationcode!==''){
        var params = {
          invitationcode: this.invitationcode,
        };
        axios.post("/api/customer/chatCustomer/validateProxy",params).then(res=>{
          console.log(res.data.result)
          if(res.data.result.exist===0){
            this.invitationCheck=true;
            this.invitationInfo='邀请码错误';
          }else{
            this.invitationCheck=false;
            this.invitationInfo=''
          }
        })
      }
    },
    handlepwdCheck(){
      if(this.password===null||this.password===''){
        this.passwordInfo='请输入密码'
      }else{
        this.passwordInfo='';
      }
    },
    register(){
      this.handleEmailCheck();
      this.handleCaptchaCheck();
      this.handlepwdCheck();
      // this.handleInvitationCheck();
      if(!this.checked){
        this.$message({
          message:'请同意用户条款',
          type:'warning'
        })
      }else {
        // console.log(sessionStorage.getItem('locale'))
        if(this.username===null||this.username===''){
          this.usernameInfo='请输入长度为5-20的数字与字母组合的用户名'
          this.$message({
            message:'请输入长度为5-20的数字与字母组合的用户名',
            type:'warning'
          })
        }else if(this.usernameInfo!=='') {
          this.$message({
            message:this.usernameInfo,
            type:'warning'
          })
        }else if(this.email===null||this.email===''||this.emailInfo!==''){
          this.emailInfo='请输入正确邮箱'
          this.$message({
            message:'请输入正确邮箱',
            type:'warning'
          })
        }else if(this.captcha===null||this.captcha===''){
          this.captchaInfo='请输入验证码'
          this.$message({
            message:'请输入验证码',
            type:'warning'
          })
        }else if(this.password===null||this.password===''){
          this.emailInfo='请输入密码'
          this.$message({
            message:'请输入密码',
            type:'warning'
          })
        }else if(this.password.length<6||this.password.length>20) {
          this.passwordInfo = '请输入密码(6-20位字符)'
          this.$message({
            message: '请输入密码(6-20位字符)',
            type: 'warning'
          })
        }else if(this.invitationCheck||this.invitationInfo!=='') {
          // alert(111)
          this.$message({
            message:'请输入正确邀请码',
            type:'warning'
          })
        }else{
          // alert(this.invitationInfo)
          this.showDisclaimers = true;
          this.buttonDisabled = true;
          var count = 5;
          this.disclaimersTimer = setInterval(() => {
            if (count === 0) {
              clearInterval(this.disclaimersTimer);
              this.buttonDisabled = false;
              this.disclaimersBtnTxt = '确认';
            } else {
              count--;
              this.disclaimersBtnTxt = `确认 ( ${count}S )`;
            }
          }, 1000)
        }
      }

    },
    again(){
      this.$message({
        message:this.timer+"后再获取",
        type:'warning'
      })
    },
    send(){
      this.handleEmailCheck();
      if(this.email===null||this.email===''||this.emailInfo!==''){
      this.emailInfo='请输入正确邮箱'
      this.$message({
        message:'邮箱不能为空',
        type:'warning'
      })
    }else {
      this.Verification = false;      //点击button改变v-show的状态
      let auth_timer = setInterval(() => {  //定时器设置每秒递减
        this.timer--;        //递减时间
        if (this.timer <= 0) {
          this.timer = 60
          this.Verification = true;    //60s时间结束还原v-show状态并清除定时器
          clearInterval(auth_timer)
        }
      }, 1000)
      var params = {
        email: this.email,
      };
      axios.post("/api/customer/chatCustomer/sendVerificationCode", params).then(res => {
        if (res.data.success) {
          this.$message({
            message: res.data.message,
            type: 'success'
          });
        } else {
          this.$message.error(res.data.message)
        }
      })
    }
    },
    disclaimersClick() {
      this.showDisclaimers = false;
      this.flag=true;
      let register = {
        username: this.username,
        password: this.password,
        email: this.email,
        vCode:this.captcha,
        // name:this.firstName+this.lastName,
        invitationcode:this.invitationcode,
      };
      const loading = this.$loading({
        lock: true,
        text: 'Loading',
        spinner: 'el-icon-loading',
        background: 'rgba(0, 0, 0, 0.7)'
      });
      axios.post("/api/customer/chatCustomer/customerRegiste",register).then(res=>{
        loading.close();
        if(res.data.success) {
          this.$message({
            message: res.data.message,
            type: 'success'
          });
          setTimeout(() => {
            this.$router.push({path: '/signIn'})
          }, 2000)
        }else{
          this.$message.error(res.data.message)
        }
      }).finally(() => {
        setTimeout(() => {
          this.flag = false;
        }, 2000)
        loading.close();
      });
    }
  }
  ,created() {
    this.invitationcode=this.$route.query.invitationcode;
  }
}
</script>

<style scoped>
@import "../../../public/static/common/css/login4d5f.css?v=3.3.49";
@import "../../../public/static/user/css/login4d5f.css?v=3.3.49";
@import "../../../public/static/plugin/validform/css/validform4d5f.css?v=3.3.49";
.disclaimers {
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  width: 100%;
  height: 100%;
  border-radius: 10px;
  z-index: 9999;
  .shadow {
    width: 100%;
    height: 100%;
    position: absolute;
    left: 0;
    top: 0;
    background-color: #eee;
  }
  .disclaimers-content {
    position: absolute;
    left: 50%;
    top: 20px;
    transform: translateX(-50%);
    /* width: 50%; */
    /* min-width: 400px; */
    width: 1010px;
    height: calc(100% - 40px);
    border: 1px solid #ccc;
    border-radius: 10px;
    box-shadow: 0 2px 12px 0 rgba(0,0,0,.1);
    /* overflow: auto; */
    .weyi_detailMid {
      display: block;
      overflow: auto;
      min-height: auto;
      margin: auto;
    }

    .el-button {
      position: absolute;
      right: 20px;
      z-index: 9999999;
      bottom: 20px;
    }

    .el-button--info {
      background-color: #31D391;
      border-color: #31D391;
      &:hover {
        background-color: #31D391;
        border-color: #31D391;
      }

      &.is-disabled {
        border-color: #EBEEF5;
      }
    }
  }
}
.link{
  vertical-align: initial;
  color: #00BE73;
}
.tishi{
  color: #F83333;
  font-size: 12px;
}
</style>
