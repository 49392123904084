<template>
  <div class="page5">
    <ul class="faqList">
      <el-collapse class="list" v-model="activeName" accordion>
        <el-collapse-item title="1、怎么下载CC翻译软件？" name="1">
          <div class="info">
            <p><span style="font-family: 微软雅黑, ">点击导航栏的<a href="/download" style="white-space: normal;">【软件下载】</a>选择相对应的操作系统和软件版本进行下载。</span></p></div>
        </el-collapse-item>
        <el-collapse-item title="2、软件更新？" name="2">
          <div class="info">
            <p style="white-space: normal;"><span style="font-family: 微软雅黑, &quot;Microsoft YaHei&quot;; font-size: 14px;">软件不定期更新，请到官网下载最新版本，如有疑问可以联系客服。</span></p></div>
        </el-collapse-item>
      </el-collapse>
    </ul>
  </div>
</template>

<script>
export default {
  name: "page5",
  data() {
    return {
      activeName: '0'
    };
  }
}
</script>

<style scoped>
.info {
  background: #f2f2f2;
  padding: 15px 20px;
  border-radius: 10px;
}
</style>
