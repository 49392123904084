<template>
  <div>
    <p class="cont-title" data-type="loginPwd">
      更换邮箱   </p>
    <div class="user-login">
      <el-steps :active="active" finish-status="success">
        <el-step title=""></el-step>
        <el-step title=""></el-step>
        <el-step title=""></el-step>
      </el-steps>
        <div class="form" v-if="this.active===0">
          <el-form :model="ruleForm" status-icon ref="ruleForm" label-width="100px" class="demo-ruleForm">
            <el-form-item prop="email" label="邮箱">
              <el-input v-model="ruleForm.email" disabled></el-input>
            </el-form-item>
            <el-form-item prop="email" label="验证码">
              <el-input v-model="ruleForm.captcha"  placeholder="请输入验证码" :oninput="ruleForm.captcha = ruleForm.captcha.replace(/\s+/g,'')" @blur="handleCaptchaCheck"><el-button style="padding-right:10px" slot="suffix" type="text" v-show="Verification" @click="send">获取验证码</el-button>
                <el-button style="padding-right:10px" slot="suffix" type="text" v-show="!Verification" @click="again"><span>{{this.timer}}</span>秒后获取</el-button></el-input>
              <span class="tishi">{{ this.captchaInfo}}</span>
            </el-form-item>
          </el-form>
          <button class="layui-btn wd-center btn-login editPwd_1_login" type="button" :disabled="flag" @click="next">下一步</button>
        </div>
      <div class="form" v-if="this.active===1">
          <el-form :model="ruleForm" status-icon ref="ruleForm" label-width="100px" class="demo-ruleForm">
            <el-form-item prop="email" label="邮箱">
              <el-input v-model="ruleForm.newEmail"></el-input>
            </el-form-item>
            <el-form-item prop="email" label="验证码">
              <el-input v-model="ruleForm.captcha"  placeholder="请输入验证码" :oninput="ruleForm.captcha = ruleForm.captcha.replace(/\s+/g,'')" @blur="handleCaptchaCheck"><el-button style="padding-right:10px" slot="suffix" type="text" v-show="Verification" @click="send">获取验证码</el-button>
                <el-button style="padding-right:10px" slot="suffix" type="text" v-show="!Verification" @click="again"><span>{{this.timer}}</span>秒后获取</el-button></el-input>
              <span class="tishi">{{ this.captchaInfo}}</span>
            </el-form-item>
          </el-form>
          <button class="layui-btn wd-center btn-login editPwd_1_login" type="button" :disabled="flag" @click="next">确认修改</button>
        </div>
      <div class="form" v-if="this.active===2">
       <h1>操作成功，即将跳转........</h1>
      </div>

    </div>
  </div>
</template>

<script>
import axios from "axios";

export default {
  name: "ChangeEmail",
  data(){
    return{
      active:0,
      flag:false,//防止重复提交，暂时禁用按钮标志
      Verification:true,    //通过v-show控制显示获取还是倒计时
      timer:60,      //定义初始时间为60s
      captchaInfo:'',
      ruleForm: {
        email:this.$store.getters.userInfo.email,
        captcha:'',
        newEmail:'',
      },
    }
  },
  methods:{
    handleCaptchaCheck(){
      if(this.captcha===null||this.captcha===''){
        this.captchaInfo='请输入验证码'
      }else{
        this.captchaInfo='';
      }
    },
    again(){
      this.$message({
        message:this.timer+"后再获取",
        type:'warning'
      })
    },
    send(){
      this.Verification = false;      //点击button改变v-show的状态
      let auth_timer = setInterval(() => {  //定时器设置每秒递减
        this.timer--;        //递减时间
        if (this.timer <= 0) {
          this.timer = 60
          this.Verification = true;    //60s时间结束还原v-show状态并清除定时器
          clearInterval(auth_timer)
        }
      }, 1000)
      var params={};
      if(this.active===0){
        params = {
          email: this.ruleForm.email,
        };
      }else{
        params = {
          email: this.ruleForm.newEmail,
        };
      }
      axios.post("/api/customer/sendCode/emailSms", params).then(res => {
        if (res.data.success) {
          this.$message({
            message: res.data.message,
            type: 'success'
          });
        } else {
          this.$message.error(res.data.message)
        }
      })
    },
    next(){
      if(this.ruleForm.captcha===''){
        this.$message({
          message:'请输入验证码',
          type: 'warning'
        });
      }else{
        this.flag=true;
        if(this.active===0){
          var params={
            email:this.ruleForm.email,
            captcha:this.ruleForm.captcha,
          }
          axios.post("/api/customer/customerMng/vEmail", params).then(res => {
            if (res.data.success) {
              this.$message({
                message: res.data.message,
                type: 'success'
              });
              setTimeout(() => {
                this.ruleForm.captcha='';
                this.active=1;
              }, 2000)
            } else {
              this.$message.error(res.data.message)
            }
          }).finally(() => {
            setTimeout(() => {
              this.flag = false;
            }, 2000)
          });
        }else{
          var params={
            oldEmail:this.ruleForm.email,
            newEmail:this.ruleForm.newEmail,
            captcha:this.ruleForm.captcha,
          }
          axios.post("/api/customer/customerMng/changeEmail", params).then(res => {
            if (res.data.success) {
              this.$message({
                message: res.data.message,
                type: 'success'
              });
              if(res.data.result===false){
                setTimeout(() => {
                  this.active=0;
                }, 2000)
              }else{
                setTimeout(() => {
                  this.active=2;
                }, 2000)
                setTimeout(() => {
                  this.$router.push({path:'/personal/Data'})
                }, 5000)
              }
            } else {
              this.$message.error(res.data.message)
            }
          }).finally(() => {
            setTimeout(() => {
              this.flag = false;
            }, 2000)
          });
        }
        }
      },
    }
}
</script>

<style scoped>
.m_content .cont-title {
  font-size: 16px;
  color: #222;
  height: 55px;
  line-height: 55px;
  text-indent: 20px;
  border-bottom: 1px solid #eaeaea;
}
.user-login {
   width: 700px;
   border-radius: 4px;
   padding-left: 200px;
   background: #fff;
   margin-top: 40px ;
   padding-bottom: 228px;
 }
.form{
  width: 400px;
  margin-top: 70px;

}
.btn-login{
  display: block;
  width: 300px;
  margin-left: 100px;
  height: 40px;
  margin-top: 20px;
  background: #1aba79;
  border-radius: 4px;
  color: #fff;
  line-height: 24px;
}
.tishi{
   color: #F83333;
   font-size: 12px;
}
</style>
