import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

/**  问题：
 *  elemetUI 不可以使用异步导入
 *  会导致  NavMenu(导航栏) activeIndex 无法绑定。
 */
import vuex from '@/store/index';

import home from "@/views/home/home";
import download from "@/views/download/Download";
import members from "@/views/members/members";
import pricing from "@/views/pricing/pricing";
import about from "@/views/about/About";
import signIn from "@/views/signIn/SignIn";
import create from "@/views/create/Create";
import problem from "@/views/problem/problem";
import personal from "../views/personal/personal";
import MainPage from "@/views/personal/mainPage/MainPage";
import MyOrder from "@/views/personal/myOrder/MyOrder";
import MyCoupon from "@/views/personal/myCoupon/MyCoupon";
import Data from "@/views/personal/data/Data";
import Security from "@/views/personal/security/Security";
import SubAccount from "@/views/personal/subAccount/SubAccount";
import page1 from "@/views/problem/page1/page1";
import page2 from "@/views/problem/page2/page2";
import page3 from "@/views/problem/page3/page3";
import page4 from "@/views/problem/page4/page4";
import page5 from "@/views/problem/page5/page5";
import page6 from "@/views/problem/page6/page6";
import page7 from "@/views/problem/page7/page7";
import page8 from "@/views/problem/page8/page8";
import clause from "@/views/create/clause";
import Main from "@/views/personal/security/Main";
import ChangePassword from "@/views/personal/security/ChangePassword";
import ChangeEmail from "@/views/personal/security/ChangeEmail";
import Lost from "@/views/lost/Lost";
import ProxyBuyRecords from "@/views/personal/proxy/ProxyBuyRecords";
import ProxyCustomerList from "@/views/personal/proxy/ProxyCustomerList";
import ProxyExtractRecords from "@/views/personal/proxy/ProxyExtractRecords";


const routes = [
  {path: '/',redirect : '/home'},
  {path : '/home' , component : home},
  {path: '/download',component: download},
  {path:'/members',component: members},
  {path:'/pricing',component: pricing},
  {path:'/about',component: about},
  {path:'/clause',component: clause},
  {path: '/signIn',component: signIn,
    meta: {
      keepAlive: true
    }
  },
  {path: '/create',component: create,
    meta: {
      keepAlive: true
    }
  },
  {path: '/lost',component: Lost,
    meta: {
      keepAlive: true
    }
  },
  {path: '/problem',component: problem,
      redirect: '/problem/page1',
      name:'problem',
      children: [
        {path: 'page1',component:page1 },
        {path: 'page2',component:page2 },
        {path: 'page3',component: page3},
        {path: 'page4',component: page4},
        {path: 'page5',component: page5},
        {path: 'page6',component: page6},
        {path: 'page7',component: page7},
        {path: 'page8',component: page8},
      ]
  },
  {path: '/personal',component: personal,
    redirect: '/personal/MainPage',
    name: 'personal',
    children:[
      {path: 'MainPage',component: MainPage},
      {path:'MyOrder',component: MyOrder},
      {path: 'SubAccount',component: SubAccount},
      {path: 'MyCoupon',component: MyCoupon},
      // {path: 'MyInvoice',component: MyInvoice},
      // {path: 'MyPackage',component: MyPackage},
      {path: 'Data',component: Data},
      {path: 'Security',component: Security,
          redirect:'/personal/Security/Main',
          children:[
            {path:'Main',component:Main},
            {path:'ChangePassword',component:ChangePassword},
            {path:'ChangeEmail',component: ChangeEmail}
          ]
      },
      {path: 'ProxyBuyRecords',component: ProxyBuyRecords},
      {path: 'ProxyCustomerList',component: ProxyCustomerList},
      {path: 'ProxyExtraRecords',component: ProxyExtractRecords},
    ]
  },
]

//冗余导航
const originalPush = VueRouter.prototype.push
VueRouter.prototype.push = function push(location) {
  return originalPush.call(this, location).catch(err => err)
}
const router = new VueRouter({
  // mode: 'history',
  mode: 'history',
  base: process.env.BASE_URL,
  routes
});
router.beforeEach( (to, from, next)=>{
  const nextRoute = [ '/personal/MainPage','/personal/MyOrder','/personal/MyCoupon','/personal/SubAccount','/personal/Data','/personal/Security','/personal/Security/Main','/personal/Security/ChangePassword','/personal/Security/ChangeEmail'];
  // console.log(1111)
  if (nextRoute.indexOf(to.path) >= 0) {
    //未登录
    // console.log("111"+vuex.getters.userInfo)
    // console.log("2222"+vuex.getters.showName)
    if (vuex.getters.showName ===null|| vuex.getters.userInfo ===null) {
      alert("请先登录")
      router.push({path: '/signIn'})
    }
    // if(nextRoute.indexOf(to.path)===3){
    //   console.log("用户类型"+vuex.getters.userInfo.usertype)
    //   if(vuex.getters.userInfo.usertype==='1'){
    //
    //   }
    // }
  }

  //回到顶部
  document.body.scrollTop = 0
  document.documentElement.scrollTop = 0
  window.pageYOffset = 0

  // if(to.path==="/home"){
  //   // console.log(666)
  //   if(vuex.getters.showName&&vuex.getters.userInfo!==null){
  //     // console.log(555)
  //       router.push({path:'/person'})
  //   }
  // }
  next();
});

export default router
