// 中文
const zh = {
    home:'首页',
    download:'下载',
    about:'关于',
    pricing:'价格',
    support:'使用说明',
    signIn:'登录',
    create:'创建账号',
    HomePage:{
        swiperTitle:'再次掌握您的信息。',
        swiperInfo1: 'CC翻译是适用于WhatsApp、Facebook、Messenger、Slack、Telegram等等的消息应用程序。',
        swiperDownload:'立即下载',
        swiperAltDownload:'备用下载',
        pagetTitle:'无限可能',
        pagetCon1:'CC翻译支持多种商务和私人消息和聊天服务，如Slack、WhatsApp、微信、Messenger、Telegram、Google Hangouts、Skype、Zendesk等。',
        pagetCon2:'无论您是只想与朋友保持联系，还是正在管理一个多席位的客户服务团队。CC翻译都能为您代劳。',
        pagetFind:'点此处查看所有服务',
        pagethTitle1:'特色服务',
        pagethTitle2:'无限账号',
        pagethCon:'CC翻译允许您多次添加每个服务。这使得CC翻译成为同时管理多个企业和私人账户的完美工具。你甚至可以同时使用五个不同的Facebook Messenger帐户，如果有无法解释的原因促使你这么做的话。',
        pagefTitle:'适用于任何场景',
        pagefCon1:'可以开启多个服务，并可以随时在它们之间轻松切换。让你变得更加专注，更少分心！',
        pagefCon2:'CC翻译工作区可以确保您在处理特定项目时只收到重要的通知，可以更好的帮助您创建无干扰的工作环境。为商务和私人生活设立不同的工作场所也变得轻松，可以在家或周末与工作脱节，帮助你保持健康的工作与生活平衡。',
        pagefiveTitle:'随时掌握您的任务',
        pagefiveCon1:'在CC翻译中使用的任何消息服务时，在消息中选择一个单词或句子，然后通过两次单击可以将其转换为待办事项列表中的一个项目！',
        pagefiveCon2:'通过将任务放在在不同的列表中，可以为您的时间和工作流程提供更多的结构，无论您是为待办事项分配优先级，还是只想将工作和私人分开。与家人共享购物清单，并为工作中的团队成员分配行动步骤。',

    },
    Download:{
        title:'下载CC翻译',
        windowstitle:'windows版',
        windowsInfo1:'需要win7及以上版本',
        windowsInfo2:'下载Windows版',

    },
    LoginPage:{
        title:'登录',
        email:'邮箱',
        password:'密码',
        create:'创建账号',
        lost:'忘记密码',
    },
    creatPage:{
        title:'创建您的CC翻译帐户',
        download:'下载CC翻译',
        signup:'注册',
        firstName:'名',
        lastName:'姓',
        email:'邮箱',
        password:'密码',
        captcha:'验证码',
        getCaptcha:'点击获取验证码',
        wait:'秒后重新获取',
        register:'注册账号',
        already:'已经有账户了？',
        lost:'忘记密码',
        message1:{
            error:'请输入正确邮箱',
            exists:'邮箱号已存在'
        },
        message2:'密码不能为空',
        message3:'请填写名字',
        message4:'请填写姓氏',
    },
    person:{
        accountInfo:'账户信息',
        editAccount:'编辑账户',
        manageTeam:'子账户管理',
        upgrade:'购买服务',
        privacy:'隐私',
        logout:'登出',
    },
    accountInfo:{
        hi:'你好',
        profile:'个人信息',
        name:'姓名',
        email:'邮箱',
        remainingchars:'字符余量',
        invitationcode:'邀请码',
        update:'修改个人信息',
        upgrade:'购买服务',
        license:'通行证',
        licenseInfo:'您正在使用免费版本的CC翻译，请在此处订阅以访问所有功能',
        userType1:'企业用户',
        userType2:'普通用户',
        userType3:'子用户',
    },
    editInfo:{
        title1:'编辑你的个人信息',
        title2:'账户信息',
        name:'姓名',
        userName:'用户名',
        email:'邮箱',
        update:'确认修改',
        changePwd:'修改密码',
        currentPwd:'原密码',
        newPwd:'新密码',
        updatePwd:'确认修改',
        danger:'危险区',
        dangerInfo:'如果您不再需要CC翻译帐户，可以点击此处删除您的帐户和所有相关数据。',
        dangerBtn:'删除CC翻译账户'
    },
    manageTeam:{
        name:'姓名',
        userName:'用户名',
        remainingchars:'字符余量',
        add:'新增子账户',
        editBtn:'编辑',
        deleteBtn:'删除',
        addTitle:'添加子账户',
        password:'密码',
        cancleBtn:'取 消',
        addBtn:'添 加',
        updateTitle:'修改子账户',
        updateBtn:'确认修改',
    },
    pricingPage:{
        title1:'选择套餐',
        title2:'翻译价格低至0.2元/1500字，请选择适合您的套餐',
        character:' 字符',
        buy:'立即购买',
        bottomInfo:'无需信用卡',
        basic:{
            title:'基础版',
            info:'即可享受常用语种即时翻译',
        },
        premium:{
            title:'高级版',
            info:'即可享受多达200+语种精准翻译',
            popular:'最受欢迎',
        },
        page2:{
            title:'使用CC翻译翻译聊天软件，让沟通变得简单!',
            btn:'立即购买!',
        }
    },
    setMeal:{
        back:"返回",
        title1:'充值字符',
        basic:'基础版',
        premium:'高级版',
        charCount:'字符数量',
        char:'字符',
        buyCount:'购买数量',
        info1:'【满10次享受9折优惠】 【满50次享受8折优惠】',
        info2:'购买1个以上个可成为企业用户,拥有开通1个子账户功能 以此类推',
        info3:'注意:如之前购买过高级版,请使用完后再进行购买，否则会清空高级版字符余额',
        total:'总计',
        btn:'立即下单',
    },
    payPage:{

    },
}

export default zh;