// 英文
const en = {
    home: 'Home',
    download: 'Download',
    about: 'About',
    pricing: 'Pricing',
    support: 'Instructions',
    signIn: 'Sign in',
    create: 'Create accout',
    HomePage: {
        swiperTitle: 'Get back on top of your messaging.',
        swiperInfo1: 'CATCHAT is your messaging app for WhatsApp, Facebook,Messenger, Slack, Telegram and many many more.',
        swiperDownload: 'Download',
        swiperAltDownload: 'Alternate Download',
        pagetTitle: 'Endless possibilities',
        pagetCon1: 'catchat supports a great variety of business and private messaging &amp; chat services like Slack, WhatsApp, WeChat, Messenger, Telegram, Google Hangouts, Skype, Zendesk and many more.',
        pagetCon2:'It does not matter if you just want to keep in touch with your friends or are managing a multi-seat customer care team. Catchat got you covered.',
        pagetFind:'Find a complete list of all supported services here',
        pagethTitle1:'One service',
        pagethTitle2:'unlimited accounts',
        pagethCon:'catchat allows you to add each service many times. This makes CatChat the perfect tool to manage multiple business and private accounts all at the same time. You could even use five different Facebook Messenger accounts at once, if some inexplicable reason urges you to do so.',
        pagefTitle:'The right tools for every situation',
        pagefCon1:'Set up multiple sets of services and easily switch between them at any time. More focus, less distraction!',
        pagefCon2:'catchat Workspaces help you create distraction-free work environments by making sure you’re only getting the notifications you absolutely need while working on a specific project. Setting up different Workspaces for business and private life is also an easy way to disconnect from work at home or on weekends, helping you maintain a healthy work-life balance.',
        pagefiveTitle:'Stay on top of your tasks',
        pagefiveCon1:'In any messaging service you’re using in catchat, select a word or sentence in a message and turn it into an item on your to-do list with two clicks. Just like that!',
        pagefiveCon2:'Give more structure to your time and workflow by organizing tasks in different lists – no matter if you’re assigning priorities to your to-dos or just want to keep work and private separate. Share shopping lists with family members and assign action steps to your team members at work.'
    },
    Download:{
        title:'Download catchat',
        windowstitle:'catchat for Windows',
        windowsInfo1:'Requires Windows 7 or newer',
        windowsInfo2:'Download for Windows',
        mactitle:'catchat for Mac',
        macInfo1:'Requires 10.9 or newer',
        macInfo2:'Download for Windows',
        linuxtitle:'catchat for Windows',
        linuxInfo1:'Requires Windows 7 or newer',
        linuxInfo2:'Download for Windows'
    },
    LoginPage:{
        title:'Login',
        email:'E-Mail',
        password:'Password',
        create:'Create catchat Account',
        lost:'Lost password',
    },
    creatPage:{
        title:'Create Your CatChat Account',
        download: 'Download CatChat',
        signup:'Signup',
        firstName:'First Name',
        lastName:'Last Name',
        email:'E-Mail',
        password:'Password',
        captcha:'Captcha',
        getCaptcha:'get the captcha',
        wait:' seconds',
        register:'Register',
        already:'Already have an account?',
        lost:'Lost password',
        message1:{
            error:'Please enter the correct email address',
            exists:'Email number already exists',
        },
        message2:'Password cannot be empty',
        message3:'Please fill in your first name',
        message4:'Please fill in your last name',
    },
    person:{
        accountInfo:'Account overview',
        editAccount:'Edit Account',
        manageTeam:'Sub-account',
        upgrade:'Upgrade Account',
        privacy:'Privacy',
        logout:'Logout',
    },
    accountInfo:{
        hi:'Hi',
        profile:'Profile',
        name:'Name',
        email:'Email',
        remainingchars:'Remainingchars',
        invitationcode:'Invitationcode',
        update:'Update Profile',
        upgrade:'Buy Service',
        license:'License',
        licenseInfo:'You are using the free version of catchat, subscribe here to get access to all the features.',
        userType1:'Business user',
        userType2:'general user',
        userType3:'sub user',
    },
    editInfo:{
        title1:'Edit your Profile',
        title2:'Account Information',
        name:'Name',
        userName:'userName',
        email:'E-Mail',
        update:'Update Profile',
        changePwd:'Change Password',
        currentPwd:'Current Password',
        newPwd:'New Password',
        updatePwd:'Update password',
        danger:'Danger Zone',
        dangerInfo:'If you don\'t need your catchat account any longer, you can delete your account and all related data here.',
        dangerBtn:'Delete catChat Account'
    },
    manageTeam:{
        name:'Name',
        userName:'userName',
        remainingchars:'Remainingchars',
        add:'Add Sub-account',
        editBtn:'edit',
        deleteBtn:'delete',
        addTitle:'Add Sub-account',
        password:'password',
        cancleBtn:'Cancle',
        addBtn:'Add',
        updateTitle:'update Sub-account',
        updateBtn:'update',
    },
    pricingPage:{
        title1:'Looking for a plan?',
        title2:'The translation price is as low as 0.2 yuan/1500 words, please choose the package that suits you',
        character:' character',
        buy:'Buy now',
        bottomInfo:'No credit card required',
        basic:{
            title:'Basic',
            info:'Provide instant translation in commonly used languages',
        },
        premium:{
            title:'Premium',
            info:'Provide accurate translation in up to 200+ languages',
            popular:'most popular',
        },
        page2:{
            title:'Make communication easy with Catchat Translator Chat!',
            btn:'Buy Service now!',
        },
    },
    setMeal:{
        back:"Back",
        title1:'Recharge',
        basic:'Basic Edition',
        premium:'Premium',
        charCount:'Number of characters',
        char:' characters',
        buyCount:'Purchase quantity',
        info1:'【10% off for 10 times】 【Enjoy 20% off over 50 times】',
        info2:'Purchase more than 1 to become a corporate user, with the function of opening 1 sub-account and so on',
        info3:'Note: If you have purchased the premium version before, please use it before purchasing, otherwise the premium version character balance will be emptied',
        total:'Total',
        btn:'Order now',
    },
}

export default en;